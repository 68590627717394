<template>
  <div class="control-panel">
    <span>选择日期：</span>
    <el-date-picker
      v-model="selectedDate"
      type="date"
      placeholder="选择日期"
      class="date-picker"
    ></el-date-picker>
    <button @click="fetchImages" class="query-button">查询</button>
  </div>
  <div class="image-grid">
    <div v-for="(image, index) in images" :key="index" class="image-item">
      <img :src="image.url" :alt="`Device ${image.device}`" v-lazy-load />
    </div>
  </div>
</template>

<script setup>
import { ref, nextTick, onMounted } from 'vue';
import dayjs from 'dayjs';
import { createAxiosInstance } from "@/plugins/axios";

const selectedDate = ref(dayjs().format('YYYY-MM-DD'));
const images = ref([]);
const axiosInstance = createAxiosInstance("计算服务器1");

const fetchImages = async () => {
  try {
    const params = {
      date: dayjs(selectedDate.value).format('YYYY-MM-DD'),
    };

    const response = await axiosInstance.get('/device_rrv/list/', { params });
    images.value = [];

    // 假设数据结构是固定格式
    Object.entries(response.data).forEach(([device, fileData]) => {
      if (fileData['rrv.png']) {
        images.value.push({
          device: device,
          url: fileData['rrv.png'],
        });
      }
    });
  } catch (error) {
    console.error('Error fetching images:', error);
  }
};

onMounted(async () => {
  await nextTick();
  fetchImages();
});
</script>

<style scoped>
.control-panel {
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  gap: 10px;
}

.date-picker, .query-button {
  padding: 5px;
  border-radius: 4px;
  outline: none;
  transition: border-color 0.3s;
}

.query-button {
  background-color: #409eff;
  color: white;
  cursor: pointer;
  border: none;
}

.query-button:active {
  background-color: #337ab7;
}

.image-grid {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  justify-content: flex-start;
}

.image-item {
  flex: 1 0 18%; /* 每行显示5个项目 */
  max-width: 18%;
  margin-bottom: 15px;
  text-align: center;
  box-sizing: border-box;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  overflow: hidden;
}

.image-item img {
  width: 100%;
  height: auto;
  display: block;
  cursor: pointer;
}
</style>
