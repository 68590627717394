<template>
  <div class="upload-container">
    <input
        type="file"
        ref="fileInput"
        @change="onFileChange"
        multiple
        hidden
    />
    <input
        type="file"
        ref="directoryInput"
        @change="onDirectoryChange"
        webkitdirectory
        multiple
        hidden
    />
    <div
        class="upload-area"
        @dragover.prevent="onDragOver($event)"
        @dragleave.prevent="onDragLeave($event)"
        @drop.prevent="onDrop($event)"
    >
      拖拽文件到此处上传 或 <span class="upload-link" @click="triggerFileSelect">选择文件</span>
      或 <span class="upload-link" @click="triggerDirectorySelect">选择文件夹</span>
    </div>
    <div v-if="filesSelected.length > 0" class="files-list">
      <table>
        <thead>
        <tr>
          <th>文件名</th>
          <th>大小</th>
          <th>上传进度</th>
          <th>操作</th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="(file, index) in filesSelected" :key="file.name + index">
          <td>{{ file.webkitRelativePath || file.name }}</td>
          <td>{{ (file.size / 1024 / 1024).toFixed(2) }} MB</td>
          <td>
            <progress :value="progress[index]" max="100"></progress>
            <span>{{ progress[index] }}%</span>
          </td>
          <td>
            <button class="delete-button" @click="removeFile(index)">删除</button>
          </td>
        </tr>
        </tbody>
      </table>
      <button class="action-button" @click="uploadFiles">上传</button>
      <button class="clear-button" @click="clearFiles">清除上传列表</button>
    </div>
    <div
        v-if="uploadStatus"
        class="upload-status"
        :class="{ success: uploadStatus === 'success', error: uploadStatus === 'error' }"
    >
      {{ uploadMessage }}
    </div>
  </div>
</template>

<script setup>
import {ref, reactive, onMounted, defineProps} from 'vue';
import {createAxiosInstance} from "@/plugins/axios";

const fileInput = ref(null);
const directoryInput = ref(null);
const filesSelected = ref([]);
const progress = reactive([]);
const uploadStatus = ref('');
const uploadMessage = ref('');
const fileHistory = ref([]);
const props = defineProps({
  serveType: String,
});

const onFileChange = (event) => {
  filesSelected.value = [...filesSelected.value, ...Array.from(event.target.files)];
  resetProgress();
  resetStatus();
};

const onDirectoryChange = (event) => {
  filesSelected.value = [...filesSelected.value, ...Array.from(event.target.files)];
  resetProgress();
  resetStatus();
};

const triggerFileSelect = () => {
  fileInput.value.click();
};

const triggerDirectorySelect = () => {
  directoryInput.value.click();
};

const onDragOver = (event) => {
  event.currentTarget.classList.add('drag-over');
};

const onDragLeave = (event) => {
  event.currentTarget.classList.remove('drag-over');
};

const onDrop = (event) => {
  filesSelected.value = [...filesSelected.value, ...Array.from(event.dataTransfer.files)];
  event.currentTarget.classList.remove('drag-over');
  resetProgress();
  resetStatus();
};

const removeFile = (index) => {
  filesSelected.value.splice(index, 1);
  progress.splice(index, 1);
};

const loadHistory = () => {
  const sessionHistory = sessionStorage.getItem('uploadHistory');
  if (sessionHistory) {
    fileHistory.value = JSON.parse(sessionHistory);
  }
};

const saveHistory = (file) => {
  const fileRecord = {
    name: file.webkitRelativePath || file.name,
    size: file.size,
  };
  fileHistory.value.push(fileRecord);
  sessionStorage.setItem('uploadHistory', JSON.stringify(fileHistory.value));
};

const uploadFile = async (file, index) => {
  const formData = new FormData();
  formData.append('files', file, file.webkitRelativePath || file.name);

  try {
    const axiosInstance = createAxiosInstance(props.serveType);
    await axiosInstance.post('/file/upload/', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      onUploadProgress: (event) => {
        progress[index] = Math.round((event.loaded / event.total) * 100);
      },
    });

    saveHistory(file);
    return {success: true};
  } catch (error) {
    console.error('上传文件时出错', error);
    return {success: false, error};
  }
};

const resetProgress = () => {
  progress.length = filesSelected.value.length;
  for (let i = 0; i < progress.length; i++) {
    progress[i] = 0;
  }
};

const resetStatus = () => {
  uploadStatus.value = '';
  uploadMessage.value = '';
};

const uploadFiles = async () => {
  resetProgress();
  resetStatus();

  const uploadPromises = filesSelected.value.map((file, index) => uploadFile(file, index));
  const results = await Promise.all(uploadPromises);

  const allSuccess = results.every((result) => result.success);

  if (allSuccess) {
    uploadStatus.value = 'success';
    uploadMessage.value = '所有文件上传成功！';
  } else {
    uploadStatus.value = 'error';
    uploadMessage.value = '上传时出现错误，请重试。';
  }
};

const clearFiles = () => {
  filesSelected.value = [];
};

onMounted(() => {
  localStorage.removeItem('uploadHistory');
  loadHistory();
});
</script>

<style scoped>
.upload-container {
  text-align: center;
  margin: 20px;
}

.upload-area {
  border: 2px dashed #ccc;
  padding: 20px;
  cursor: pointer;
}

.upload-area.drag-over {
  border-color: #000;
}

.upload-link {
  color: blue;
  cursor: pointer;
  text-decoration: underline;
}

.files-list {
  margin-top: 20px;
}

table {
  width: 100%;
  border-collapse: collapse;
}

th,
td {
  border: 1px solid #ddd;
  padding: 8px;
}

th {
  background-color: #f2f2f2;
  text-align: left;
}

.action-button,
.clear-button,
.delete-button {
  margin-top: 10px;
  margin-right: 10px;
}

.upload-status {
  margin-top: 20px;
}

.upload-status.success {
  color: green;
}

.upload-status.error {
  color: red;
}
</style>
