import { getColor, seconds2HHmm, formatDateString, ledColor } from '../utils/utils';

const catColor = ['#36349d', '#3478f6', '#59aae0', '#fe8973', '#CCC', '#8E377A'];
import {locales} from '@/plugins/utils/locales';

const L = locales['zh-Hans']

function renderItem(params, api) {
    var curveShape = {
        type: 'rect' //避免animation bug
    };
    var rectShape = {
        type: 'rect'
    };
    var markerShape = {
        type: 'rect'
    };

    var categoryIndex = api.value(0);
    var nextCategoryIndex = api.value(3);
    if (categoryIndex>=0) {
        var start = api.coord([api.value(1), (categoryIndex>3) ? 3 : categoryIndex]);
        var end = api.coord([api.value(2), (categoryIndex>3) ? 3 : categoryIndex]);
        var start2 = nextCategoryIndex!=-1 ? api.coord([api.value(1), (nextCategoryIndex>3) ? 3 : nextCategoryIndex]) : start;
        var end2 = nextCategoryIndex!=-1 ? api.coord([api.value(2), (nextCategoryIndex>3) ? 3 : nextCategoryIndex]) : end;
        var height = api.size([0, 1])[1] * 0.9;
        
        var cond1 = (start[1]>start2[1]);
        var cond2 = (start[1]>=start2[1]);
        var x1 = end[0];
        var y1 = start[1] + ( cond1 ? -height*1/3 : height*2/3);
        var x2 = end2[0];
        var y2 = start2[1] + ( cond2 ? height*2/3 : -height/3);

        var thisColor = catColor[categoryIndex];
        var nextColor = catColor[nextCategoryIndex];

        //var yOffset = params.coordSys.height * 0.15;
        //var yRatio = 0.5;
        var yOffset = 0;
        var yRatio = 1;
    
        rectShape = {
                x: start[0] - 0.5,              // offset半个像素才能与直线对齐，dont know why..
                y: (start[1] - height / 3)*yRatio - yOffset,
                width: end[0] - start[0] + 1,
                height: height*yRatio
            };
        if (categoryIndex==4) { //卧床无意图
            rectShape = {
                x: start[0],
                y: (start[1] - height / 3)*yRatio - yOffset,
                width: end[0] - start[0],
                height: height*yRatio
            };
        }
        if (categoryIndex==5) { //离床
            height = api.size([0, 1])[1];
            rectShape = {
                x: start[0],
                y: (start[1] - ((rectShape.width<5) ? height : height*0.9) / ((rectShape.width<5) ? 2 : 3))*yRatio - yOffset,
                width: end[0] - start[0],
                height: ((rectShape.width<5) ? height : height*0.9)*yRatio,
            };

            if (rectShape.width<5) {
                let mWidth = 7;
                let mHeight = 6;
                markerShape = {
                    type: 'polygon',
                    shape: {
                        points: [ [start[0] + rectShape.width/2 - mWidth/2, start[1] - height/2 - mHeight/2], [start[0] + rectShape.width/2  + mWidth/2, start[1] - height/2 - mHeight/2], [start[0] + rectShape.width/2 , start[1] - height/2 + mHeight/2] ]
                    },
                    style: {
                        fill: thisColor,
                        opacity: 0.8,
                    },
                    emphasis: {
                        style: {
                            stroke: thisColor,
                            lineWidth: 3,
                        }
                    },
                    z: 20,
                }
            }
        }
        
        //下一分段有效，则画连接线
        if (!(categoryIndex>=3 && nextCategoryIndex>=3) && nextCategoryIndex>=0) { 
            curveShape = {
                type: 'line',
                silent: true,
                shape: {
                    x1: x1,
                    y1: (y1 + (cond1?3:-3))*yRatio - yOffset,
                    x2: x2,
                    y2: (y2 + (cond1?-3:3))*yRatio - yOffset,
                },
                style: {
                    stroke: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                        offset: 0, color: cond1 ? nextColor : thisColor
                    }, {
                        offset: 1, color: cond1 ? thisColor : nextColor
                    }]),
                    lineWidth: 1,
                    opacity: 0.7,
                }
            };
        }
    }

    let radius = (rectShape.width > 2) ? 3 : 0;
    return ( {
        type: 'group',
        children: [
            curveShape,
            markerShape,
            rectShape && {
                type: 'rect',
                silent: false,//(categoryIndex>3),
                transition: ['shape'],
                shape: {...rectShape, 
                    r: radius 
                },
                style: {...api.style(), 
                    fill: thisColor,
                    opacity: 1,
                },
                //z: 2,
                emphasis: {
                    style: {
                        stroke: thisColor,
                        lineWidth: 2,
                        //shadowBlur: 2,
                    }
                }
            }
        ]
    } );
}

// 睡眠分期合并：5分期合并为4分期
function mergeSleepStage(stage) {
    switch(stage) {
        case -1: return -1; // 无效的数据段
        case 1: return 3;
        case 2: return 2;
        case 3: return 1;
        case 4: return 1;
        case 5: return 0;
        default:
            return 3;
    }
}

export function setDayCPCChartOption(cpc_chart, cpc_data) {
    if (!cpc_data || !cpc_data.stages) {
        console.log("Empty stage data, set chart fail.")
        return;
    }
    if (!cpc_chart) { //图表组件尚未初始化
        return;
    }

    var data = [];
    var dataCPC = [];
    var dataMask = [];

    var categories = [L.deep, L.light, L.rem, L.wake];
    
    var baseTime = cpc_data.start;
    // 从睡眠分期数组读出绘图数据
    for (let i=0; i<cpc_data.stages.length; i++) {
        // 5分期合并为4分期
        let stg = mergeSleepStage(cpc_data.stages[i].value);
        let stageLen = cpc_data.stages[i].end - cpc_data.stages[i].start; // 分期长度（秒）
        
        data.push({
            name: stg>=0 ? categories[stg] : '',
            value: [stg, baseTime * 1000 + cpc_data.stages[i].start * 1000, baseTime * 1000 + cpc_data.stages[i].end * 1000, -1],
            itemStyle: { color: stg>=0 ? catColor[stg] : '#FFF' }
        });
    }
    
    //分期中的相邻碎片合并
    for (let i=1; i<data.length; i++) {
        if (data[i-1].value[0] === data[i].value[0]) {
            data[i-1].value[2] = data[i].value[2];
            data.splice(i, 1);
            i--;
        }
    }
    //对离床和非意图段也做一遍碎片合并检查
    for (let i=0; i<cpc_data.nointention?.length; i++) {
        cpc_data.nointention[i].s = Math.round(cpc_data.nointention[i].s);
        cpc_data.nointention[i].e = Math.round(cpc_data.nointention[i].e);
    }
    for (let i=1; i<cpc_data.nointention?.length; i++) {
        if ( (cpc_data.nointention[i-1].e === cpc_data.nointention[i].s) || (cpc_data.nointention[i-1].e+1 === cpc_data.nointention[i].s) ) {
            cpc_data.nointention[i-1].e = cpc_data.nointention[i].e;
            cpc_data.nointention.splice(i, 1);
            i--;
        }
    }
    for (let i=1; i<cpc_data.leavebed?.length; i++) {
        if ((cpc_data.leavebed[i-1].e === cpc_data.leavebed[i].s)||((cpc_data.leavebed[i-1].e+1 === cpc_data.leavebed[i].s))) {
            cpc_data.leavebed[i-1].e = cpc_data.leavebed[i].e;
            cpc_data.leavebed.splice(i, 1);
            i--;
        }
    }

    //用离床和非意图时间把wake分期拆开
    let hasIntentionData = false;
    let hasLeaveData = false;
    for (let i=0; i<data.length; i++) {
        if (data[i].value[0]===3) {
            for (let j=0; j<cpc_data.nointention?.length; j++) {
                let nointent = cpc_data.nointention[j];
                let oldname = data[i].name;
                let oldstart = data[i].value[1]/1000;
                let oldend = data[i].value[2]/1000;
                let oldstyle = data[i].itemStyle;
                if (baseTime+nointent.s >= oldstart && baseTime+nointent.e <= oldend) { //如果当前wake分期与意图有重叠
                    hasIntentionData = true;
                    let idx = i;
                    if (baseTime+nointent.s > oldstart) { // 如果有，拆分第一个wake段，并替换掉原来的wake段
                        data.splice(i, 1, {
                            name: oldname,
                            value: [3, oldstart*1000, baseTime*1000 + nointent.s*1000, -1],
                            itemStyle: oldstyle,
                        })
                        idx = i+1;
                    } else { // 否则要删掉原来的wake段
                        data.splice(i, 1);
                    }
                    // 插入一个非意图段
                    data.splice(idx, 0, {
                        name: '活动', 
                        value: [4, baseTime*1000 + nointent.s*1000, baseTime*1000 + nointent.e*1000, -1],
                        itemStyle: { color: catColor[4] }
                    })
                    if (baseTime+nointent.e < oldend) { // 如果有，拆分后一个wake段
                        data.splice(idx+1, 0, {
                            name: oldname,
                            value: [3, baseTime*1000 + nointent.e*1000, oldend*1000, -1],
                            itemStyle: oldstyle,
                        })
                    }
                }
            }
        }
    }
    for (let i=0; i<data.length; i++) {
        if (data[i].value[0]===3) {
            for (let j=0; j<cpc_data.leavebed?.length; j++) {
                let leave = cpc_data.leavebed[j];
                let oldname = data[i].name;
                let oldstart = data[i].value[1]/1000;
                let oldend = data[i].value[2]/1000;
                let oldstyle = data[i].itemStyle;
                if (baseTime+leave.s >= oldstart && baseTime+leave.e <= oldend) { //如果当前wake分期与意图有重叠
                    hasLeaveData = true;
                    let idx = i;
                    if (baseTime+leave.s > oldstart) { // 如果有，拆分第一个wake段，并替换掉原来的wake段
                        data.splice(i, 1, {
                            name: oldname,
                            value: [3, oldstart*1000, baseTime*1000 + leave.s*1000, -1],
                            itemStyle: oldstyle,
                        })
                        idx = i+1;
                    } else { // 否则要删掉原来的wake段
                        data.splice(i, 1);
                    }
                    // 插入一个离床段
                    data.splice(idx, 0, {
                        name: '离床', 
                        value: [5, baseTime*1000 + leave.s*1000, baseTime*1000 + leave.e*1000, -1],
                        itemStyle: { color: catColor[5] }
                    })
                    if (baseTime+leave.e < oldend) { // 如果有，拆分后一个wake段
                        data.splice(idx+1, 0, {
                            name: oldname,
                            value: [3, baseTime*1000 + leave.e*1000, oldend*1000, -1],
                            itemStyle: oldstyle,
                        })
                    }
                }
            }
        }
    }
    
    //在每一段的数据中记住下一个段的分期值
    let firstLegend = null; // 记住第一个出现的需要legend的类型，画legend按出现的顺序来排
    for (let i=0; i<data.length-1; i++) {
        if (data[i].value[0]==4 && !firstLegend) {
            firstLegend = 'intent';
        }
        if (data[i].value[0]==5 && !firstLegend) {
            firstLegend = 'leave';
        }
        if (data[i+1].value[1]==data[i].value[2]) //处理空洞，空洞位置不画连接线
            data[i].value[3] = data[i+1].value[0];
        else
            data[i].value[3] = -1;
    }
    //console.log(data);
  
    var sleepStart = cpc_data.start * 1000;
    var sleepEnd = cpc_data.end * 1000;

    if (cpc_data && cpc_data.segments && cpc_data.segments.length>0) {
        var offset = cpc_data.start;
        var end = cpc_data.end;
        var lastend = 0;
        
        if (cpc_data.segments[0].s >= 0) {
            dataCPC.push([offset*1000, null]);
        }
        for (let i = 0; i<cpc_data.segments.length; i++) {
            if (offset + cpc_data.segments[i].e <= end) {
                dataCPC.push([1000*(offset + cpc_data.segments[i].e), cpc_data.segments[i].v<0 ? null : cpc_data.segments[i].v]);
                lastend = offset + cpc_data.segments[i].e;
            }
        }
        if (lastend < end) {
            dataCPC.push([end*1000, null]);
        }

        //扣掉wake分期的部分
        for (let i=0; i<cpc_data.stages?.length; i++) {
            let seg = cpc_data.stages[i];
            if (seg.value<2)
                dataMask.push ([ { name: L.non_sleep, xAxis: cpc_data.start*1000 + seg.start*1000 }, { xAxis: cpc_data.start*1000 + seg.end*1000 } ]);
        }
    }
    //console.log(dataCPC);
  
    var option = {
        backgroundColor: 'transparent',
        tooltip: {
            trigger: 'axis',
            backgroundColor: getColor('background'),
            borderColor: 'transparent',
            order: 'seriesDesc',
            textStyle: {
                fontSize: 12,
                lineHeight: 9,
                textShadowBlur: 2,
                textShadowColor: 'transparent',
                color: getColor('text'),
            },
            confine: true,
            shadowBlur: 4,
            axisPointer: {
                type: 'line',
                axis: 'x',
                //snap: false,
                label: { 
                    show: true,
                    backgroundColor: '#6a7985',
                    formatter: (params) => formatDateString(params.value, "HHmm"),
                }
            },
            formatter: function (params) {
                if (params[0].seriesIndex==0) {
                    return params[0].name + ' ' + formatDateString(params[0].value[1], "HHmm") + ' - ' + formatDateString(params[0].value[2], "HHmm");
                } else {
                    let t = Math.round(params[0].value[1]*100)/100;
                    return formatDateString(params[0].value[0], "HHmm") + ' ' + t;
                }
            },
        },
        title: [{
                text: '睡眠分期',
                padding: [2, 0, 0, 24],
                textStyle: {
                    fontWeight: 'normal',
                    fontSize: 12,
                }
            }, {
                text: '整晚心肺协同指数',
                padding: [2, 0, 0, 24],
                top: '33%',
                textStyle: {
                    fontWeight: 'normal',
                    fontSize: 12,
                }
            }],
        grid: [{
                left: 24,
                right: '7%',
                top: 18,
                height: '20%',
                //containLabel: true
            }, {
                left: 24,
                right: '7%',
                bottom: 24,
                height: '45%',
                //containLabel: true
            }],
        axisPointer: { link: [{ xAxisIndex: 'all' }] },
        xAxis: [{
            min: 'dataMin',
            max: 'dataMax',
            minInterval: 120*60000,
            maxInterval: 120*60000,
            splitLine: {
                show: true,
                lineStyle: { type: 'dashed' },
            },
            axisLabel: {
                show: false,
            },
            axisPointer: {
                triggerEmphasis: false,
                snap: false,
                label: {
                    show: false,
                }
            }
        }, {
            gridIndex: 1,
            boundaryGap: false,
            min: 'dataMin',
            max: 'dataMax',
            minInterval: 120*60000,
            maxInterval: 120*60000,
            splitLine: {
                lineStyle: { type: 'dashed' },
            },
            axisPointer: {
                snap: false,
                triggerEmphasis: false,
            },
            axisLabel: {
                showMinLabel: true,
                showMaxLabel: true,
                itemStyle: {
                    opacity: 0.2,
                },
                formatter: function (val, index) {
                    var d = new Date(val);
                    var tag = d.getHours()+":"+d.getMinutes().toString().padStart(2, '0');
                    //if (val % 3600000 != 0)
                    if (val===sleepStart || val===sleepEnd)
                    {
                        return '{endtag|' + tag + '}';
                    }
                    else
                        return '{all|' + tag + '}';
                },
                rich: {
                    all: {
                        padding: 2,
                    },
                    endtag: {
                        color: getColor('text'),
                        backgroundColor: getColor('tag'),
                        padding: 2,
                        borderRadius: 2,
                    }
                }
            }
        }],
        yAxis: [{
            gridIndex: 1,
            type: 'value',
            posisiton: 'left',
            min: 0,
            max: 1,
        }, {
            gridIndex: 0,
            data: categories,
            //axisLine: { show: false },
            axisTick: { 
                show: true,
                inside: false,
            },
            axisLabel: {
                show: false,
                inside: true,
                fontSize: 4,
                //padding: [-15, 0, 10, 0],
                verticalAlign: 'top'
            },
            z: 10,
            posisiton: 'left',
        }],
        dataZoom: {
            type: 'inside',
            start: 0,
            end: 100,
            filterMode: 'weakFilter', // empty会exception
            minSpan: 40,
            maxSpan: 100,
            zoomLock: false,
            orient: 'horizontal',
            xAxisIndex: [0, 1]
        },
        /*visualMap: {
            show: false,
            dimension: 1,
            seriesIndex: 1,
            pieces: [
              { gte: 0.8, color: '#26b9da' },
              { lt: 0.8, gte: 0.2, color: '#5ed2a1' },
              { lt: 0.2, color: '#eeb91f' }
            ]
        },*/
        visualMap: {
            show: false,
            dimension: 1,
            seriesIndex: 1,
            type: 'continuous',
            inRange: {
                color: [ledColor[3], ledColor[2], ledColor[1], ledColor[1], ledColor[0], ledColor[0]],
            },
            min: 0,
            max: 1,
        },
        series: [{
            xAxisIndex: 0,
            yAxisIndex: 1,
            type: 'custom',
            clip: false,
            //silent: true,
            renderItem: renderItem,
            itemStyle: {
                borderWidth: 0,
                opacity: 0.9,
                borderColor: '#dddddd'
            },
            dimensions: ['分期', '开始', '结束'],
            encode: {
                x: [1, 2],
                y: 0,
                //tooltip: [1, 2]
            },
            data: data
        }, {
            xAxisIndex: 1,
            yAxisIndex: 0,
            type: 'line',
            symbol: 'none',
            name: 'CPC',
            data: dataCPC,
            itemStyle: { color: '#73c0de' },
            markArea: {
                silent: false,
                label: {
                    show: false,
                },
                itemStyle: {
                    color: getColor('tag'),
                    opacity: 0.4,
                },
                emphasis: {
                    label: {
                        show: true,
                        position: 'insideTop',
                        color: getColor('text'),    
                    }
                },
                data: dataMask,
            },
            encode: {
                x: 0,
                y: 1,
                tooltip: [0, 1],
            }
        }]
    };
    cpc_chart.setOption(option);
}

function initDayCPCChart(canvas, width, height, dpr) {
    var theme = null;
    try{
        let info = wx.getAppBaseInfo();
        theme = info.theme || null;
    }catch(e) {}

    const chart = echarts.init(canvas, theme, {
        width: width,
        height: height,
        devicePixelRatio: dpr,
        renderer: 'svg',
    });
    cpc_chart = chart;
    // 自动关闭tooltip
    let timer = null;
    chart.getZr().handler.on('mouseup', function(e){
        timer = setTimeout(()=>{
            chart.dispatchAction({
                type: 'hideTip'
            })
            /*chart.dispatchAction({
                type: 'updateAxisPointer',
                currTrigger: 'leave',
            })*/
            chart.dispatchAction({
                type: 'downplay',
            })
        }, 4000);
    })
    chart.getZr().handler.on('mousedown', function(e){
        if (timer) {
            clearTimeout(timer);
            timer = null;
        }
    })
    
    setDayCPCChartOption();
    return chart;
}

