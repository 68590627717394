import { createRouter, createWebHashHistory } from 'vue-router'
import DeviceManagement from '../components/DeviceManagement.vue'
import UserManagement from '../components/UserManagement.vue'
import PsgfileManagement from '../components/PsgfileManagement.vue'
import WelcomPage from '../components/WelcomPage.vue';
import WdbViewer from '../components/WdbViewer.vue';
import IccardViewer from '../components/IccardViewer.vue';
import OrganizationManagement from '@/components/OrganizationManagement.vue';
import OrgDeviceManagement from '@/components/OrgDeviceManagement.vue';
import BatchView from '@/components/BatchView.vue';
import DocumentDemo from '../components/DocumentDemo.vue';
import DataDetail from '@/components/DataDetail.vue';
import ReportPerformance from '@/components/ReportPerformance.vue';
import SysDeviceConfig from '@/components/SysDeviceConfig.vue';
import SysDetailView from '@/components/SysDetailView.vue';
import DeviceRriView from '@/components/DeviceRriView.vue';
import DeviceRrvView from '@/components/DeviceRrvView.vue';
import FileManagementView from '@/components/FileManagementView.vue';
import DeviceStability from '@/components/DeviceStability.vue';
import ReportManagement from "@/components/ReportManagement.vue";
const routes = [
  {
    path: '/',
    name: 'Welcome',
    component: WelcomPage
  },
  {
    path: '/device-management',
    name: 'DeviceManagement',
    component: DeviceManagement
  },
  {
    path: '/user-management',
    name: 'UserManagement',
    component: UserManagement
  },
  {
    path: '/data-management',
    name: 'PsgfileManagement',
    component: PsgfileManagement

  },
  {
    path: '/org-management',
    name: 'OrganizationManagement',
    component: OrganizationManagement

  },
  {
    path: '/org-device-management',
    name: 'OrgDeviceManagement',
    component: OrgDeviceManagement

  },
  {
    path: '/wdb-viewer',
    name: 'WdbViewer',
    component: WdbViewer

  },
  {
    path: '/iccard-viewer',
    name: 'IccardViewer',
    component: IccardViewer
  },
  {
    path: '/report-management',
    name: 'ReportManagement',
    component: ReportManagement
  },
  {
    path: '/documentdemo',
    name: 'DocumentDemo',
    component: DocumentDemo

  },
  {
    path: '/batch',
    name: 'BatchView',
    component: BatchView

  },
  {
    path: '/data-detail',
    name: 'DataDetail',
    component: DataDetail

  },
  {
    path: '/report-performance',
    name: 'ReportPerformance',
    component: ReportPerformance

  },
  {
    path: '/sys-config',
    name: 'SysDeviceConfig',
    component: SysDeviceConfig

  },
  {
    path: '/sys-detail',
    name: 'SysDetailView',
    component: SysDetailView

  },
  {
    path: '/rri-detail',
    name: 'DeviceRriView',
    component: DeviceRriView

  },
  {
    path: '/rrv-detail',
    name: 'DeviceRrvView',
    component: DeviceRrvView

  },
  {
    path: '/file_management',
    name: 'FileManagementView',
    component: FileManagementView

  }, {
    path: '/device-stability',
    name: 'DeviceStability',
    component: DeviceStability

  }

];

const router = createRouter({
  history: createWebHashHistory(),
  routes
});

export default router
