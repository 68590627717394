<!-- JsonEdit.vue -->
<template>
  <el-dialog
      v-model="show"
      title="修改信息"
      width="50%"
      :close-on-click-modal="false"
  >
    <JsonEditorVue class="editor" v-model="filteredData"/>

    <template v-slot:footer>
      <el-button @click="closeEditor">取消</el-button>
      <el-button type="primary" @click="saveChanges">保存</el-button>
    </template>
  </el-dialog>
</template>

<script setup>
import {ref, defineProps, defineEmits, watch} from 'vue';
import JsonEditorVue from 'json-editor-vue3';
import {ElDialog, ElButton} from 'element-plus';
import {createAxiosInstance} from "@/plugins/axios";


// Props
const props = defineProps({
  show: Boolean,
  datas: Object,
  selectedDbname: String,
  selectedItem: String,
  serverType: String,
});
const data = ref(props.datas[0])
const show = ref(props.show)
const selectedDbname = ref(props.selectedDbname)
const selectedItem = ref(props.selectedDbname)
// Emit events
const emit = defineEmits(['update:show', 'update:datas', 'save', 'close']);

// Methods
const closeEditor = () => {
  emit('update:show', false);
  emit('close');
};

const saveChanges = async () => {
  const params = {
    table: selectedDbname.value || '',
    item: selectedItem.value || '',
    time_type: 'string',
    value: JSON.stringify(filteredData.value) || ''  // Convert object to JSON string
  };

  const axiosInstance = createAxiosInstance(props.serverType);

  try {
    const response = await axiosInstance.post(`/sys/wdb/edit/`, params);
    console.log(response.data);
    emit('update:show', false);
  } catch (error) {
    console.error('Error during saving changes:', error);
  }
};
//监听 datas 的变化
watch(() => props.datas, (newVal) => {
      if (newVal && newVal.length > 0) {
        data.value = newVal[0];
      } else {
        data.value = newVal;
      }
      filteredData.value = removeFields(data.value, ['refresh_time', 'time_diff', 'modified']);

    }
);
const filteredData = ref([]);

// 定义 removeFields 函数
// 定义 removeFields 函数，用于从对象中移除指定的键
function removeFields(obj, fields) {
  const newObj = {...obj};
  fields.forEach(field => delete newObj[field]);
  return newObj;
}


// 监听 selectedDbname 的变化
watch(() => props.selectedDbname, (newDbname) => {
  selectedDbname.value = newDbname;
});

// 监听 selectedItem 的变化
watch(() => props.selectedItem, (newItem) => {
  selectedItem.value = newItem;
});
</script>

<style scoped>
</style>
