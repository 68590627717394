import { toRaw } from 'vue';
// 打开或创建 IndexedDB 数据库
import * as pako from 'pako';
import { make_timestamp_from_hour } from '@/plugins/timetool';

const msgpack = window.msgpack5();

export async function extract_maped (buffer) {
  const view = new DataView(buffer); // 使用 DataView 读取 ArrayBuffer
  const datatype = view.getUint8(0); // 获取数据类型
  const dataSize = buffer.byteLength - 8; // 计算数据长度

  // 根据不同的数据类型创建相应的视图
  switch (datatype) {
    case 0:
      return new Float32Array(buffer, 8, dataSize / 4);
    case 1:
      return new Float64Array(buffer, 8, dataSize / 8);
    case 2:
      return new Int8Array(buffer, 8, dataSize);
    case 3:
      return new Int16Array(buffer, 8, dataSize / 2);
    case 4:
      return new Int32Array(buffer, 8, dataSize / 4);
    case 5:
      return new Uint8Array(buffer, 8, dataSize);
    case 6:
      return new Uint16Array(buffer, 8, dataSize / 2);
    case 7:
      return new Uint32Array(buffer, 8, dataSize / 4);
    default:
      throw new Error('未知的数据类型');
  }
}

export function extract_packet (buffer) {
  const datalist = []
  let idx = 0
  buffer = pako.inflate(new Uint8Array(buffer)).buffer;

  while (idx + 4 < buffer.byteLength) {
    const dataView = new DataView(buffer, idx);
    const objlen = dataView.getInt32(0, true);

    if (idx + 4 + objlen > buffer.byteLength) break;

    // 提取指定长度的数据片段
    const packData = new Uint8Array(buffer, idx + 4, objlen);

    // 假设存在解码函数 decode
    const decoded = msgpack.decode(packData);

    // 更新偏移量
    idx += 4 + objlen;
    // 将解码结果添加到列表
    datalist.push(decoded);
  }
  return datalist
}

export async function extract_list (buffer) {
  let idx = 0; // 起始偏移量
  const datalist = [];

  // 使用 DataView 直接读取 ArrayBuffer
  while (idx < buffer.byteLength) {
    const dataView = new DataView(buffer, idx);

    // 假设小端字节序读取对象长度
    const objlen = dataView.getInt32(0, true);

    // 检查是否超出 buffer 边界
    if (idx + 4 + objlen > buffer.byteLength) break;

    // 提取指定长度的数据片段
    const packData = new Uint8Array(buffer, idx + 4, objlen);

    // 假设存在解码函数 decode
    const decoded = msgpack.decode(packData);

    // 更新偏移量
    idx += 4 + objlen;

    // 将解码结果添加到列表
    datalist.push(decoded);
  }
  // 构造结果对象
  return datalist
}
export async function extract_file (url, buffer) {
  // 示例解析逻辑，将 buffer 转换为对象并返回
  buffer = await try_decompress(url, buffer);
  const urlinfo = extracturl(url);
  if (urlinfo.filetype === 'map' || urlinfo.filetype === 'mapz') {
    const extracted = await extract_maped(buffer);
    if (extracted) {
      const tm = make_timestamp_from_hour(urlinfo.date, urlinfo.hour);
      const time_pack = create_time_buffer(tm, extracted, 3600);
      time_pack.device = urlinfo.device;
      time_pack.task = urlinfo.task;
      time_pack.type = 1;
      time_pack.column = urlinfo.column;
      return time_pack;
    }
  }
  if (urlinfo.filetype === 'pack' || urlinfo.filetype === 'p') {
    const extractlist = await extract_list(buffer);
    if (extractlist) {
      const tm = make_timestamp_from_hour(urlinfo.date, urlinfo.hour);
      return {
        data: extractlist,
        device: urlinfo.device,
        task: urlinfo.task,
        type: 2,
        column: urlinfo.column,
        starttime: tm
      };
    }
  }
}
function ndarrayDecoder (data) {
  // 分割数据类型和数据
  const nullByteIndex = data.indexOf(0);
  if (nullByteIndex === -1) throw new Error('Invalid data format');
  const dtype = new TextDecoder().decode(data.slice(0, nullByteIndex));
  const dataArrayBytes = new Uint8Array(data.slice(nullByteIndex + 1));
  try {
    switch (dtype) {
      case 'uint16':
        return new Uint16Array(dataArrayBytes.buffer);
      case 'int16':
        return new Int16Array(dataArrayBytes.buffer);
      case 'float32':
        return new Float32Array(dataArrayBytes.buffer);
      case 'float64':
        return new Float64Array(dataArrayBytes.buffer);
      case 'int32':
        return new Int32Array(dataArrayBytes.buffer);
      case 'int64':
        // eslint-disable-next-line no-case-declarations
        const data = new BigInt64Array(dataArrayBytes.buffer);
        // 创建一个 Float32Array 用于存放转换后的值
        const float32Array = new Float32Array(data.length);
        for (let i = 0; i < data.length; i++) {
          float32Array[i] = Number(data[i]); // 使用 Number() 将 BigInt 转为浮点数
        }
        return float32Array;
      case 'int8':
        return new Int8Array(dataArrayBytes.buffer);
      default:
        throw new Error('Unsupported dtype: ' + dtype);
    }
  } catch (e) {
    console.log(e);
    throw new Error('Invalid data format');
  }
}
msgpack.registerDecoder(0x3E, ndarrayDecoder);

async function try_decompress (url, buffer) {
  // 检查 URL 是否以 'z' 结尾
  if (url.endsWith('.mapz') || url.endsWith('.pack')) {
    try {
      // 使用 Pako 进行解压缩，将 Uint8Array 作为输入
      const decompressed = pako.inflate(new Uint8Array(buffer));

      // 返回解压后的 buffer
      return decompressed.buffer;
    } catch (error) {
      console.error('解压缩失败:', error);
      throw new Error('Pako 解压缩过程中出错');
    }
  }

  // 如果 URL 不以 'z' 结尾，返回原始 buffer
  return buffer;
}

export function extracturl (url) {
  // 使用 split 函数解析 URL 的后半部分，从右到左填充
  const parts = url.split('/');
  const filePart = parts.pop();
  let [column, filetype] = filePart.split('.');
  let hour = column;
  if (filetype === 'p' || filetype === 'pack') {
    column = 'list';
  } else {
    hour = parts.pop();
  }
  const date = parts.pop();
  const task = parts.pop();
  const device = parts.pop();

  return {
    device,
    task,
    date,
    hour,
    column,
    filetype
  };
}

export function create_time_buffer (starttime, dataArray, datalen) {
  if (datalen % 3600 !== 0) {
    throw new Error('Data length must be a multiple of 3600.'); // 检查 datalen 是否为 3600 的整数倍
  }

  const freq = dataArray.length / datalen; // 计算采样率
  if (!Number.isInteger(freq)) {
    throw new Error('Frequency must be an integer.'); // 检查 freq 是否为整数
  }

  const _min = getMin(dataArray.constructor);

  const dataBlock = {
    starttime, // 开始时间，以秒为单位，可以有小数部分
    freq, // 数据采样率
    datalen, // 数据集长度
    data: dataArray // 原始数据数组

  };

  if (freq <= 1) return dataBlock;

  // 生成 min_cache 和 max_cache
  const CacheConstructor = dataArray.constructor;
  dataBlock.min_cache = new CacheConstructor(datalen).fill(_min);
  dataBlock.max_cache = new CacheConstructor(datalen).fill(_min);

  for (let sec = 0; sec < datalen; sec++) {
    const start = sec * freq;
    const end = start + freq;

    let min = Infinity;
    let max = -Infinity;

    for (let i = start; i < end; i++) {
      const value = dataArray[i];

      // 判断是否为无效值
      if (value === _min || Number.isNaN(value)) {
        continue; // 忽略无效值
      }

      if (value < min) min = value;
      if (value > max) max = value;
    }

    if (min !== Infinity) dataBlock.min_cache[sec] = min;
    if (max !== -Infinity) dataBlock.max_cache[sec] = max;
  }
  // console.log("max:" + Math.max(...dataBlock.max_cache) + " min:" + Math.min(...dataBlock.min_cache))
  return dataBlock;
}

export function getMin (constructor) {
  switch (constructor) {
    case Int8Array:
      return -128;
    case Uint8Array:
      return 0;
    case Int16Array:
      return -32768;
    case Uint16Array:
      return 0;
    case Int32Array:
      return -2147483648;
    case Uint32Array:
      return 0;
    case Float32Array:
    case Float64Array:
      return NaN;
    default:
      throw new Error('Unsupported data type');
  }
}

export function create_empty_time_buffer (starttime, endtime, obj) {
  // 将 starttime 和 endtime 调整到整数小时
  const adjustedStarttime = Math.floor(starttime / 3600) * 3600; // 向下取整到最近的整点小时
  const adjustedEndtime = Math.ceil(endtime / 3600) * 3600; // 向上取整到下一个整点小时

  // 计算长度，确保它是 3600 的整数倍
  const len = adjustedEndtime - adjustedStarttime;
  if (len % 3600 !== 0) {
    throw new Error('Length must be a multiple of 3600.');
  }

  // 获取原始数据数组的构造函数类型
  const CacheConstructor = obj.data.constructor;
  const _min = getMin(CacheConstructor);
  // 创建填充的空数据数组和缓存数组
  const emptyDataArray = new CacheConstructor(len * obj.freq).fill(_min);
  const cacheLength = len;
  const result = {
    starttime: adjustedStarttime, // 调整后的开始时间
    freq: obj.freq, // 保持与原对象相同的采样率
    datalen: len, // 数据集长度
    data: emptyDataArray, // 空的数据数组
    realtime: createFixedArray(10), // 实时数据数组
    realtime_history: createFixedArray(10), // 实时数据历史数组
    modified_left: null,
    modified_right: null
  };
  if (obj.freq > 1) {
    const min_cache = new CacheConstructor(cacheLength).fill(_min);
    const max_cache = new CacheConstructor(cacheLength).fill(_min);
    result.min_cache = min_cache;
    result.max_cache = max_cache;
  }
  return result;
}

export function createFixedArray (maxLength) {
  const array = [];
  return new Proxy(array, {
    get (target, prop) {
      return target[prop];
    },
    set (target, prop, value) {
      target[prop] = value;
      if (target.length > maxLength) {
        target.shift();
      }
      return true;
    }
  });
}

export function create_empty_event_list (starttime, endtime) {
  // 将 starttime 和 endtime 调整到整数小时
  const adjustedStarttime = Math.floor(starttime / 3600) * 3600; // 向下取整到最近的整点小时
  const adjustedEndtime = Math.ceil(endtime / 3600) * 3600; // 向上取整到下一个整点小时
  return {
    starttime: adjustedStarttime, // 调整后的开始时间
    datalen: adjustedEndtime - adjustedStarttime, // 调整后的结束时间
    data: [],
    realtime: createFixedArray(10),
    realtime_history: createFixedArray(10),
    modified_left: null,
    modified_right: null
  };
}
export function add_event_list (dest_event_list_, source_event_list_) {
  const dest_event_list = toRaw(dest_event_list_);
  const source_event_list = toRaw(source_event_list_);

  const destStart = dest_event_list.starttime;
  const destEnd = destStart + dest_event_list.datalen;

  const destData = dest_event_list.data;
  let sourceData = source_event_list.data;
  if (sourceData[0].data) {
    const newdata = [];
    for (const i in sourceData) {
      const d = sourceData[i].data;
      if (d.starttime) {
        newdata.push(d)
      } else {
        for (const j in d) {
          newdata.push(d[j]);
        }
      }
    }
    sourceData = newdata;
  }
  // 优化判断：如果两个列表没有时间重叠，直接拼接
  if (
    destData.length > 0 &&
    sourceData.length > 0 &&
    destData[destData.length - 1].endtime <= sourceData[0].starttime
  ) {
    // `dest_event_list` 在前，`source_event_list` 在后，直接拼接
    dest_event_list.data = destData.concat(sourceData);
    return;
  }

  if (
    destData.length > 0 &&
    sourceData.length > 0 &&
    sourceData[sourceData.length - 1].starttime <= destData[0].starttime
  ) {
    // `source_event_list` 在前，`dest_event_list` 在后，直接拼接
    dest_event_list.data = sourceData.concat(destData);
    return;
  }

  // 正常的归并排序过程
  const mergedList = [];
  let i = 0;
  let j = 0;

  // 归并排序过程
  while (i < destData.length && j < sourceData.length) {
    const eventA = destData[i];
    let eventB = sourceData[j];
    if (eventB.starttime === null) eventB = eventB[0];
    // 忽略不在时间范围内的事件
    if (eventB.starttime < destStart || eventB.starttime >= destEnd) {
      j++;
      continue;
    }

    if (eventA.starttime < destStart || eventA.starttime >= destEnd) {
      i++;
      continue;
    }

    // 去重检查：如果 starttime 和 endtime 相同，则跳过 eventB
    if (eventA.starttime === eventB.starttime && eventA.endtime === eventB.endtime) {
      j++;
      continue;
    }

    // 比较两个事件的 starttime，选择较小的插入结果列表
    if (eventA.starttime < eventB.starttime) {
      mergedList.push(eventA);
      i++;
    } else if (eventA.starttime > eventB.starttime) {
      mergedList.push(eventB);
      j++;
    } else {
      // 如果 starttime 相同，选择 endtime 较小的
      if (eventA.endtime < eventB.endtime) {
        mergedList.push(eventA);
        i++;
      } else {
        mergedList.push(eventB);
        j++;
      }
    }
  }

  // 处理剩余的 destData 和 sourceData
  while (i < destData.length) {
    const event = destData[i];
    if (event.starttime >= destStart && event.starttime < destEnd) {
      mergedList.push(event);
    }
    i++;
  }

  while (j < sourceData.length) {
    const event = sourceData[j];
    if (event.starttime >= destStart && event.starttime < destEnd) {
      mergedList.push(event);
    }
    j++;
  }

  // 更新 dest_event_list 的数据
  dest_event_list.data = mergedList;
}

export function add_time_buffer (dest_time_buffer, source_time_buffer) {
  const destStart = dest_time_buffer.starttime;
  const destEnd = destStart + dest_time_buffer.datalen;
  const sourceStart = source_time_buffer.starttime;
  const sourceEnd = sourceStart + source_time_buffer.datalen;
  if (dest_time_buffer.freq !== source_time_buffer.freq) {
    throw new Error('Frequency must be the same.');
  }
  // 计算交集部分
  const overlapStart = Math.max(destStart, sourceStart);
  const overlapEnd = Math.min(destEnd, sourceEnd);

  if (overlapStart < overlapEnd) {
    const startOffsetDest = (overlapStart - destStart); // 以秒为单位的偏移量
    const startOffsetSrc = (overlapStart - sourceStart); // 以秒为单位的偏移量
    const overlapLength = (overlapEnd - overlapStart); // 交集部分的长度，以秒为单位

    const freq = dest_time_buffer.freq;
    const dataStartIndexDest = Math.floor(startOffsetDest * freq);
    const dataStartIndexSrc = Math.floor(startOffsetSrc * source_time_buffer.freq);
    const lengthToCopy = Math.floor(overlapLength * freq);

    // 使用 set() 方法复制数据数组的值
    dest_time_buffer.data.set(source_time_buffer.data.subarray(dataStartIndexSrc, dataStartIndexSrc + lengthToCopy), dataStartIndexDest);
    if (freq > 1) {
      // 更新 min_cache 和 max_cache
      const cacheOffsetDest = Math.floor(startOffsetDest);
      const cacheOffsetSrc = Math.floor(startOffsetSrc);
      const cacheLength = Math.ceil(overlapLength);

      const destLength = dest_time_buffer.min_cache.length;
      const srcLength = source_time_buffer.min_cache.length;

      // 计算可以复制的实际长度，避免超出数组边界
      const copyLength = Math.min(cacheLength, destLength - cacheOffsetDest, srcLength - cacheOffsetSrc);

      // 使用 `set` 方法批量复制
      dest_time_buffer.min_cache.set(
        source_time_buffer.min_cache.subarray(cacheOffsetSrc, cacheOffsetSrc + copyLength),
        cacheOffsetDest
      );

      dest_time_buffer.max_cache.set(
        source_time_buffer.max_cache.subarray(cacheOffsetSrc, cacheOffsetSrc + copyLength),
        cacheOffsetDest
      );

      // console.log('min_cache:', Math.min(dest_time_buffer.min_cache), 'max_cache:', Math.max(dest_time_buffer.max_cache));
    }
  } else {
    throw new Error('No overlap between source and destination time buffers.');
  }
}
/**
 * 将数组从采样率 A 重采样到采样率 B，保持输入数据类型不变
 * @param {TypedArray} data - 原始数据数组（如 Int8Array、Uint8Array、Float32Array 等）
 * @param {number} originalRate - 原始采样率 A
 * @param {number} targetRate - 目标采样率 B
 * @returns {TypedArray} - 重采样后的数组，类型与输入数据相同
 */

/**
 * 对数组进行降采样，保持输入数据类型
 * @param {TypedArray} arr - 输入数组（如 Int8Array、Float32Array 等）
 * @param {number} groupSize - 分组大小，用于降采样
 * @returns {TypedArray} - 降采样后的数组，类型与输入相同
 */
export function resampleArrayByGroup (arr, groupSize) {
  const fullGroups = Math.floor(arr.length / groupSize);
  const remainder = arr.length % groupSize;
  const resultLength = fullGroups + (remainder > 0 ? 1 : 0);

  // 使用输入数组的构造函数创建输出数组
  const result = new arr.constructor(resultLength);

  // 计算完整分组的均值
  for (let i = 0; i < fullGroups; i++) {
    let sum = 0;
    for (let j = 0; j < groupSize; j++) {
      sum += arr[i * groupSize + j];
    }
    result[i] = sum / groupSize;
  }

  // 处理剩余元素（如果有）
  if (remainder > 0) {
    let sum = 0;
    for (let i = arr.length - remainder; i < arr.length; i++) {
      sum += arr[i];
    }
    result[resultLength - 1] = sum / remainder;
  }

  return result;
}

export function add_time_buffer_fragment (ddest, ssrc) {
  const dest = toRaw(ddest);
  const src = toRaw(ssrc);

  if (dest === null || dest === undefined || src === null || src === undefined) {
    return;
  }
  // 快速路径：freq = 1 且只有一个点
  if (dest.freq === 1 && src.freq === 1) {
    const srcTime = src.starttime;
    for (const ii in src.data) {
      const i = parseInt(ii);
      const value = src.data[i];
      if (srcTime + i >= dest.starttime && srcTime + i < dest.starttime + dest.datalen) {
        const index = Math.floor(srcTime + i - dest.starttime);
        dest.data[index] = value;
      }
    }
    return;
  }

  try {
    const destStart = dest.starttime;
    const destEnd = destStart + dest.datalen;
    const srcStart = src.starttime;
    const srcEnd = srcStart + (src.data.length / src.freq);
    const _min = getMin(dest.data.constructor);

    // 计算交集部分
    const overlapStart = Math.max(destStart, srcStart);
    const overlapEnd = Math.min(destEnd, srcEnd);

    if (overlapStart < overlapEnd) {
      const startOffsetDest = (overlapStart - destStart);
      const startOffsetSrc = (overlapStart - srcStart);
      const overlapLength = (overlapEnd - overlapStart);

      let srcdata = src.data;
      if (dest.freq !== src.freq) {
        srcdata = resampleArrayByGroup(srcdata, src.freq / dest.freq);
      }

      const dataStartIndexDest = Math.floor(startOffsetDest * dest.freq);
      const dataStartIndexSrc = Math.floor(startOffsetSrc * dest.freq);
      const lengthToCopy = Math.floor(overlapLength * dest.freq);
      const destdata = dest.data;

      // 使用 set() 方法复制数据
      destdata.set(srcdata.subarray(dataStartIndexSrc, dataStartIndexSrc + lengthToCopy), dataStartIndexDest);

      // 更新 min_cache 和 max_cache
      for (let i = dataStartIndexDest; i < dataStartIndexDest + lengthToCopy; i++) {
        const value = destdata[i];
        if (Number.isNaN(value) || value === _min) continue;

        // 计算对应的缓存索引
        const cacheIndex = Math.floor(i / dest.freq);

        // 更新 min_cache 和 max_cache
        dest.min_cache[cacheIndex] = Math.min(dest.min_cache[cacheIndex], value);
        dest.max_cache[cacheIndex] = Math.max(dest.max_cache[cacheIndex], value);
      }
    }
  } catch (e) {
    console.log(e)
  }
}

export function get_event_pos (event_buffer, starttime, endtime, width) {
  const paint_data = {};
  const event_data = toRaw(event_buffer.data);
  try {
    for (let i = 0; i < event_data.length; i++) {
      const data = event_data[i];
      if (data.endtime < starttime) continue;
      if (data.starttime >= endtime) break;
      if (!data.endtime) data.endtime = data.starttime + 1;
      // 计算在canvas上的位置
      data.x1 = Math.floor(width * (data.starttime - starttime) / (endtime - starttime));
      data.x2 = Math.floor(width * (data.endtime - starttime) / (endtime - starttime));
      let group = data.event_type;
      if (data.event_info && data.event_info.stroke) { group = data.event_info.stroke; }

      if (!paint_data[group]) {
        paint_data[group] = [data];
        data.view_type = 1;
        data.hit = 1;
      } else {
        const prev = paint_data[group][paint_data[group].length - 1];
        if (prev.x1 === data.x1 && prev.x2 === data.x2) continue; // 去重
        if (prev.x2 + 1 >= data.x1 && data.x2 - data.x1 < 5) {
          prev.x2 = data.x2;
          data.view_type = 0;
          prev.view_type = 2;
          prev.hit += 1;
        } else {
          data.view_type = 1;
          data.hit = 1;
          paint_data[group].push(data);
        }
      }
    }
  // console.log('end get event_pos:' + (Date.now() - startt))
  } catch (e) {
    console.log(e);
  }
  return paint_data;
}
export function get_canvas_pos (time_buffer, starttime, endtime, width) {
  const databuf = time_buffer.data
  const min_cache = time_buffer.min_cache
  const max_cache = time_buffer.max_cache
  let Cls = databuf.constructor
  if (Cls !== Float64Array) Cls = Float32Array
  const y1 = new Cls(width).fill(NaN);
  const y2 = new Cls(width).fill(NaN);
  const _min = getMin(databuf.constructor);
  const timeRange = endtime - starttime;

  for (let i = 0; i < width; i++) {
    const pixelStartTime = starttime + i * timeRange / width;
    const pixelEndTime = pixelStartTime + timeRange / width;

    const startOffset = Math.max(0, Math.floor((pixelStartTime - time_buffer.starttime) * time_buffer.freq));
    let endOffset = Math.min(databuf.length, Math.floor((pixelEndTime - time_buffer.starttime) * time_buffer.freq));
    if (endOffset === startOffset) endOffset += 1;
    if (pixelEndTime <= time_buffer.starttime || pixelStartTime >= (time_buffer.starttime + time_buffer.datalen)) {
      continue;
    }

    let min = Infinity;
    let max = -Infinity;
    if (time_buffer.freq <= 2) {
      for (let j = startOffset; j < endOffset; j++) {
        if (databuf[j] === _min || isNaN(databuf[j])) continue;
        min = Math.min(min, databuf[j]);
        max = Math.max(max, databuf[j]);
      }
    } else {
      const cacheStart = Math.ceil(startOffset / time_buffer.freq);
      const cacheEnd = Math.floor(endOffset / time_buffer.freq);
      for (let j = cacheStart; j < cacheEnd; j++) {
        if (min_cache[j] === _min || isNaN(min_cache[j])) continue
        min = Math.min(min, min_cache[j])
        max = Math.max(max, max_cache[j]);
      }

      const leftBoundary = Math.min(endOffset, Math.floor(startOffset / time_buffer.freq) * time_buffer.freq);
      for (let j = startOffset; j < leftBoundary; j++) {
        if (databuf[j] === _min || isNaN(databuf[j])) continue;
        min = Math.min(min, databuf[j]);
        max = Math.max(max, databuf[j]);
      }
      // 处理右侧部分（未被缓存完全覆盖的部分）
      const rightBoundary = Math.max(startOffset, (cacheEnd * time_buffer.freq));
      for (let j = rightBoundary; j < endOffset; j++) {
        if (databuf[j] === _min || isNaN(databuf[j])) continue;
        min = Math.min(min, databuf[j]);
        max = Math.max(max, databuf[j]);
      }
    }
    if (min !== Infinity) {
      y1[i] = min;
    }
    if (max !== -Infinity) {
      y2[i] = max;
    }
  }
  // console.log('end get canvas_pos :' + (Date.now() - startt) + ' counter:' + counter + ' jump:' + jump)
  return { y1, y2 };
}
