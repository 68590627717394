import { locales } from '../utils/locales';

import {
  chart_dates, // 图表横轴的日期与报告数据之间的映射关系
  getInterval
} from './variables.js';

import { getColor, getRankColor, seconds2HHmm } from '../utils/utils.js';

const L = locales['zh-Hans']
const monthNameArray = [L.jan, L.feb, L.mar, L.apr, L.may, L.jun, L.jul, L.aug, L.sep, L.oct, L.nov, L.dec];

export function setWASOChartOption (reports, waso_chart) {
  if (!waso_chart) {
    return;
  }

  const dataWASO = [];
  const dataXAxis = [];

  // 从日期序列转换到图表数据
  let weekmode = false;
  if (Object.keys(reports).length > 0) {
    const total = Object.keys(chart_dates).length;
    // 如果周期太长，以周为单位计算均值
    if (total <= 60) {
      // 短周期模式
      for (const key in chart_dates) {
        const [month, day] = key.split('/');
        const abbrKey = month + '-' + day;
        const wasoValue = Math.round(reports[key].waso);
        dataWASO.push({
          name: abbrKey,
          value: wasoValue,
          itemStyle: { color: '#73c0de' }
        });
        dataXAxis.push(abbrKey);
      }
    } else {
      // 长周期模式（按周统计）
      weekmode = true;
      let [waso_avg, count] = [0, 0];
      let valid = false; // 跳过开头的"半周"
      let weekend = '';

      for (const key in chart_dates) {
        const [year, month, day] = key.split('-');
        const d = new Date();
        d.setFullYear(year);
        d.setMonth(month - 1);
        d.setDate(day);
        d.setHours(0, 0, 0, 0);
        const weekday = d.getDay();

        const wasoValue = chart_dates[key] >= 0
          ? Math.round(reports[chart_dates[key]].waso)
          : null;

        if (wasoValue != null && valid) {
          waso_avg += wasoValue;
          count++;
        }

        if (weekday === 6) { // 每周最后一天
          weekend = month + '-' + day;
        }

        if (weekday === 0) { // 每周第一天
          if (valid) {
            waso_avg = count ? Math.round(waso_avg / count) : null;
            const weekRange = month + '-' + day + L.to + weekend;
            const colorRank = getRankColor(null, 'waso', waso_avg);
            dataWASO.push({
              name: weekRange,
              value: waso_avg,
              itemStyle: { color: colorRank }
            });
            dataXAxis.push(weekRange);
            [waso_avg, count] = [0, 0];
            weekend = '';
          } else {
            valid = true;
          }
        }
      }
    }
  }

  dataWASO.reverse();
  dataXAxis.reverse();

  // 处理纵坐标轴的合理性
  let yinterval = 10;
  let ymax = 0;
  for (let i = 0; i < dataWASO.length; i++) {
    if (dataWASO[i].value > ymax) {
      ymax = dataWASO[i].value;
    }
  }
  ymax = Math.ceil(ymax / 20) * 20;
  if (ymax >= 120) {
    yinterval = 60;
  } else {
    yinterval = Math.round(ymax / 40) * 10;
  }

  const option = {
    animationDuration: 500,
    backgroundColor: 'transparent',
    /* title: {
            text: '睡眠中断 (分钟)',
            padding: [0, 0, 0, 32],
            textStyle: {
                fontWeight: 'normal',
                fontSize: 14
            }
        }, */
    tooltip: {
      trigger: 'axis',
      confine: true,
      order: 'seriesDesc',
      shadowBlur: 4,
      backgroundColor: getColor('background'),
      textStyle: {
        fontSize: 12,
        lineHeight: 9,
        textShadowBlur: 2,
        textShadowColor: 'transparent',
        color: getColor('text')
      },
      axisPointer: {
        type: 'line',
        label: {
          show: true,
          backgroundColor: '#ffffff'
        }
      },
      valueFormatter: (value) => {
        if (value != null) {
          return seconds2HHmm(value * 60, true);
        } else {
          return '-';
        }
      }
    },
    grid: {
      top: '5%',
      left: '3%',
      right: '3%',
      bottom: '0%',
      containLabel: true
    },
    xAxis: [{
      type: 'category',
      boundaryGap: true,
      axisTick: { alignWithLabel: true },
      axisLabel: {
        showMinLabel: true,
        showMaxLabel: true,
        fontSize: 10,
        rotate: weekmode ? 0 : 45,
        interval: weekmode ? 0 : getInterval,
        formatter: (value) => {
          if (weekmode) {
            const [weekstart, weekend] = value.split(L.to);
            const [startMonth, startDay] = weekstart.split('-');
            const [endMonth] = weekend.split('-');
            if (startMonth != endMonth || startDay == 1) { // 月初
              return monthNameArray[parseInt(endMonth) - 1];
            } else {
              return ''
            }
          } else {
            return value;
          }
        }
      },
      data: dataXAxis
    }],
    yAxis: {
      type: 'value',
      min: 0,
      interval: yinterval,
      axisLabel: {
        show: true,
        // formatter: '{value}' + L.time_min,
        formatter: (value) => {
          if (yinterval == 60) {
            if (value % 60 != 0) // 非整小时数坐标轴则不显示
            { return null; }
            return Math.round(value / 60) + L.time_hr;
          } else {
            return value + L.time_min;
          }
        }
      }
    },
    series: [{
      name: L.wake_after_sleep_onset,
      type: 'line',
      smooth: true,
      // symbol: weekmode ? 'emptyCircle' : (value, params)=>{ return getSymbol(value, params, dataWASO); },
      symbol: 'circle',
      symbolSize: 5,
      lineStyle: { color: getColor('linegray1') },
      itemStyle: { color: getColor('linegray1') },
      data: dataWASO
    }]
  };
  waso_chart.setOption(option);
}
