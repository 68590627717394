export const chart_dates = {}; // 图表横轴的日期与报告数据之间的映射关系

export function resetDates () {
  for (const key in chart_dates) {
    delete chart_dates[key];
  }
}

export function getInterval (index) {
  let d = 7; // 以间隔多远的距离显示X轴标记
  const total = Object.keys(chart_dates).length;
  if (total <= 8) d = 1; // 8天
  else if (total <= 30) d = 2; // 30天
  else { d = 14; } // 更多天
  const n = (total - 1) % d;
  if (index % d === n) { return true }
  return false;
}
