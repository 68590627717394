<template>
  <el-container style="height: 100vh;">
    <div style="width: 200px; padding: 10px;">
      <el-select v-model="selected" placeholder="下拉框" @change="handleSelect" style="margin-bottom: 20px;">
        <el-option
            v-for="item in options"
            :key="item.value"
            :label="item.label"
            :value="item.value">
        </el-option>
      </el-select>
      <el-input v-model="search" placeholder="搜索设备" class="search-input"></el-input>
      <el-menu v-if="navItems.length" :default-active="activeIndex" class="el-menu-vertical-demo no-style-menu"
               @select="handleMenuSelect">
        <el-menu-item v-for="item in navItems" :key="item" :index="item">{{ item }}</el-menu-item>
      </el-menu>
    </div>

    <el-container>
      <el-main>
        <el-table :data="filteredData" style="width: 100%">
          <el-table-column prop="key" label="Key" width="150">
            <template v-slot="scope">{{ scope.row.key }}</template>
          </el-table-column>
          <el-table-column prop="value" label="Value" width="150">
            <template v-slot="scope">
              <el-input v-model="scope.row.value" placeholder="请输入"></el-input>
            </template>
          </el-table-column>
          <el-table-column prop="type" label="Type" width="150">
            <template v-slot="scope">
              <el-select v-model="scope.row.type" placeholder="请选择">
                <el-option label="float" value="float"></el-option>
                <el-option label="string" value="string"></el-option>
              </el-select>
            </template>
          </el-table-column>
          <el-table-column label="操作" width="120">
            <template v-slot="scope">
              <el-button @click="submitForm(scope.row)">保存</el-button>
            </template>
          </el-table-column>
        </el-table>
      </el-main>
    </el-container>
  </el-container>
</template>
<script setup>
import {  ref, watch } from 'vue';
import { ElMessageBox } from 'element-plus';
import {createAxiosInstance} from "@/plugins/axios";

const selected = ref('');
const search = ref('');
const activeIndex = ref('');
const navItems = ref([]);
const options = ref([
  { value: 'device', label: '设备配置' },
  { value: 'global', label: '全局配置' }
]);
const data_type = ref('');
const deviceData = ref([]);
const globalData = ref([]);
const filteredData = ref([]);
const axiosInstance = createAxiosInstance("数据服务器");

const handleSelect = async (value) => {
  console.log('选择的值:', value);
  let data;
  data_type.value = value === 'device' ? '' : 'sys';

  if (value === 'device' || value === 'global') {
    data = await fetchDevices();
    if (value === 'device') {
      deviceData.value = data;
    } else {
      globalData.value = data;
    }
  }

  navItems.value = data.map(item => Object.keys(item)[0]);
  if (navItems.value.length > 0) {
    activeIndex.value = navItems.value[0];
    updateFilteredData(activeIndex.value, data);
  }
};

const handleMenuSelect = (key) => {
  console.log('菜单选择', key);
  activeIndex.value = key;
  let data = selected.value === 'device' ? deviceData.value : globalData.value;
  updateFilteredData(key, data);
};

const updateFilteredData = (key, data) => {
  const selectedItem = data.find(item => Object.keys(item)[0] === key);
  if (selectedItem) {
    filteredData.value = Object.entries(selectedItem[key]).map(([k, v]) => ({
      key: k,
      value: v,
      type: typeof v === 'number' ? 'float' : 'string'
    }));
  }
};

const fetchDevices = async () => {
  try {
    const response = await axiosInstance.get('/device_config/list/', {
      params: {
        device: search.value,
        data_type: data_type.value
      },
    });
    console.log(response.data);
    return response.data;
  } catch (error) {
    console.error('Failed to fetch device data:', error);
    return [];
  }
};

const submitForm = async (item) => {
  let clo = ''
  const currentSelect = selected.value;
  if (currentSelect === 'global') {
    clo = '全局配置';
  } else {
    clo = '设备配置';
  }

  let params; // 声明 params 变量
  params = new URLSearchParams({
    table: clo || '',
    tag: item.key || '',
    value: item.value || '',
    item: activeIndex.value || '',
    time_type: item.type || '',
  }).toString();

  try {
    const response = await axiosInstance.get(`/config/add/?${params}`);
    console.log(response.data);
    ElMessageBox.alert('保存成功', '成功', {
      confirmButtonText: '确定',
      center: true,
      type: 'success',
    });
    await refreshData();
  } catch (error) {
    console.error('Failed to save data:', error);
    ElMessageBox.alert('保存失败', '错误', {
      confirmButtonText: '确定',
      center: true,
      type: 'error',
    });
  }
};

const refreshData = async () => {
  if (selected.value) {
    const data = await fetchDevices();
    if (selected.value === 'device') {
      deviceData.value = data;
    } else {
      globalData.value = data;
    }
    navItems.value = data.map(item => Object.keys(item)[0]);
    if (navItems.value.length > 0) {
      activeIndex.value = navItems.value[0];
      updateFilteredData(activeIndex.value, data);
    }
  }
};

watch(search, () => {
  refreshData();
});
</script>
<style>
.el-container {
  background-color: #f5f7fa;
}

.el-main {
  padding: 10px;
}

.el-table {
  background-color: white;
}

.no-style-menu .el-menu-item {
  background-color: transparent !important; /* 移除菜单项的背景颜色 */
  color: #333; /* 设置字体颜色 */
}

.no-style-menu .el-menu-item:hover {
  background-color: #f0f0f0 !important; /* 设置鼠标悬停时的背景颜色 */
}
</style>