<template>
    <div>
        <el-tag v-for="(value, key) in info"
                :key="key"
                :disable-transitions="false"
                :style="{ color: getColor(value), borderColor: getColor(value) }">
            {{ key }}
        </el-tag>
    </div>
</template>

<script setup>
    import { defineProps } from 'vue';

    defineProps({
        info: {
            type: Object,
            required: true
        }
    });

    const getColor = (value) => {
        if (value <= -2) return 'red';
        else if (value === -1) return 'orange';
        else if (value === 0) return 'black';
        else if (value === 1) return 'green';
        else if (value >= 2) return 'blue';
        else return 'grey';
    };

</script>

<style scoped>
    /* 可以在这里添加一些额外的样式，如需要的话 */
</style>
