<template>
  <el-card class="header-card">
    <div class="card-header">
      事件日志
    </div>
    <div class="card-content">
      <TreeViewObj :data="logs" :expand="true" />
    </div>
  </el-card>
</template>

<script setup>
import { defineProps, onMounted, onUnmounted, ref } from 'vue';
import TreeViewObj from "@/common/TreeViewObj.vue";
import { getDateTimestamp } from "@/plugins/timetool";
import { wdb_get } from "@/plugins/axios";

const props = defineProps({
  device: String,
  date: String,
});

let requesting_task = 0;
const logs = ref({});

async function getLogs() {
  if (!props.device || requesting_task) return;
  requesting_task = 1;
  logs.value = await wdb_get('设备日志天', props.device, '实时日志', getDateTimestamp(props.date));
  requesting_task = 0;
}

let intervalId = null;
onMounted(async () => {
  intervalId = setInterval(getLogs, 1000);
});

onUnmounted(() => {
  if (intervalId) {
    clearInterval(intervalId);
  }
});
</script>

<style scoped>
.header-card {
  width: 100%;
  max-height: 500px;
  overflow: hidden; /* 确保溢出被内部 div 处理 */
  background-color: black;
  color: yellow;
  box-sizing: border-box;
}

.card-header {
  padding: 10px;
  font-size: 1em;
  font-weight: bold;
}

.card-content {
  max-height: 450px;
  overflow-y: auto; /* 这里启用滚动 */
  padding: 10px;
  box-sizing: border-box;
}
</style>
