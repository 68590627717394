<template>
  <div>
    <div>
      <el-tag v-for="(value, key) in info.info"
              :key="'device-' + key"
              :disable-transitions="false"
              :style="{ color: getColor(value, key), borderColor: getColor(value, key) }">
        {{ key }}
      </el-tag>
    </div>
<div>
<template v-for="(value, key) in info.device">
  <el-tooltip v-if="value['info']"
              :key="'device-tooltip-' + key"
              :content="value['info']"
              placement="top">
    <el-tag :disable-transitions="false"
            :style="{ color: getColor(value['level'], value['tag_name']), borderColor: getColor(value['level'], value['tag_name']) }">
      {{ value['tag_name'] }}<template v-if="value['detail']">:{{value['detail']}}</template>
      <span v-if="value['modify'] === '0' && value['state'] === '0'"
            :style="{ marginLeft: '5px', color: getColor(value['level'], value['tag_name']), borderColor: getColor(value['level'], value['tag_name']), borderRadius: '50%', padding: '2px 5px', display: 'inline-block', border: '1px solid' }">
        !
      </span>
    </el-tag>
  </el-tooltip>
  <el-tag v-else
          :key="'device-tag-' + key"
          :disable-transitions="false"
          :style="{ color: getColor(value['level'], value['tag_name']), borderColor: getColor(value['level'], value['tag_name']) }">
    {{ value['tag_name'] }}
    <template v-if="value['detail']">:{{ value['detail'] }}</template>
    <span v-if="value['modify'] === '0' && value['state'] === '0'"
          :style="{ marginLeft: '5px', color: getColor(value['level'], value['tag_name']), borderColor: getColor(value['level'], value['tag_name']), borderRadius: '50%', padding: '2px 5px', display: 'inline-block', border: '1px solid' }">
    !
    </span>
  </el-tag>
</template>
</div>
    <div>
      <br/>
      <div>
        <el-tag v-for="(value, key) in info.org"
                :key="'org-' + key"
                :disable-transitions="false"
                :style="{ color: getColor(value, key), borderColor: getColor(value, key) }">
          {{ key }}
        </el-tag>
      </div>

    </div>
  </div>
</template>

<script setup>
import {defineProps, watch, ref} from 'vue';

const props = defineProps({
  info: {
    type: Object,
    required: true,
  }
});
const device_info = ref(props.info)

const getColor = (value, key) => {
  if (key === '状态') { // 确保这里的键名与实际使用的一致
    return value === "已停用" ? 'red' : 'blue'; // 根据状态改变颜色
  } else {
    if (value <= -2) return 'red';
    else if (value === -1) return 'orange';
    else if (value === 0) return 'black';
    else if (value === 1) return 'green';
    else if (value >= 2) return 'blue';
    else return 'grey';
  }
};
watch(() => props.info, (newVal) => {
  // 当数据变化时，重新渲染图表
  device_info.value = newVal


}, {deep: true});
</script>

<style scoped>
/* 可以在这里添加一些额外的样式，如需要的话 */
.wrap-text {
  white-space: normal; /* 允许换行 */
  word-break: break-word; /* 在单词结束处断行 */
  width: 200px; /* 设置最大宽度，根据需要调整 */
  height: auto;
}
</style>
