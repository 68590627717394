<template>
  <div class="filter-select">
    <CustomFilter item="device" @filter-changed="handleFilterChange"/>
  </div>
  <div>
    <el-card>
      <el-row class="content-row">
        <el-col :span="8" class="content-col">
          <div class="section">
            <div class="section-header">
              <span>设备列表（{{ total }}）</span>
            </div>
            <div class="clearfix mb-3">
              <el-input
                  v-model="inputFilter"
                  placeholder="搜索设备"
                  :prefix-icon="Search"
                  clearable
                  class="custom-input"
                  @input="onInputFilterChange"
              ></el-input>
            </div>
            <div class="table-container">
              <el-table
                  :data="devices"
                  @selection-change="handleDeviceSelectionChange"
                  style="width: 100%;"
                  :default-selection="defaultSelectedDevices"
              >
                <el-table-column type="selection" width="55"></el-table-column>
                <el-table-column prop="device" label="设备编号" width="200"></el-table-column>
                <el-table-column prop="status" label="基础信息">
                  <template #default="scope">
                    <DeviceSummary :info="scope.row"/>
                  </template>
                </el-table-column>
              </el-table>
            </div>
            <div class="arrow-icon" @click="addToSelected">
              <el-icon>
                <DArrowRight/>
              </el-icon>
            </div>
            <el-pagination
                v-model:current-page="currentPage"
                :page-size="pageSize"
                layout="total, prev, pager, next"
                :total="total"
                @current-change="handleCurrentChange"
            >
            </el-pagination>
          </div>
        </el-col>

        <el-col :span="8" class="content-col">
          <div class="section">
            <div class="section-header">
              <span>已选设备（{{ selectList.length }}）</span>
            </div>
            <div class="table-container">
              <el-table :data="selectList" style="width: 100%;">
                <el-table-column prop="device" label="设备名称" style="width: 100%;">
                  <template #default="scope">
                    <div style="display: flex; justify-content: space-between; align-items: center;">
                      <span>{{ scope.row }}</span>
                      <el-button type="text" @click="removeFromSelected(scope.row)">X</el-button>
                    </div>
                  </template>
                </el-table-column>
              </el-table>
            </div>
            <div style="margin-top: 20px">
              <el-button type="danger" @click="clearSelected">清空</el-button>
            </div>
          </div>
        </el-col>

        <el-col :span="8" class="content-col">
          <div class="section">
            <div class="section-header">
              <span>任务列表</span>
            </div>
            <div class="table-container">
              <!-- 使用 el-collapse 控制多个折叠面板 -->
              <el-collapse v-model="activeNames" accordion>
                <el-collapse-item v-for="(tasks, category, index) in tableData" :key="index" :title="category"
                                  :name="String(index + 1)">
                  <div class="table-container">
                    <el-table :data="tasks"
                              @selection-change="(selection) => handleTaskSelectionChange(category, selection)"
                              style="width: 100%;">
                      <el-table-column type="selection" width="55"></el-table-column>
                      <el-table-column prop="name" label="任务名称" width="120"></el-table-column>
                    </el-table>
                  </div>
                </el-collapse-item>
              </el-collapse>
            </div>
            <div class="footer">
              <div>
                <p>选择日期或日期区间:</p>
                <el-date-picker
                    v-model="formattedDateRange"
                    type="daterange"
                    range-separator="至"
                    start-placeholder="开始日期"
                    end-placeholder="结束日期"
                    @change="handleDateChange"
                    style="margin-right: 10px;"
                ></el-date-picker>
              </div>

            </div>
            <div style="margin-top: 20px">
              选择运行服务器
              <el-select v-model="serverType" placeholder="选择服务器"
                         style="width: 20%;">
                <el-option label="数据服务器" value="数据服务器"></el-option>
                <el-option label="计算服务器1" value="计算服务器1"></el-option>
                <el-option label="计算服务器2" value="计算服务器2"></el-option>
              </el-select>
            </div>
            <div style="margin-top: 20px">
              <el-button type="primary" @click="runTasks">运行</el-button>
            </div>
          </div>
        </el-col>
      </el-row>

      <el-row>
        <el-col :span="24">
          <el-card>
            <template v-slot:header>
              <div class="clearfix">
                <span>任务进度</span>
              </div>
            </template>
            <div class="summary">
              <div>运行中: {{ task_list.running_count }}</div>
              <div>运行任务的设备: {{ task_list.run_count }}</div>
            </div>
            <el-table :data="formattedTaskList" class="task-table">
              <el-table-column prop="device" label="设备"></el-table-column>
              <el-table-column label="任务名称">
                <template v-slot="scope">
                  <div v-for="task in scope.row.tasks" :key="task.name">{{ task.name }}</div>
                </template>
              </el-table-column>
              <el-table-column label="状态">
                <template v-slot="scope">
                  <div v-for="task in scope.row.tasks" :key="task.name">{{ task.status }}</div>
                </template>
              </el-table-column>
            </el-table>
          </el-card>
        </el-col>
      </el-row>
    </el-card>
  </div>
</template>

<script setup>
import {ref, onMounted, watch, computed, onUnmounted, watchEffect} from 'vue';
import {createAxiosInstance, wdb_get} from "@/plugins/axios";
import {DArrowRight, Search} from "@element-plus/icons-vue";
import DeviceSummary from "@/components/DeviceSummary.vue";
import CustomFilter from "@/common/CustomFilter.vue";
const currentPage = ref(1);
const pageSize = ref(100);
const inputFilter = ref("");
const devices = ref([]);
const selectedDeviceIds = ref([]);
//选择任务
const taskProgress = ref([]);
let encodedFilterStr = "";
const total = ref(100);
let fetching = 0;
//选择设备
const selectList = ref([]);
//选择日期
const dateRange = ref([])
const tableData = ref({})
const activeNames = ref(['1']); // 默认展开第一个面板
const selectedTasks = ref({});
const task_list = ref([])
let intervalId = null; // 用于存储定时器ID
const serverType = ref('数据服务器')
const handleTaskSelectionChange = (category, selection) => {
  selectedTasks.value[category] = selection;
  // 深度复制数据

};
const formattedDateRange = ref([]);

// 观察 dateRange 的变化
watchEffect(() => {
  if (dateRange.value && dateRange.value.length === 2) {
    const start = new Date(dateRange.value[0]);
    const end = new Date(dateRange.value[1]);

    // 判断是否为同一天
    if (start.toDateString() === end.toDateString()) {
      formattedDateRange.value = [start.toISOString().substring(0, 10)];
    } else {
      formattedDateRange.value = [
        start.toISOString().substring(0, 10),
        end.toISOString().substring(0, 10)
      ];
    }
  }
});

// 处理日期变化
function handleDateChange(value) {
  if (value && value.length === 2) {
    const startDate = new Date(new Date(value[0]).setHours(0, 0, 0, 0)).getTime();
    const endDate = new Date(new Date(value[1]).setHours(23, 59, 59, 999)).getTime();

    // 判断是否为同一天
    if (new Date(startDate).toDateString() === new Date(endDate).toDateString()) {
      dateRange.value = [startDate];
      console.log('选择的日期时间戳:', startDate);
    } else {
      dateRange.value = [startDate, endDate];
      console.log('开始时间戳:', startDate, '结束时间戳:', endDate);
    }
  }
}

async function getData() {
  const task_list = ['device', 'report'];


  for (const type of task_list) {
    selectedTasks.value[type] = [];

    const values = await wdb_get('.system_task', type);
    const newvalues = [];
    let flag = 1;
    for (var i in values) {
      if (i != "refresh_time" && i != "time_diff" && i != "modified")
        newvalues.push({'id': flag, 'name': i});
      flag += 1;
    }
    tableData.value[type] = newvalues

  }
}

function onInputFilterChange() {
  encodedFilterStr = "search:*" + inputFilter.value + "|";
  fetchData();
}

// const handleSizeChange = (newSize) => {
//   pageSize.value = newSize;
//   fetchData();
// };
const handleCurrentChange = (val) => {
  currentPage.value = val;
  fetchData();
};

onMounted(() => {
  getData();
  fetchData();
  fetchTaskData()

  intervalId = setInterval(fetchTaskData, 1000);

});
onUnmounted(() => {

  if (intervalId) {
    clearInterval(intervalId); // 清除定时器
  }
});
const fetchData = async () => {
  if (fetching) return;
  try {
    fetching = 1;
    const axiosInstance = createAxiosInstance('数据服务器');
    const response = await axiosInstance.get('/device/?filter=' + encodedFilterStr + '&page=' + currentPage.value + '&pagesize=' + pageSize.value);
    fetching = 0;
    processResponse(response);
  } catch (error) {
    console.error('失败:', error);
    fetching = 0;
  }
};
const processResponse = (response) => {
  devices.value = response.data.data.nodes;
  total.value = response.data.data.count;
};
const handleDeviceSelectionChange = (val) => {
  selectedDeviceIds.value = val.map(item => item.device);
}

watch([selectedDeviceIds, selectedTasks], ([newTasks]) => {
  taskProgress.value = newTasks.map(task => ({
    id: task.id,
    name: task.name,
    progress: 0
  }));
})
const addToSelected = () => {
  const set = new Set([...selectList.value, ...selectedDeviceIds.value]);
  selectList.value = Array.from(set);
}
const defaultSelectedDevices = computed(() => {
  const a = devices.value.filter(device => selectList.value.includes(device.device));
  return a
});

async function runTasks() {
  console.log(dateRange.value)
  const axiosInstance = createAxiosInstance(serverType.value);
  console.log(serverType.value)
  await axiosInstance.post('/batch/task/', {
    tasks: selectedTasks.value,
    devices: selectList.value,
    dateRange: dateRange.value
  }, {
    headers: {
      'Content-Type': 'application/json'
    }
  });
}

const removeFromSelected = (device) => {
  selectList.value = selectList.value.filter(item => item !== device);
};
const clearSelected = () => {
  selectList.value = []
}
const handleFilterChange = async (filterStr) => {
  encodedFilterStr = encodeURIComponent(filterStr);
  fetchData();
};

const fetchTaskData = async () => {
  try {
    fetching = 1;
    const axiosInstance = createAxiosInstance(serverType.value);
    console.log(serverType.value)
    const response = await axiosInstance.get('/tasks/');
    fetching = 0;
    task_list.value = response.data;
  } catch (error) {
    console.error('失败:', error);
    fetching = 0;
  }
};
const formattedTaskList = computed(() => {
  if (Array.isArray(task_list.value.running_list)) {
    return task_list.value.running_list.map(deviceTasks => {
      const device = Object.keys(deviceTasks)[0];
      const tasks = Object.keys(deviceTasks[device]).map(taskName => {
        return {
          name: taskName,
          status: deviceTasks[device][taskName].status
        };
      });
      return {device, tasks};
    });
  }
  return [];
});


</script>


<style scoped>
.mb-3 {
  margin-bottom: 20px;
}

.progress-item {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.progress-label {
  width: 150px;
  margin-right: 20px;
}

.progress-bar {
  flex-grow: 1;
}

.custom-input {
  width: 80%;
}

.table-container {
  max-height: 600px;
  overflow-y: auto;
}

.content-row {
  border-top: 1px solid #ebeef5;
  border-bottom: 1px solid #ebeef5;
  padding: 20px 0;
  position: relative; /* 确保子元素的绝对定位是相对于这个父元素 */
}

.content-col {
  border-right: 1px solid #ebeef5;
  position: relative; /* 确保子元素的绝对定位是相对于这个父元素 */
}

.content-col:last-child {
  border-right: none;
}

.section {
  height: 65vh; /* 可以根据需要调整这个高度 */
  display: flex;
  flex-direction: column;
}

.section-header {
  font-weight: bold;
  margin-bottom: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.arrow-icon {
  position: absolute;
  top: 40%;
  right: 20px; /* 根据需要调整 */
  transform: translateY(-50%);
  cursor: pointer;
  z-index: 1000; /* 确保它在最上层 */
  background-color: rgba(64, 158, 255, 0.2); /* 半透明背景颜色 */
  padding: 8px; /* 内边距 */
  color: #409EFF; /* 图标颜色 */
  border: 1px solid #409EFF; /* 边框 */
  border-radius: 4px; /* 圆角边框 */
  transition: background-color 0.2s; /* 背景颜色过渡效果 */
}

.footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
}

.summary {
  margin-bottom: 20px;
}

.task-table .el-table-column {
  text-align: center;
}

.el-card {
  padding: 20px;
}

.el-card .clearfix {
  display: flex;
  justify-content: space-between;
}

.el-card .clearfix span {
  font-weight: bold;
}

.el-table {
  width: 100%;
}

.el-table-column {
  padding: 10px;
}
</style>