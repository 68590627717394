import { getColor, formatDateString } from '../utils/utils';

export function setDayHRVChartOption (hrv_chart, hrv_data) {
  if (!hrv_chart || !hrv_data) {
    return;
  }

  const data = [];
  const dataMask = [];
  if (hrv_data && hrv_data.segments) {
    const offset = hrv_data.start;
    const end = hrv_data.end;
    let lastend = 0;
    if (hrv_data.segments[0].time > 0) {
      data.push([offset, null]);
    }
    let max = 0;
    for (let i = 0; i < hrv_data.segments.length; i++) {
      data.push([offset + hrv_data.segments[i].time, hrv_data.segments[i].value <= 0 ? null : hrv_data.segments[i].value]);
      lastend = offset + hrv_data.segments[i].time;
      if (hrv_data.segments[i].value > max) {
        max = hrv_data.segments[i].value;
      }
    }
    if (lastend < end) {
      data.push([end, null]);
    }

    for (let i = 0; i < hrv_data.nointention?.length; i++) {
      const seg = hrv_data.nointention[i];
      if (seg.e - seg.s > 60) { dataMask.push([{ name: '活动', xAxis: hrv_data.start + seg.s }, { xAxis: hrv_data.start + seg.e }]); }
    }
    for (let i = 0; i < hrv_data.leavebed?.length; i++) {
      const seg = hrv_data.leavebed[i];
      if (seg.e - seg.s > 60) { dataMask.push([{ name: '离床', xAxis: hrv_data.start + seg.s }, { xAxis: hrv_data.start + seg.e }]); }
    }
  }

  const option = {
    backgroundColor: 'transparent',
    title: {
      text: '整晚HRV RMSSD',
      padding: [0, 0, 0, 32],
      textStyle: {
        fontWeight: 'normal',
        fontSize: 12
      }
    },
    grid: {
      left: '2%',
      right: '7%',
      top: '14%',
      bottom: '3%',
      containLabel: true
    },
    xAxis: {
      min: 'dataMin',
      max: 'dataMax',
      minInterval: 120 * 60,
      maxInterval: 120 * 60,
      axisLabel: {
        showMinLabel: true,
        showMaxLabel: true,
        formatter: function (val) {
          const d = new Date(val * 1000);
          return d.getHours() + ':' + d.getMinutes().toString().padStart(2, '0');
        }
      },
      splitLine: {
        lineStyle: { type: 'dashed' }
      }
    },
    yAxis: {
      type: 'value',
      minInterval: 10,
      min: function (value) { return Math.floor(value.min / 10) * 10 },
      max: function (value) { return Math.ceil(value.max / 10) * 10 }
    },
    tooltip: {
      trigger: 'axis',
      confine: true,
      shadowBlur: 4,
      backgroundColor: getColor('background'),
      textStyle: {
        fontSize: 12,
        lineHeight: 9,
        textShadowBlur: 2,
        textShadowColor: 'transparent',
        color: getColor('text')
      },
      formatter: function (params) {
        return params[0].marker +
                    formatDateString(params[0].value[0] * 1000, 'HHmm') +
                    '  ' +
                    (params[0].value[1] ? Math.round(params[0].value[1]) + '毫秒' : '无');
      }
    },
    series: [
      {
        type: 'line',
        data,
        itemStyle: { color: '#e7a370' },
        // smooth: true,
        markLine: {
          silent: true,
          symbol: 'none',
          label: {
            position: 'insideStartTop',
            formatter: function () {
              // return '平均' + Math.round(params.value);
              return '平均' + Math.round(hrv_data.avg);
            }
          },
          data: [[{
            name: '平均',
            coord: [hrv_data.start + (hrv_data.intention || 0), hrv_data.avg]
          }, {
            coord: [hrv_data.end - (hrv_data.leave ? (hrv_data.end - hrv_data.start - hrv_data.leave) : 0), hrv_data.avg]
          }]],
          lineStyle: { opacity: 0.8 }
        },
        symbol: function (value, params) { // 单点数据需要显示symbol，能连成线则不显示
          const left = params.dataIndex - 1;
          const right = params.dataIndex + 1;
          let symbol = 'none';
          if (value) {
            if (left >= 0 && right < data.length) { // 中间单点
              if (!data[left][1] && !data[right][1]) symbol = 'circle';
            } else if (right < data.length) { // 最左边缘
              if (!data[right][1]) symbol = 'circle';
            } else if (left >= 0) { // 最右边缘
              if (!data[left][1]) symbol = 'circle';
            }
          }
          return symbol;
        },
        markArea: {
          silent: false,
          label: {
            show: false
          },
          itemStyle: {
            color: '#CCC',
            opacity: 0.4
          },
          emphasis: {
            label: {
              show: true,
              position: 'right',
              color: getColor('text')
            }
          },
          data: dataMask
        },
        encode: {
          x: 0,
          y: 1,
          tooltip: [0, 1]
        }
      }
    ]
  };

  hrv_chart.setOption(option);
}

export function setDayHrvLHChartOption (hrvLH_chart, hrvLH_data) {
  if (!hrvLH_chart || !hrvLH_data) {
    return;
  }

  const data = [];
  if (hrvLH_data && hrvLH_data.segments) {
    const offset = hrvLH_data.start;
    const end = hrvLH_data.end;
    let lastend = 0;
    if (hrvLH_data.segments[0].time > 0) {
      data.push([offset, null]);
    }
    for (let i = 0; i < hrvLH_data.segments.length; i++) {
      data.push([offset + hrvLH_data.segments[i].time, hrvLH_data.segments[i].value <= 0 ? null : hrvLH_data.segments[i].value]);
      lastend = offset + hrvLH_data.segments[i].time;
    }
    if (lastend < end) {
      data.push([end, null]);
    }
  }

  const option = {
    backgroundColor: 'transparent',
    title: {
      text: 'HRV LF/HF',
      padding: [0, 0, 0, 36],
      textStyle: {
        fontWeight: 'normal',
        fontSize: 14
      }
    },
    grid: {
      left: '2%',
      right: '7%',
      top: '14%',
      bottom: '3%',
      containLabel: true
    },
    xAxis: {
      min: 'dataMin',
      max: 'dataMax',
      minInterval: 120 * 60,
      maxInterval: 120 * 60,
      axisLabel: {
        showMinLabel: true,
        showMaxLabel: true,
        formatter: function (val) {
          const d = new Date(val * 1000);
          return d.getHours() + ':' + d.getMinutes().toString().padStart(2, '0');
        }
      },
      splitLine: {
        show: false
      }
    },
    yAxis: {
      type: 'value',
      minInterval: 5,
      min: function (value) { return Math.floor(value.min / 5) * 5 },
      max: function (value) { return Math.ceil(value.max / 5) * 5 }
    },
    tooltip: {
      trigger: 'axis',
      confine: true,
      shadowBlur: 4,
      backgroundColor: getColor('background'),
      textStyle: {
        fontSize: 12,
        lineHeight: 9,
        textShadowBlur: 2,
        textShadowColor: 'transparent',
        color: getColor('text')
      },
      formatter: function (params) {
        return params[0].marker +
                    formatDateString(params[0].value[0] * 1000, 'HHmm') +
                    // + ' - ' + params[0].value[1];
                    '  ' + (params[0].value[1] ? params[0].value[1] : '无');
      }
    },
    series: [
      {
        type: 'line',
        data,
        itemStyle: { color: '#89cff0' },
        markLine: {
          silent: true,
          symbol: 'none',
          label: {
            position: 'insideStartTop',
            formatter: function () {
              // return '平均' + params.value;
              return '平均' + hrvLH_data.avg;
            }
          },
          // data: [ { type: 'average', name: '平均' } ],
          data: [[{ name: '平均', coord: [hrvLH_data.start + (hrvLH_data.intention || 0), hrvLH_data.avg] }, { coord: [hrvLH_data.end, hrvLH_data.avg] }]],
          lineStyle: { opacity: 0.8 }
        },
        symbol: function (value, params) { // 单点数据需要显示symbol，能连成线则不显示
          const left = params.dataIndex - 1;
          const right = params.dataIndex + 1;
          let symbol = 'none';
          if (value) {
            if (left >= 0 && right < data.length) { // 中间单点
              if (!data[left][1] && !data[right][1]) symbol = 'circle';
            } else if (right < data.length) { // 最左边缘
              if (!data[right][1]) symbol = 'circle';
            } else if (left >= 0) { // 最右边缘
              if (!data[left][1]) symbol = 'circle';
            }
          }
          return symbol;
        },
        /* markPoint: {
                silent: true,
                symbol: 'circle',
                data: [ { type: 'min', name: 'Min', symbolSize: 10 } ],
                label: {
                    position: 'right',
                    formatter: function(params) {
                        return '最低'+Math.round(params.value);
                    }
                },
                itemStyle: { opacity: 0.8 }
            }, */
        markArea: hrvLH_data.intention
          ? {
              silent: true,
              label: {
                show: false
              },
              itemStyle: {
                color: '#CCC',
                opacity: 0.4
              },
              emphasis: {
                label: {
                  show: true,
                  position: 'right',
                  color: getColor('text')
                }
              },
              data: [[{ name: '在床非睡眠时段', xAxis: hrvLH_data.start }, { xAxis: hrvLH_data.start + hrvLH_data.intention }]]
            }
          : {},
        encode: {
          x: 0,
          y: 1,
          tooltip: [0, 1]
        }
      }
    ]
  };

  hrvLH_chart.setOption(option);
}
