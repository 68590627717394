export const locales = {
    'zh-Hans': {
        // Tabbar
        'summary': '摘要',
        'details': '报告详情',
        'consultant': 'AI睡眠顾问',
        'my': '我的',
        // 日历
        'sun': '日', 'mon': '一', 'tue': '二', 'wed': '三', 'thu': '四', 'fri': '五', 'sat': '六',
        'jan': '一月', 'feb': '二月', 'mar': '三月', 'apr': '四月', 'may': '五月', 'jun': '六月', 'jul': '七月', 'aug': '八月', 'sep': '九月', 'oct': '十月', 'nov': '11月', 'dec': '12月',
        // 图表
        'illuminance': '光照',
        'temperature_abbr': '温度',
        'humidity_abbr': '湿度',
        'recent_trend': '近期趋势',
        'movement': '体动',
        'few': '很少',
        'occasional': '偶尔',
        'frequent': '频繁',
        'times': '次',
        'osa_mild': '轻度',
        'osa_moderate': '中度',
        'osa_severe': '重度',
        'blood_oxygen_drop': '整晚估算血氧下降百分比',
        'normal': '正常',
        'pay_attention': '需注意',
        'non_sleep': '非睡眠',
        'personal_baseline': '个人基线区间',
        'baseline': '个人基线区间',
        'out_of_baseline': '超出基线区间',
        'whole_night_apnea_hypopnea_events': '睡眠分期',
        'apnea_hypopnea_events': '呼吸事件',
        'average': '平均',
        'whole_night_sleeping_heart_rate': '整晚睡眠心率',
        'whole_night_hrv_rmssd': '整晚HRV RMSSD',
        'whole_night_sleeping_respiratory_rate': '整晚睡眠呼吸频率',
        'whole_night_snore_events': '整晚鼾声事件',
        'to': ' 至 ',
        'time_in_bed': '卧床时长',
        'rem_duration': '快速眼动时长',
        'deep_sleep_duration': '深睡时长',
        'in_bed': '上床',
        'get_up': '起床',
        'lowest_hr': '最低心率',
        'lowest_hr_baseline': '最低心率个人基线区间',
        'average_hr': '平均心率',
        'once': '1次',
        'twice': '2次',
        'none': '无',
        // 首页
        'index_title': '五季医学睡眠报告',
        'date': '日期',
        'from': '来自',
        'today_summary': '今日摘要',
        'report_summary': '报告摘要',
        'connection': '连接',
        'disconnected': '断开',
        'sleep_score': '睡眠质量',
        'sleep_time': '睡眠时长',
        'deep_sleep': '深睡眠',
        'wake': '清醒',
        'rem': '快速眼动',
        'light': '浅睡',
        'deep': '深睡',
        'active': '活动',
        'leave_bed': '离床',
        'ahi': '呼吸暂停低通气指数(AHI)',
        'lowest_heart_rate': '睡眠最低心率',
        'hrv': '心率变异性(HRV)',
        'bpm': '次/分',
        'ms': '毫秒',
        's': '秒',
        'light_ratio': '光照影响',
        'light_ratio_short': '光照影响',
        'temperature': '温度',
        'humidity': '湿度',
        'nodata': '无数据',
        'nodata_abbr': '无',
        'select_feel': '请选择睡眠感受',
        'recorded_feel': '记录的睡眠感受',
        'osa': '阻塞性睡眠呼吸暂停',
        'osa_healthy': '正常',
        'osa_mild_risk': '轻度风险',
        'osa_moderate_risk': '中度风险',
        'osa_severe_risk': '重度风险',
        'status_normal': '正常',
        'status_low': '偏低',
        'status_high': '偏高',
        'status_optimal': '适宜',
        'time_min': '分',
        'time_minute': '分钟',
        'time_hr': '时',
        'err_login': '立即开始使用睡眠监测垫，了解自己的睡眠。请先登录并绑定设备编号。',
        'err_bind': '立即开始使用睡眠监测垫，了解自己的睡眠。请先绑定设备编号。',
        'err_zero': '当前尚无您的睡眠报告。立即开始使用睡眠监测垫，了解今晚的睡眠。',
        'err_network': '网络或服务错误，请稍后刷新重试。',
        'err_go_login': '去登录',
        'err_go_bind': '去绑定',
        'err_contact_service': '联系客服',
        'err_check_device': '查看设备',
        // 报告详情页
        'details_title': '睡眠报告详情',
        'trends': '趋势',
        'sleep_parameters': '睡眠分析',
        'sleep_quality': '睡眠质量',
        'vs_yesterday': '较昨天',
        'vs_weekly_avg': '较周平均',
        'sleep_structure': '睡眠结构',
        'respiratory_health': '呼吸健康',
        'heart_health': '心脏健康',
        'sleeping_heart_rate': '睡眠心率',
        'sleeping_hrv': '睡眠心率变异性',
        'sleeping_respiratory_rate': '睡眠呼吸频率',
        'sleep_environment': '睡眠环境',
        'data_labs': '更多数据研究',
        'generated': '生成时间',
        'total_sleep_time': '总睡眠时长',
        'sleep_efficiency': '睡眠效率',
        'sleep_onset_latency': '入睡速度',
        'wake_after_sleep_onset': '睡眠中断时长',
        'sleep_disturbance': '睡眠中断',
        'rem_sleep': '快速眼动睡眠',
        'light_sleep': '浅睡眠',
        'sleep_regularity': '睡眠规律性',
        'sleep_wakeup_time': '入睡/醒来时间',
        'nrem_ahi': '非快速眼动期AHI',
        'rem_ahi': '快速眼动期AHI',
        'avg_hrv_rmssd': '平均HRV RMSSD',
        'avg_heart_rate': '睡眠平均心率',
        'avg_respiratory_rate': '平均呼吸频率',
        'avg_temperature': '平均温度',
        'avg_humidity': '平均湿度',
        'lowest': '最低',
        'highest': '最高',
        'light_exposure_ratio': '光照影响的睡眠比例',
        'light_exposure_ratio_short': '光照影响的睡眠',
        'ideal': '理想',
        'good': '良好',
        'fair': '一般',
        'long': '偏长',
        'too_long': '过长',
        'late': '偏晚',
        'too_late': '过晚',
        'short': '偏短',
        'too_short': '过短',
        'low': '偏低',
        'too_low': '过低',
        'little': '偏少',
        'too_little': '过少',
        'irregular': '不规律',
        'not_applicable': '暂无',
        'ahi_comment_no_risk': 'AHI数据显示没有阻塞性睡眠呼吸暂停风险，建议持续关注这一指标。',
        'ahi_comment_mild_risk': 'AHI数据显示<b>轻度阻塞性睡眠呼吸暂停风险</b>，建议持续关注这一指标，并在必要的时候前往专业医疗机构进行进一步咨询和检查。',
        'ahi_comment_moderate_risk': 'AHI数据显示<b>中度阻塞性睡眠呼吸暂停风险</b>，建议持续关注这一指标，并在必要的时候前往专业医疗机构进行进一步咨询和检查。',
        'ahi_comment_severe_risk': 'AHI数据显示<b>重度阻塞性睡眠呼吸暂停风险</b>，建议持续关注这一指标，并在必要的时候前往专业医疗机构进行进一步咨询和检查。',
        'snore': '鼾声',
        'snore_analysis': '鼾声分析',
        'snore_index': '鼾声指数',
        'timing': '就寝时间',
        // 数据研究页
        'data_lab_title': '数据研究',
        'back': '返回',
        'about_data_lab': '关于数据研究指标',
        'about_data_lab_details': '展示供科研评价目的的测试指标和功能，旨在为研究人员提供更多具有医学和科研价值的指标，也供有兴趣的用户更深入的探索个人健康数据。',
        'ah_events_distribution': '呼吸事件的分布',
        'avg_hrv_lfhf': '平均HRV LF/HF',
        'light_sleep_ahi': '浅睡期AHI',
        'deep_sleep_ahi': '深睡期AHI',
        'rr_plot': 'RR间期散点图',
        'rrv_plot': '呼吸率变异性散点图',
        'avg_rrv': '平均呼吸率变异性',
        'odi': '氧减指数(ODI)',
        'light_sleep_odi': '浅睡期ODI',
        'deep_sleep_odi': '深睡期ODI',
        'rem_odi': '快速眼动期ODI',
        'nrem_odi': '非快速眼动期ODI',
        'oxygen': '血氧',
        'whole_night': '整晚',
        'rem_latency': '快速眼动睡眠潜伏期',
        // 顾问页
        'consultant_title': 'AI睡眠顾问',
        'beta': '测试版',
        'enter_your_question': '输入您想了解的问题',
        'connecting': '正在连接，请稍候',
        'for_reference_only': '仅供参考，不作为医疗依据。如有疑问请咨询医师。',
        // 我的设置页
        'account_settings': '个人设置',
        'fill_in_personal_information': '填写个人信息',
        'update_personal_information': '更新个人信息',
        'bind_your_device': '绑定设备',
        'device_settings': '设备设置',
        'set_up_wifi': '配置设备的WiFi',
        'check_device_status': '查看设备状态',
        'view_manual': '查看说明书',
        'contact_customer_service': '联系客服',
        'forward_to_friends': '推荐给好友',
        'please_fill_in': '请填写',
        'please_set_up': '建议配置',
        'long_press_to_follow': '长按二维码关注公众号，睡眠报告自动推送。',
        'binded_to': '已绑定',
        'not_binded': '尚未绑定',
        // 数据趋势页
        'trend_title': '长期趋势',
        'month': '月',
        'year': '年',
        'previous_month': '前一月',
        'next_month': '后一月',
        'previous_year': '前一年',
        'next_year': '后一年',
        'basic_sleep_parameters': '睡眠指标',
        'rem_and_deep_duration': '快速眼动和深睡时长',
        'sleep_stages_duration': '各睡眠阶段的时长',
        'sleep_stages_ahi': '不同睡眠阶段的AHI',
        'lowest_and_average_heart_rate': '最低心率与平均心率',
        'awakenings': '睡眠中断次数',
    },
    'en': {
        // Tabbar
        'summary': 'Summary',
        'details': 'Details',
        'consultant': 'AI Consultant',
        'my': 'My',
        // 日历
        'sun': 'SUN', 'mon': 'MON', 'tue': 'TUE', 'wed': 'WED', 'thu': 'THU', 'fri': 'FRI', 'sat': 'SAT',
        'jan': 'Jan', 'feb': 'Feb', 'mar': 'Mar', 'apr': 'Apr', 'may': 'May', 'jun': 'Jun', 'jul': 'Jul', 'aug': 'Aug', 'sep': 'Sep', 'oct': 'Oct', 'nov': 'Nov', 'dec': 'Dec',
        // 图表
        'illuminance': 'Illuminance',
        'temperature_abbr': 'Temp',
        'humidity_abbr': 'RH',
        'recent_trend': 'Recent Trend',
        'movement': 'Movement',
        'few': 'Few',
        'occasional': 'Occasional',
        'frequent': 'Frequent',
        'times': ' times',
        'osa_mild': 'Mild',
        'osa_moderate': 'Moderate',
        'osa_severe': 'Severe',
        'blood_oxygen_drop': 'Estimated Drop of Blood Oxygen',
        'normal': 'Normal',
        'pay_attention': 'Pay Attention',
        'non_sleep': 'Non-Sleep',
        'personal_baseline': 'Personal Baseline',
        'baseline': 'Baseline',
        'out_of_baseline': 'Above/Below',
        'whole_night_apnea_hypopnea_events': 'Sleep Stages',
        'apnea_hypopnea_events': 'Apnea-Hypopnea Events',
        'average': 'Average',
        'whole_night_sleeping_heart_rate': 'Sleeping Heart Rate',
        'whole_night_hrv_rmssd': 'HRV RMSSD',
        'whole_night_sleeping_respiratory_rate': 'Sleeping Respiratory Rate',
        'whole_night_snore_events': 'Snore Events',
        'to': ' To ',
        'time_in_bed': 'Time in Bed',
        'rem_duration': 'REM Sleep',
        'deep_sleep_duration': 'Deep Sleep',
        'in_bed': 'In Bed ',
        'get_up': 'Get Up ',
        'lowest_hr': 'Lowest HR',
        'lowest_hr_baseline': 'Lowest HR Baseline',
        'average_hr': 'Average HR',
        'once': 'Once',
        'twice': 'Twice',
        'none': 'None',
        // 首页
        'index_title': 'Summary',
        'date': 'Date',
        'from': 'From',
        'today_summary': 'Today',
        'report_summary': 'Summary',
        'connection': 'Connection',
        'disconnected': 'Disconnected',
        'sleep_score': 'Score',
        'sleep_time': 'Total Sleep',
        'deep_sleep': 'Deep Sleep',
        'wake': 'Awake',
        'rem': 'REM',
        'light': 'Light',
        'deep': 'Deep',
        'active': 'Active',
        'leave_bed': 'Away',
        'ahi': 'Apnea-Hypopnea Index (AHI)',
        'lowest_heart_rate': 'Lowest Heart Rate',
        'hrv': 'Heart Rate Variability',
        'bpm': 'bpm',
        'ms': 'ms',
        's': 's',
        'light_ratio': 'Light Exposure',
        'light_ratio_short': 'Light',
        'temperature': 'Temperature',
        'humidity': 'Humidity',
        'nodata': 'Unavailable',
        'nodata_abbr': 'N/A',
        'select_feel': 'How\'d You Feel Waking Up',
        'recorded_feel': 'Wake Up Feeling',
        'osa': 'Obstructive Sleep Apnea',
        'osa_healthy': 'Normal',
        'osa_mild_risk': 'Mild Risk',
        'osa_moderate_risk': 'Moderate Risk',
        'osa_severe_risk': 'Severe Risk',
        'status_normal': 'Normal',
        'status_low': 'Low',
        'status_high': 'High',
        'status_optimal': 'Good',
        'time_min': 'm',
        'time_minute': 'm',
        'time_hr': 'h ',
        'err_login': 'Start using Sleep Monitor Mat today to know your sleep better. Please register and bind your mat.',
        'err_bind': 'Start using Sleep Monitor Mat today to know your sleep better. Please bind your mat.',
        'err_zero': 'No sleep report yet. Start using Sleep Monitor Mat today to know your sleep better.',
        'err_network': 'Network or service error. Please refresh to try again.',
        'err_go_login': 'Register',
        'err_go_bind': 'Bind my mat',
        'err_contact_service': 'Contact Us',
        'err_check_device': 'Check Connection',
        // 报告详情页
        'details_title': 'Details',
        'trends': 'Trends',
        'sleep_parameters': 'Sleep Parameters',
        'sleep_quality': 'Sleep Quality',
        'vs_yesterday': 'vs Yesterday',
        'vs_weekly_avg': 'vs Weekly Avg',
        'sleep_structure': 'Sleep Structure',
        'respiratory_health': 'Respiratory Health',
        'heart_health': 'Heart Health',
        'sleeping_heart_rate': 'Sleeping Heart Rate',
        'sleeping_hrv': 'Sleeping HRV',
        'sleeping_respiratory_rate': 'Sleeping Respiratory Rate',
        'sleep_environment': 'Sleep Environment',
        'data_labs': 'Wuji Medical Data Lab',
        'generated': 'Generated',
        'total_sleep_time': 'Total Sleep Time',
        'sleep_efficiency': 'Sleep Efficiency',
        'sleep_onset_latency': 'Sleep Onset Latency',
        'wake_after_sleep_onset': 'Wake After Sleep Onset',
        'sleep_disturbance': 'Disturbance',
        'rem_sleep': 'REM Sleep',
        'light_sleep': 'Light Sleep',
        'sleep_regularity': 'Sleep Regularity',
        'sleep_wakeup_time': 'Sleep Onset/Wake Up',
        'nrem_ahi': 'NREM AHI',
        'rem_ahi': 'REM AHI',
        'avg_hrv_rmssd': 'Avg HRV RMSSD',
        'avg_heart_rate': 'Avg Heart Rate',
        'avg_respiratory_rate': 'Avg Respiratory Rate',
        'avg_temperature': 'Avg Temperature',
        'avg_humidity': 'Avg Humidity',
        'lowest': 'Lowest',
        'highest': 'Highest',
        'light_exposure_ratio': 'Light Exposure',
        'light_exposure_ratio_short': 'Light Exposure',
        'ideal': 'Ideal',
        'good': 'Good',
        'fair': 'Fair',
        'long': 'Long',
        'too_long': 'Too long',
        'late': 'Late',
        'too_late': 'Too late',
        'short': 'Short',
        'too_short': 'Too short',
        'low': 'Low',
        'too_low': 'Too low',
        'little': 'Short',
        'too_little': 'Too short',
        'irregular': 'Irregular',
        'not_applicable': 'N/A',
        'ahi_comment_no_risk': 'Your AHI shows no risk of Obstructive Sleep Apnea (OSA), and it is recommended to continue to pay attention to this indicator.',
        'ahi_comment_mild_risk': 'Your AHI shows the risk of <b>mild Obstructive Sleep Apnea (OSA)</b>. It is recommended to continue to pay attention to this indicator and go to a professional medical institution for further consultation and examination when necessary.',
        'ahi_comment_moderate_risk': 'Your AHI shows the risk of <b>moderate Obstructive Sleep Apnea (OSA)</b>. It is recommended to continue to pay attention to this indicator and go to a professional medical institution for further consultation and examination when necessary.',
        'ahi_comment_severe_risk': 'Your AHI shows the risk of <b>severe Obstructive Sleep Apnea (OSA)</b>. It is recommended to continue to pay attention to this indicator and go to a professional medical institution for further consultation and examination when necessary.',
        'snore': 'Snore',
        'snore_analysis': 'Snore Analysis',
        'snore_index': 'Snore Index',
        'timing': 'Timing',
        // 数据研究页
        'data_lab_title': 'Data Lab',
        'back': 'Back',
        'about_data_lab': 'About Indicators in Data Lab',
        'about_data_lab_details': 'Indicators for scientific research evaluation purposes are displayed here, aiming to provide researchers with more indicators with medical and scientific research value, and also allowing interested users to explore personal health data more deeply.',
        'ah_events_distribution': 'Apnea-Hypopnea Events',
        'avg_hrv_lfhf': 'Avg HRV LF/HF',
        'light_sleep_ahi': 'N1 & N2 AHI',
        'deep_sleep_ahi': 'N3 AHI',
        'rr_plot': 'HRV Poincaré Plot',
        'rrv_plot': 'RRV Poincaré Plot',
        'avg_rrv': 'Average Respiratory Rate Variability',
        'odi': 'Oxygen Desaturation Index (ODI)',
        'light_sleep_odi': 'N1 & N2 ODI',
        'deep_sleep_odi': 'N3 ODI',
        'rem_odi': 'REM ODI',
        'nrem_odi': 'NREM ODI',
        'oxygen': 'Oxygen',
        'whole_night': 'All night',
        'rem_latency': 'REM Latency',
        // 顾问页
        'consultant_title': 'AI Consultant',
        'beta': 'beta',
        'enter_your_question': 'Enter your question',
        'connecting': 'Connecting...',
        'for_reference_only': 'For reference only. If in doubt, please consult your physician.',
        // 我的设置页
        'account_settings': 'Account Settings',
        'fill_in_personal_information': 'Fill in Personal Information',
        'update_personal_information': 'Update Personal Information',
        'bind_your_device': 'Pair Your Device',
        'device_settings': 'Device Settings',
        'set_up_wifi': 'Set Up WiFi',
        'check_device_status': 'Check Device Status',
        'view_manual': 'User Manual',
        'contact_customer_service': 'Customer Service',
        'forward_to_friends': 'Forward to Friends',
        'please_fill_in': 'Recommended',
        'please_set_up': 'Recommended',
        'long_press_to_follow': 'Long press the QR code to follow Wuji Medical and your sleep reports will be automatically pushed.',
        'binded_to': 'Paired ',
        'not_binded': 'N/A',
        // 数据趋势页
        'trend_title': 'Long-term Trends',
        'month': 'Month',
        'year': 'Year',
        'previous_month': 'Previous Month',
        'next_month': 'Next Month',
        'previous_year': 'Previous Year',
        'next_year': 'Next Year',
        'basic_sleep_parameters': 'Basic Parameters',
        'rem_and_deep_duration': 'REM & Deep Sleep Duration',
        'sleep_stages_duration': 'Durations of Sleep Stages',
        'sleep_stages_ahi': 'AHI of Sleep Stages',
        'lowest_and_average_heart_rate': 'Lowest and Average Heart Rate',
        'awakenings': 'Awakenings',
    }
};

export const tipTexts = {
    "zh-Hans": {
        "quality": {
            title: "睡眠质量",
            text: `睡眠质量得分是基于您每天的睡眠数据在八个关键维度上的评估综合得出的。这包括总睡眠时长、睡眠效率、入睡速度、睡眠中断、深睡眠、快速眼动睡眠、就寝时间和睡眠规律性。这个综合得分旨在帮助您快速的了解您睡眠质量的表现和变化。\n\n虽然我们为每天提供了一个得分，我们强烈建议您更加关注这些维度的周、月甚至更长时间段的趋势，帮助您更全面的了解您的睡眠。` ,
            changelog: [{
                title: '2024/06/13更新',
                text: `为更合理的评估睡眠质量，对睡眠质量得分进行如下优化调整：\n1. 增加睡眠效率、快速眼动睡眠时长在得分中作为参考维度; \n2. 参考用户实际年龄，对不同年龄段的睡眠质量评价更合理。为了更准确了解自己的睡眠，请在【我的】-【更新个人信息】中填写自己的出生年月。`,
            }],
        },
        "tst":{
            title: "总睡眠时长",
            text: `Total Sleep Time（TST），总记录时间内的实际睡眠时间，所有快速眼动期和非快速眼动期睡眠时间的总和。合适的睡眠时长取决于多种因素，尤其是年龄。一般18-65岁成年人建议的睡眠时长为7-9小时，65岁以上建议为7-8小时。请注意，这些建议是一般性的，因为每个人的生活方式和身体状况都有所不同，实际所需睡眠时长也会不同。\n\n建立规律的作息时间、创造适宜的睡眠环境、睡前避免咖啡因和刺激性物质、午睡尽量控制在20-30分钟以内，这些建议可帮助维持健康的睡眠时长。`
        },
        "se": {
            title: "睡眠效率",
            text: `Sleep Efficiency（SE），睡眠期间实际用于睡眠的时间比例，睡眠效率 = 总睡眠时间 / 卧床时间。\n\n睡眠效率为 85% 或更高被认为是正常的，而睡眠效率高于 90% 则被认为是非常好的。较长的入睡速度和睡眠中断是导致睡眠效率低的主要原因。`
        },
        "sol": {
            title: "入睡速度",
            text: `入睡速度 Sleep Onset Latency（SOL）测量入睡用时，也称为睡眠潜伏期 ，是指上床睡觉后到入睡所需的时间。理想情况下，睡眠潜伏期应在 10 到 20 分钟之间。即使没有疲倦的主观感觉，人在需要睡眠时一般也会更快入睡。睡眠潜伏期很短的人有可能有睡眠不足的情况；过长（大于20-30分钟）意味着失眠或发生了其他影响入睡能力的健康问题。\n\n限制睡前屏幕使用时间、制定规律的睡前习惯、限制入睡前的运动、使用冥想或呼吸练习等心理放松技巧，可帮助缩短入睡时间并提高睡眠质量。`
        },
        "waso": {
            title: "睡眠中断",
            text: `睡眠中断 Wake after Sleep Onset（WASO）测量入睡后清醒的总分钟数，一定程度的 WASO 是正常的，但夜间清醒时间过长或碎片化会大大降低睡眠质量，影响次日白天的精力，工作和学习表现。\n\n导致入睡后清醒时间增加的原因有很多，包括焦虑和压力、睡眠障碍、不规律的作息、过度饮食或饮酒，及其他健康问题或疾病。了解这些因素并采取相应的改善措施，如维持规律的作息时间、改善睡眠环境、减少压力等，有助于改善睡眠质量，减少夜间醒来的次数和时间。`
        },
        "disturbance": {
            title: "睡眠中断",
            text: `睡眠中断测量入睡后清醒的总分钟数Wake after Sleep Onset（WASO）和清醒的次数（Awakenings），一定程度的睡眠中断是正常的，但夜间清醒时间过长或清醒次数过多带来的睡眠碎片化会大大降低睡眠质量，影响次日白天的精力，工作和学习表现。\n\n导致入睡后清醒时间和次数增加的原因有很多，包括焦虑和压力、睡眠障碍、不规律的作息、过度饮食或饮酒，及其他健康问题或疾病。了解这些因素并采取相应的改善措施，如维持规律的作息时间、改善睡眠环境、减少压力等，有助于改善睡眠质量，减少夜间醒来的次数和时间。`
        },
        "sri": {
            title: "睡眠规律性",
            text: `睡眠规律性由睡眠规律指数 (SRI) 计算，测量您在最近7天内睡眠开始与结束时间的稳定性。睡眠规律性对睡眠质量有着显著的影响，维持规律的睡眠时间和作息习惯可以帮助调整生物钟，促进更健康的睡眠模式，对睡眠质量有积极的作用，同时对健康、安全和工作表现也很重要。研究发现，不规律的睡眠与多种不良后果有关，包括心脏自主调节、炎症、新陈代谢、心理健康以及表现和认知功能等方面。\n\n调整睡眠规律性的第一步是保证充足的睡眠的前提下选择固定的就寝和起床时间，即使是周末也要保持，虽然可能需要一些时间适应，这是正常的；同时养成良好的睡眠卫生习惯，逐渐适应期望的就寝时间，发展为稳定健康的睡眠模式。`
        },
        "stage": {
            title: "睡眠结构",
            text: `睡眠周期是由非快速眼动睡眠（NREM sleep）和快速眼动睡眠（REM sleep）组成。\n\n快速眼动睡眠是大脑活动最高的睡眠阶段，大部分梦境发生在这期间，除了控制呼吸、心率和眼球运动的肌肉外，身体的大部分肌肉都处于麻痹状态。\n\n非快速眼动睡眠包含浅睡眠和深睡眠。浅睡眠在睡眠阶段中占主导地位，在此阶段心跳和呼吸减缓，体温降低；深睡眠也称慢波睡眠，在此阶段，呼吸和心率降至最低水平，大脑活动减慢，是身体与大脑修复、代谢调节的关键时期。深睡眠一般占总睡眠时间的10-25%，统计表明深睡眠可能随年龄增长每10年减少2%，年轻成年人每晚可能经历80-100分钟的深度睡眠，而60岁以上老年人深度睡眠时间可能少于20分钟。`
        },
        "ahi": {
            title: "呼吸暂停低通气指数 (AHI)",
            text: `阻塞性睡眠呼吸暂停（OSA）是一种以睡眠打鼾伴呼吸暂停和日间思睡为主要临床表现的睡眠呼吸疾病，患病率为2%～4%。该病可引起间歇性低氧、高碳酸血症以及睡眠结构紊乱，并可导致高血压、冠心病、心律失常、脑血管病、认知功能障碍、2型糖尿病等多器官多系统损害。研究表明，未经治疗的重度OSA患者病死率比普通人群高3.8倍。\n
            OSA的诊断通常参考呼吸暂停-低通气指数（AHI）数值。AHI表示睡眠中每小时发生的呼吸暂停和低通气的平均次数，通过呼吸暂停和低通气事件（持续10秒以上）的总数除以睡眠的总时间来计算。成人的AHI数值分为三类，分别对应不同的 OSA 严重程度：\n轻度：5≤AHI<15\n中度：15≤AHI<30\n重度：AHI≥30`,
            note: `*参考：中国医师协会睡眠医学专业委员会. 成人阻塞性睡眠呼吸暂停多学科诊疗指南 [J] . 中华医学杂志,2018,98 (24): 1902-1914. DOI: 10.3760 cma.j.issn.0376-249. 2018.24.003`
        },
        "ahi2345": {
            title: "睡眠阶段与呼吸事件",
            text: `上呼吸道塌陷和阻塞性睡眠呼吸暂停（OSA）的严重程度在睡眠不同阶段会有所变化。其中快速眼动（REM）睡眠占总睡眠时间的20%-25%，与影响上气道功能的生理变化密切相关，在此期间全身肌张力降低，从而增加了上气道塌陷的可能性。\n\n此外，与非快速眼动（NREM）睡眠相比，REM睡眠期间的呼吸事件通常更长、更频繁，并且血氧降低的幅度可能更大。实际上，有10%-37%的被诊断为OSA的患者仅在REM睡眠中出现呼吸暂停和/或低通气事件。因此，了解不同睡眠阶段中的AHI情况对于OSA的评估和治疗提供了一定的帮助。`
        },
        "hr": {
            title: "睡眠最低心率",
            text: `睡眠时，人的心率通常会低于日间静息心率，平均可能低至每分钟 40 至 50 次。大多数成年人的静息心率在每分钟 40 至 100 次之间，进入深度睡眠时的心率可能比静息心率低约 20% 至 30%。\n
        跟踪睡眠状态下心率的长期趋势和是否超出个人基线区间，可以了解身体适应性、整体健康表现的异常与波动，或其他需引起注意的健康状况。稳定的保持在个人基线范围内是最好的，虽然更低的睡眠最低心率通常意味着更好的心血管健康水平和身体素质，但低于基线区间太多也可能反映异常的健康状况；高出基线区间的情况更加需要引起注意，可以反映低质量的睡眠、疾病，或睡前不适合的饮食及锻炼习惯。`,
            note: `*睡眠最低心率个人基线区间范围取自过去14天积累的数据。`
        },
        "hrv": {
            title: "睡眠心率变异性",
            text: `心率变异性（HRV）测量心跳间隔波动，反映心脏自主神经系统功能。
            HRV RMSSD是心跳间隔波动的主要指标，通常高值代表更好的心血管功能和抗压能力，低值则与抑郁、焦虑及心血管疾病风险增加相关。事实上，HRV的高低是相对的，取决于许多个人因素（如年龄、性别、昼夜节律、压力反应模式等），没有适用于所有人的标准。因此更有意义的是关注HRV是否保持在个人历史基线范围中，持续超出或低于个人的历史基线都可能暗示身体对压力的适应性或健康状况发生了变化。其中低于历史基线的情况更加需要引起关注，但短期内高出历史基线过多也可能反映异常的健康状况。\n\n• HRV RMSSD值在个人基线范围内的上升趋势可能表明个体的自我调节能力在增强，可能因为合理的饮食、锻炼或优质的睡眠，身体能够更好地适应压力或恢复到了更健康的状态。\n\n• HRV RMSSD值在个人基线范围内的下降趋势可能表明个体的自我调节能力受到了某些因素的影响，如压力过大、情绪波动、睡眠质量差等；吸烟、饮酒或某些药物也可能会造成HRV降低。`,
            note: `*HRV个人基线区间范围取自过去2个月内积累的数据。`
        },
        "hrvlh": {
            title: "HRV LF/HF",
            text: `自律神经系统调节心率、血压、呼吸和消化，分为交感神经（应激）和副交感神经（放松）系统。HRV LF/HF比值可以用来衡量交感神经和副交感神经活动比例，其中：\n\n• LF/HF比值的下降趋势可能意味着副交感神经系统正在占主导地位，身体可能正在从压力中恢复，或者处于一种更加放松的状态。\n\n• LF/HF比值的上升趋势可能意味着交感神经系统正在占主导地位，身体可能正在应对压力，或处于一种更加活跃或紧张的状态。`,
        },
        "rr": {
            title: "睡眠呼吸频率",
            text: `健康成年人呼吸频率（每分钟呼吸的次数）一般为 12-20 次/分钟，睡眠时的呼吸频率通常会低于日间呼吸频率。\n
        与心率一样，呼吸频率是一种生命体征指标，可以洞察一个人的整体健康状况。健康成年人的呼吸频率在夜间变化不大，因此与个人基线相比突然的变化可能暗示身体受到压力、睡眠质量下降或出现其他需引起注意的健康状况，对于发现异常情况十分重要。同时，比起低于基线区间的情况，高出基线区间的情况更加需要引起关注，如在发烧或焦虑等健康异常状况下，呼吸频率可能会升高，但短期内低于基线区间太多也可能反映异常的健康状况。`,
            note: `*睡眠呼吸频率个人基线区间范围取自过去14天积累的数据。`
        },
        "env": {
            title: "睡眠环境",
            text: `合适的睡眠环境对于获得良好的睡眠质量至关重要。睡眠环境监测您睡眠期间环境中的温度、湿度、光照指标，帮助您了解您的环境是否处于最适宜睡眠的条件。\n
            研究表明，适宜的睡眠温度范围为 15-23°C，湿度范围为 40-60%；尽可能在光照强度小于 1lx 的黑暗环境中入睡，开灯睡觉会干扰睡眠周期，导致睡眠更加碎片化。`
        },
        "satisfaction": {
            title: "睡眠感受",
            text: `记录每日的主观睡眠感受，是了解整体睡眠情况有价值的参考因素之一。每日睡眠报告生成后的当天内（24点前），可以根据醒后或白天的主观感觉，输入或修改睡眠感受。`
        },
        "deep": {
            title: "深睡眠",
            text: `睡眠周期是由非快速眼动睡眠（NREM sleep）和快速眼动睡眠（REM sleep）组成。\n\n非快速眼动睡眠包含浅睡眠和深睡眠。浅睡眠在睡眠阶段中占主导地位，在此阶段心跳和呼吸减缓，体温降低；深睡眠也称慢波睡眠，在此阶段，呼吸和心率降至最低水平，大脑活动减慢，是身体与大脑修复、代谢调节的关键时期。深睡眠一般占总睡眠时间的10-25%，统计表明深睡眠可能随年龄增长每10年减少2%，年轻成年人每晚可能经历 80-100 分钟的深睡眠，而 60 岁以上老年人深度睡眠时间可能少于 20 分钟。
            `
        },
        "rem": {
            title: "快速眼动睡眠",
            text: `睡眠周期是由非快速眼动睡眠（NREM sleep）和快速眼动睡眠（REM sleep）组成。\n\n快速眼动睡眠是大脑活动最高的睡眠阶段，大部分梦境发生在这期间。除了控制呼吸、心率和眼球运动的肌肉外，身体的大部分肌肉都处于麻痹状态。快速眼动睡眠一般占总睡眠时间的20-25%，统计表明快速眼动睡眠可能随年龄增长轻微减少，年轻成年人每晚可能经历90-120分钟的快速眼动睡眠，而60岁以上老年人可能少于40分钟。
            `
        },
        "spo2": {
            title: "整晚血氧波动",
            text: `血氧水平是血液中所携带的氧气含量，表明氧气在全身分配是否充足，因此可以反应总体健康状况。\n\n睡眠期间的血氧水平通常保持相对稳定，较个人基线相比小幅度波动是正常的（绿色区域或少量的黄色区域）；但如果睡眠中发生呼吸暂停、低通气事件或肺部问题，血氧水平可能会因为进入身体的氧气减少而下降，较个人基线产生较大幅度的波动（大量的黄色区域）。如果图表上经常出现较大幅度的波动并且伴有阻塞性睡眠呼吸暂停风险提示，需要引起注意。`
        },
        "odi": {
            title: "氧减指数（ODI）",
            text: `氧减指数（ODI）‌是指在整夜睡眠期间，每小时平均发生的氧减次数。这个指标通过监测您的血氧饱和度的变化，来评估您是否有睡眠呼吸暂停的风险。如果ODI持续较高(>5)，并且伴有阻塞性睡眠呼吸暂停风险提示，需要引起注意。`
        },
        "rem_latency": {
            title: "快速眼动睡眠潜伏期",
            text: `指从入睡开始到进入快速眼动睡眠（REM）阶段所需的时间。快速眼动睡眠是睡眠周期中的一个关键阶段，与做梦、记忆巩固和情绪调节相关。\n\n该指标通常在60～120分钟之间，较短可能是睡眠不足、某些睡眠障碍（如嗜睡症）或白天过度嗜睡的表现；较长则可能表明在睡眠阶段转换上存在困难，这可能与压力、睡眠中断或其他潜在的睡眠问题有关。平衡的快速眼动睡眠潜伏期表明睡眠周期运转良好，对整体的休息质量和认知功能非常重要。如果该指标持续偏离正常范围，需要引起注意。`
        },
        "snore_index": {
            title: "鼾声指数",
            text: `鼾声指数（Snore Index, SI）是指在睡眠过程中每小时发生的打鼾事件的平均次数，用于评估打鼾的严重程度。\n\n长期打鼾的人中约有1/3患有OSA（AHI>5)，打鼾但AHI<5的情况通常是单纯打鼾。对于单纯打鼾来说，经常打鼾也可能带来健康风险，由于打鼾引起的颈动脉及其周围组织长期震动，经常打鼾人的颈动脉硬化风险比健康人群高10倍。生活方式的改变，例如减肥、睡前避免饮酒、或侧睡，可以帮助改善打鼾的情况。`
        },
        "timing": {
            title: "就寝时间",
            text: `就寝时间测量准备开始睡眠的时间。研究表明，规律早睡早起的睡眠模式与更好的心理健康和较低的多种健康问题风险相关（如心血管疾病、糖尿病、抑郁等），而晚睡型个体则可能面临更高的健康风险。\n\n调整合理的就寝时间，可以通过避免晚间咖啡因和屏幕蓝光、确保良好的睡眠环境、白天多晒太阳、适量运动，睡前放松身心等方式，逐步调整作息。`
        },
        "rrv": {
            title: "呼吸率变异性",
            text: `正常呼吸表现出相对稳定的呼吸频率和潮气量，这两者共同构成正常的呼吸节律。呼吸节律的变化被称为呼吸率变异性（Respiratory Rate Variability, RRV）。呼吸率的变化是一个重要的指标，通常与健康状况相关。`
        }
    },
    "en": {
        "quality": {
            title: "Sleep Quality Score",
            text: `The sleep quality score is derived from a comprehensive assessment of your daily sleep data across eight key dimensions. These include total sleep duration, sleep efficiency, sleep onset latency, sleep interruptions, deep sleep, REM sleep, timing, and sleep regularity. This composite score is designed to help you quickly understand the quality of your sleep and its variations. \n\nWhile a daily score is provided, we strongly recommend focusing on the trends over weekly, monthly, or even longer periods to gain a more comprehensive understanding of your sleep.` ,
        },
        "tst":{
            title: "Total Sleep Time",
            text: `Total Sleep Time (TST) refers to the actual amount of sleep within the total recording period, encompassing all REM and non-REM sleep stages. The appropriate duration of sleep depends on various factors, especially age. Generally, it is recommended that adults aged 18-65 get 7-9 hours of sleep, while those aged 65 and older are advised to get 7-8 hours. Please note that these recommendations are general guidelines, as individual lifestyle and health conditions can vary, and the actual amount of sleep needed may differ.\n\nEstablishing a regular sleep schedule, creating a conducive sleep environment, avoiding caffeine and stimulants before bedtime, and limiting naps to 20-30 minutes can help maintain a healthy sleep duration.`
        },
        "se": {
            title: "Sleep Efficiency",
            text: `Sleep Efficiency (SE) is the proportion of time spent actually sleeping during the total time spent in bed. Sleep efficiency is calculated as: Sleep Efficiency = Total Sleep Time / Time in Bed. \n\nA sleep efficiency of 85% or higher is considered normal, while a sleep efficiency above 90% is regarded as excellent. Prolonged sleep onset latency and sleep interruptions are the primary factors that contribute to low sleep efficiency.`
        },
        "sol": {
            title: "Sleep Onset Latency",
            text: `Sleep Onset Latency (SOL) measures the time it takes to fall asleep. It refers to the time from getting into bed to the onset of sleep. Ideally, sleep latency should be between 10 and 20 minutes. Even without a subjective feeling of tiredness, people generally fall asleep more quickly when they need sleep. A very short sleep latency might indicate sleep deprivation, while a prolonged latency (greater than 20-30 minutes) could signify insomnia or other health issues affecting the ability to fall asleep. \n\nLimiting screen time before bed, establishing a regular bedtime routine, avoiding exercise before sleep, and using psychological relaxation techniques such as meditation or breathing exercises can help reduce sleep onset latency and improve sleep quality.`
        },
        "waso": {
            title: "Wake After Sleep Onset",
            text: `Wake After Sleep Onset (WASO) measures the total number of minutes awake after initially falling asleep. Some degree of WASO is normal, but prolonged or fragmented wakefulness during the night can significantly reduce sleep quality, impacting daytime energy, work, and learning performance. \n\nThere are many factors that can increase wakefulness after sleep onset, including anxiety and stress, sleep disorders, irregular sleep schedules, overeating or excessive alcohol consumption, and other health issues or medical conditions. Understanding these factors and taking appropriate steps to address them, such as maintaining a regular sleep schedule, improving the sleep environment, and reducing stress, can help improve sleep quality by reducing the frequency and duration of nighttime awakenings.`
        },
        "disturbance": {
            title: "Sleep Disturbance",
            text: `Sleep disturbance measures the duration and frequency of nitghttime awakenings during sleep, known as Wake After Sleep Onset (WASO) and Awakenings. WASO refers to the total number of minutes awake after initially falling asleep. Awakenings refers to the total number of times awake after initially falling asleep. Some degree of WASO and Awakenings is normal, but prolonged or fragmented wakefulness during the night can significantly reduce sleep quality, impacting daytime energy, work, and learning performance.\n\nThere are many factors that can increase wakefulness after sleep onset, including anxiety and stress, sleep disorders, irregular sleep schedules, overeating or excessive alcohol consumption, and other health issues or medical conditions. Understanding these factors and taking appropriate steps to address them, such as maintaining a regular sleep schedule, improving the sleep environment, and reducing stress, can help improve sleep quality by reducing the frequency and duration of nighttime awakenings.`
        },
        "sri": {
            title: "Sleep Regularity",
            text: `Sleep regularity is calculated using the Sleep Regularity Index (SRI), which measures the consistency of your sleep onset and wake-up times over the past 7 days. Sleep regularity has a significant impact on sleep quality; maintaining a consistent sleep schedule and routine helps regulate your biological clock, promotes healthier sleep patterns, and positively influences sleep quality. It is also crucial for overall health, safety, and work performance. Research has found that irregular sleep patterns are associated with various negative outcomes, including heart rate variability, inflammation, metabolism, mental health, and cognitive performance.\n\nThe first step in improving sleep regularity is to establish consistent bedtimes and wake-up times while ensuring adequate sleep, even on weekends. Although it may take some time to adjust, this is normal. Additionally, practicing good sleep hygiene and gradually aligning your bedtime with your desired schedule can help you develop a stable and healthy sleep pattern.`
        },
        "stage": {
            title: "Sleep Structure",
            text: `Our sleep is composed of multiple cycles of non-rapid eye movement (NREM) sleep and rapid eye movement (REM) sleep. \n\nREM sleep is the stage of sleep where brain activity is at its peak, and most dreaming occurs. During this stage, most of the body’s muscles are paralyzed, except for those controlling breathing, heart rate, and eye movements. \n\nNREM sleep includes both light sleep and deep sleep. Light sleep is the dominant phase in the sleep cycle, during which the heart rate and breathing slow down, and body temperature decreases. Deep sleep, also known as slow-wave sleep, is the phase where breathing and heart rate reach their lowest levels, brain activity slows down, and the body and brain undergo essential repair and metabolic regulation.Deep sleep generally constitutes 10-25% of total sleep time. Statistics indicate that deep sleep may decrease by about 2% per decade with age. Young adults may experience 80-100 minutes of deep sleep per night, while older adults over 60 years of age may get less than 20 minutes of deep sleep.`
        },
        "ahi": {
            title: "Apnea-Hypopnea Index (AHI)",
            text: `Obstructive Sleep Apnea (OSA) is a sleep-related breathing disorder characterized by snoring accompanied by episodes of apnea and excessive daytime sleepiness. The prevalence of OSA is estimated to be 2% to 4%. This condition can cause intermittent hypoxia, hypercapnia, and disruption of sleep architecture, leading to multiple organ and system damage, including hypertension, coronary artery disease, arrhythmias, cerebrovascular disease, cognitive impairment, and type 2 diabetes. Studies have shown that the mortality rate of untreated patients with severe OSA is 3.8 times higher than that of the general population.\n
            The diagnosis of OSA typically relies on the Apnea-Hypopnea Index (AHI). AHI represents the average number of apnea and hypopnea episodes per hour of sleep, calculated by dividing the total number of apnea and hypopnea events (each lasting more than 10 seconds) by the total sleep time. The AHI values for adults are categorized into three levels, corresponding to different severities of OSA:\nMild: 5≤AHI<15\nModerate: 15≤AHI<30\nSevere: AHI≥30`
        },
        "ahi2345": {
            title: "Apnea-Hypopnea Events and Sleep Stages",
            text: `The severity of upper airway collapse and Obstructive Sleep Apnea (OSA) varies during different sleep stages. Rapid Eye Movement (REM) sleep, which accounts for 20%-25% of total sleep time, is closely associated with physiological changes that affect upper airway function. During REM sleep, there is a reduction in muscle tone throughout the body, which increases the likelihood of upper airway collapse. \n\nAdditionally, apnea-hypopnea events during REM sleep are typically longer and more frequent compared to those during Non-Rapid Eye Movement (NREM) sleep, and the reductions in blood oxygen levels may be more significant. In fact, 10%-37% of patients diagnosed with OSA exhibit apneas and/or hypopneas exclusively during REM sleep. Therefore, understanding the Apnea-Hypopnea Index (AHI) during different sleep stages is crucial for the assessment and treatment of OSA.`
        },
        "hr": {
            title: "Lowest Sleeping Heart Rate",
            text: `During sleep, a person's heart rate typically falls below their daytime resting heart rate, averaging as low as 40 to 50 beats per minute. Most adults have a resting heart rate between 40 to 100 beats per minute, and the heart rate during deep sleep may be about 20% to 30% lower than the resting heart rate. \n\nTracking long-term trends in heart rate during sleep and any deviations from personal baseline ranges can provide insights into the body's adaptability, overall health anomalies and fluctuations, or other health concerns that may require attention. Maintaining a stable heart rate within personal baseline ranges is ideal, although a lower minimum heart rate during sleep usually indicates better cardiovascular health and physical fitness. However, heart rates significantly below the baseline may indicate abnormal health conditions; rates above the baseline are particularly concerning, as they may reflect poor sleep quality, illness, or unsuitable dietary and exercise habits before sleep.`,
            note: `* The personal baseline of lowest sleeping heart rate is based on data gathered from the past 14 days.`
        },
        "hrv": {
            title: "Heart Rate Variability",
            text: `Heart Rate Variability (HRV) measures the fluctuation between heartbeats, reflecting the function of the autonomic nervous system. \nRMSSD is a key indicator of HRV, where higher values typically indicate better cardiovascular function and stress resilience, while lower values may be associated with increased risks of depression, anxiety, and cardiovascular diseases. In fact, HRV levels are relative and depend on many individual factors, such as age, gender, circadian rhythms, and stress response patterns, and there are no universal standards applicable to everyone. It is more meaningful to monitor whether HRV remains within an individual's historical baseline range. Consistently exceeding or falling below one's historical baseline can suggest changes in the body's adaptability to stress or overall health. A decrease below the historical baseline warrants particular attention, but an excessively high RMSSD in the short term can also indicate abnormal health conditions. \n\n• An upward trend in RMSSD within the personal baseline range may indicate an enhancement in an individual's self-regulation abilities, possibly due to a balanced diet, exercise, or quality sleep, allowing the body to better adapt to stress or return to a healthier state. \n\n• Conversely, a downward trend within the baseline range could suggest that the individual's self-regulation capacity is being impacted by factors such as excessive stress, emotional fluctuations, poor sleep quality; smoking, drinking, or certain medications could also cause a reduction in HRV.`,
            note: `* The personal baseline of HRV is based on data gathered from the past two months.`
        },
        "hrvlh": {
            title: "HRV LF/HF",
            text: `The autonomic nervous system regulates heart rate, blood pressure, breathing, and digestion, and is divided into the sympathetic (stress response) and parasympathetic (relaxation response) systems. The LF/HF ratio in Heart Rate Variability (HRV) can be used to measure the balance between sympathetic and parasympathetic nervous activities:\n\n• A declining trend in the LF/HF ratio may indicate that the parasympathetic nervous system is becoming more dominant, suggesting that the body might be recovering from stress or entering a more relaxed state.\n\n• An increasing trend in the LF/HF ratio may suggest that the sympathetic nervous system is becoming more dominant, indicating that the body might be responding to stress, or is in a more active or tense state.`,
        },
        "rr": {
            title: "Sleeping Respiratory Rate",
            text: `Healthy adults typically have a respiratory rate of 12-20 breaths per minute, and this rate usually decreases during sleep compared to daytime breathing rates.\n\nLike heart rate, respiratory rate is a vital sign that can provide insights into an individual's overall health. In healthy adults, the respiratory rate doesn't vary significantly overnight, so sudden changes compared to personal baselines might indicate the body is under stress, experiencing a decline in sleep quality, or other health issues that require attention. Notably, rates exceeding the baseline range often warrant more concern. For instance, an increased respiratory rate can occur in conditions like fever or anxiety. However, a significant drop below the baseline in the short term might also reflect abnormal health conditions.`,
            note: `* The personal baseline of sleeping respiratory rate is based on data gathered from the past 14 days.`
        },
        "env": {
            title: "Sleep Environment",
            text: `An appropriate sleep environment is crucial for achieving good sleep quality. Monitoring the temperature, humidity, and light levels in your environment during sleep can help you determine if your surroundings are optimally set for sleep.\n\nResearch indicates that the ideal temperature range for sleep is 15-23°C, and the ideal humidity range is 40-60%. It’s best to sleep in as dark an environment as possible, with light intensity less than 1 Lx. Sleeping with the lights on can disrupt your sleep cycle, leading to more fragmented sleep.`
        },
        "satisfaction": {
            title: "Sleep Perception",
            text: `Keeping a daily record of subjective sleep perception is a valuable reference for understanding overall sleep patterns. You can enter or modify your sleep perceptions based on how you feel upon waking or during the day, within the same day that the sleep report is generated (before midnight). This practice can help you track and manage your sleep quality over time, allowing you to make adjustments as needed based on your personal observations.`
        },
        "deep": {
            title: "Deep Sleep",
            text: `The sleep cycle consists of non-rapid eye movement (NREM) sleep and rapid eye movement (REM) sleep.\n\nNREM sleep includes both light and deep sleep stages. Light sleep predominates in the sleep cycle; during this phase, heart rate and breathing slow down, and body temperature decreases. Deep sleep, also known as slow-wave sleep, is a phase where both breathing and heart rate drop to their lowest levels, and brain activity slows down. This stage is crucial for physical and brain repair and metabolic regulation. Deep sleep generally accounts for 10-25% of total sleep time. Statistics indicate that deep sleep may decrease by about 2% per decade with age. Young adults might experience 80-100 minutes of deep sleep per night, while individuals over 60 years of age might have less than 20 minutes of deep sleep.`
        },
        "rem": {
            title: "Rapid Eye Movement (REM) Sleep",
            text: `The sleep cycle consists of non-rapid eye movement (NREM) sleep and rapid eye movement (REM) sleep.\n\nREM sleep is the stage of sleep with the highest brain activity, and it is during this phase that most dreaming occurs. During REM sleep, most of the body's muscles are paralyzed, except for those that control breathing, heart rate, and eye movements. REM sleep typically accounts for 20-25% of the total sleep time. Statistics suggest that the amount of REM sleep may slightly decrease with age. Young adults might experience 90-120 minutes of REM sleep each night, while individuals over the age of 60 might have less than 40 minutes.`
        },
        "spo2": {
            title: "Estimated Drop of Blood Oxygen",
            text: `Blood oxygen saturation represents the amount of oxygen carried in the blood, indicating whether oxygen distribution throughout the body is sufficient, and thus it can reflect overall health status. Blood oxygen drop ratio approximates the decline percentage in your blood oxygen saturation.\n\nDuring sleep, blood oxygen saturation typically remains relatively stable, and minor fluctuations compared to personal baselines are normal (within green areas or minimal yellow areas). However, if apnea-hypopnea events, or lung issues occur during sleep, oxygen saturation might decrease due to reduced oxygen intake, resulting in more significant fluctuations compared to personal baselines (extensive yellow areas). If the chart frequently shows significant fluctuations accompanied by risks of obstructive sleep apnea, it's important to pay attention and possibly seek medical advice.`
        },
        "odi": {
            title: "Oxygen Desaturation Index (ODI)",
            text: `The Oxygen Desaturation Index (ODI) measures how often your blood oxygen level drops by a certain percentage during sleep. It's an important indicator of how well your body is getting oxygen while you rest. In simple terms, ODI helps track the number of times per hour your blood oxygen levels decrease, which can be a sign of breathing problems like sleep apnea. Consistently higher ODI (above 5), accompanied by risks of obstructive sleep apnea, may suggest further investigation or treatment.`
        },
        "rem_latency": {
            title: "REM Latency",
            text: `REM Latency refers to the amount of time it takes for you to enter the REM (Rapid Eye Movement) stage of sleep after initially falling asleep. REM sleep is a crucial phase of your sleep cycle, associated with vivid dreaming, memory consolidation, and emotional regulation.\n\nHealthy REM Latency typically ranges between 60-120 minutes. Shorter REM Latency can be a sign of sleep deprivation, certain sleep disorders like narcolepsy, or excessive daytime sleepiness. Longer REM Latency may indicate difficulties in transitioning through the sleep stages, which could be due to stress, sleep fragmentation, or other underlying sleep issues.\n\nA balanced REM Latency suggests a well-functioning sleep cycle, which is important for overall restfulness and cognitive function. If your REM Latency is consistently outside the normal range, it might be helpful to review your sleep habits or consult a healthcare professional.`
        },
        "snore_index": {
            title: "Snore Index",
            text: `The Snore Index (SI) refers to the average number of snoring events per hour during sleep and is used to assess the severity of snoring. \n\nAbout one-third of people who snore chronically have Obstructive Sleep Apnea (OSA) with an Apnea-Hypopnea Index (AHI) above 5. Snorers with an AHI below 5 are usually classified as simple snorers. Frequent snoring can pose health risks. Due to the prolonged vibration of the carotid artery and surrounding tissues caused by snoring, frequent snorers have a tenfold higher risk of carotid atherosclerosis compared to healthy individuals. Lifestyle changes, such as weight loss, avoiding alcohol before bed, or sleeping on one’s side, can help reduce snoring.`
        },
        "timing": {
            title: "Timing",
            text: `Timing refers to the time of starting to prepare for sleep. Research indicates that maintaining consistent sleep timing, with early bedtimes and wake-ups, is associated with better mental health and a lower risk of various health issues, such as cardiovascular disease, diabetes, and depression. In contrast, habitual late sleepers may face higher health risks.\n\nAdjusting to a healthier sleep timing can be achieved by avoiding evening caffeine and blue light, creating a comfortable sleep environment, getting daytime sunlight, engaging in regular exercise, and relaxing before bed.`
        },
        "rrv": {
            title: "Respiratory Rate Variability",
            text: `Normal breathing exhibits a relatively constant rate and tidal volume that together constitute normal respiratory rhythm. Variations within this respiratory rhythm are labeled as Respiratory Rate Variability (RRV). Changes in respiratory rate (RR) is an important indicator which often precedes various health issues.`
        }
    }
};