<template>

  <div class="device-panel" ref="devicePanelRef">
    <div class="toolbar">
      <WaveformToolbar
          :selstart="timerange.selstart"
          :selend="timerange.selend"
          :device="timerange.device"
          :taskfilter="props.mode"
          :date = "timerange.date"
          :server="props.server"
          @realtime="emitCheckboxChange"
          :channels="visible_channels"/>
    </div>
    <div style="position:relative">

      <div v-for="(panel, panelIndex) in _panels" :key="`panel-${panelIndex}`">
        <div class="checkbox-group"
             v-if="Object.keys(visible_channels).length===0 || visible_channels[panel.name]===1">
          通道:{{ panel.name }}
          <div v-for="(seriesKey, index) in Object.keys(panel.series)" :key="`series-${panelIndex}-${index}`"
               style="display: flex; align-items: center; margin-bottom: 5px;">
            <input type="checkbox" v-model="panel.selected" :value="panel.series[seriesKey].key"
                   :id="`channel-${panelIndex}-${index}`"  @change="onCheckboxChange(panel.series[seriesKey].selected)">

            <label :for="`channel-${panelIndex}-${index}`"
                   :style="{ color: channelColors[index % channelColors.length], marginLeft: '1px' }">{{
                panel.series[seriesKey].name
              }}</label>

            <div v-for="(listkey, listindex) in Object.keys(panel.series[seriesKey].list)"
                 :key="`list-${panelIndex}-${index}-${listindex}`" style="display: flex; align-items: center;">
              <input type="checkbox" v-model="panel.series[seriesKey].selected"
                     :value="panel.series[seriesKey].list[listkey].key"
                     :id="`channel-${panelIndex}-${index}-${listindex}`">
              <label :style="{ color: channelColors[listindex % channelColors.length], marginLeft: '1px' }">{{
                  panel.series[seriesKey].list[listkey].name
                }}</label>
            </div>

          </div>
        </div>

        <div v-if="Object.keys(visible_channels).length===0 || visible_channels[panel.name]===1">
          <div class="heatmap" v-for="(seriesKey,index) in panel.selected"
               :key="`detail-${panelIndex}-${seriesKey}`"
               @mousedown="handleMouseDown($event)"
               @mouseup="(event) => handleMouseUp(event, panel.series[seriesKey])"
               @mousemove="(event) => handleMouseMove(event,panel.series[seriesKey])"
               @mouseleave="handleMouseLeave($event)"
               @contextmenu.prevent="onContainerRightClick($event,panel.series[seriesKey])"
               @click="handleMouseClick($event)">
            <TimeRangeCanvas :selstart="timerange.selstart"
                             :selend="timerange.selend"
                             :last_modified="panel.series[seriesKey].last_modified"
                             :last_realtime="panel.series[seriesKey].last_realtime"
                             :last_resized="panel.series[seriesKey].last_resized"
                             :serieskey= "seriesKey"
                             :seriesobj="panel.series[seriesKey]"
                             :color="channelColors[index % channelColors.length]"/>
            <TagCanvas v-for="(listkey, listindex) in panel.series[seriesKey].selected"
                       :color="channelColors[listindex % channelColors.length]"
                       :listobj="panel.series[seriesKey].list[listkey]"
                       :last_modified="panel.series[seriesKey].list[listkey].last_modified"
                        :last_realtime="panel.series[seriesKey].list[listkey].last_realtime"
                        :last_resized="panel.series[seriesKey].list[listkey].last_resized"
                       :key="`list-${panelIndex}-${index}-${listindex}`"
                       :selstart="timerange.selstart"
                       :selend="timerange.selend" />
          </div>
        </div>
      </div>

      <div v-if="selectinfo.move || selectinfo.over" :style="selectionStyle()"/>
      <div :style="hintStyle()">
        <TreeViewObj v-if="hintinfo.move" :data="hintinfo.info" :expand="true"/>
      </div>
      <div>
        <el-dropdown @command="onCommand" trigger="manual" ref="dropdown"
                     :style="{ position: 'absolute', top: menuPosition.y + 'px', left: menuPosition.x + 'px' }">
                    <span class="el-dropdown-link">

                    </span>
          <template #dropdown>
            <el-dropdown-menu>
              <el-dropdown-item command="rollback">区间回滚</el-dropdown-item>
              <el-dropdown-item command="reset"> 区间重置</el-dropdown-item>
              <el-dropdown-item command="moveleft"> 左移</el-dropdown-item>
              <el-dropdown-item command="moveright"> 右移</el-dropdown-item>
              <el-dropdown-item command="anotate_onbed" v-if="!anotating">标注:在床</el-dropdown-item>
              <el-dropdown-item command="anotate_move" v-if="!anotating">标注:体动</el-dropdown-item>
              <el-dropdown-item command="anotate_partner" v-if="!anotating">标注:同睡人</el-dropdown-item>
              <el-dropdown-item command="anotate_quality" v-if="!anotating">标注:信号质量</el-dropdown-item>
              <el-dropdown-item command="anotate_pos" v-if="!anotating">标注:睡姿</el-dropdown-item>
              <el-dropdown-item command="" v-if="anotating">退出标注</el-dropdown-item>
            </el-dropdown-menu>
          </template>
        </el-dropdown>
      </div>

    </div>

  </div>
  <AnotateDialog v-model="anotateVisible"
                 :anotateVisible="anotateVisible"
                 :data="anotateData"
                 @submit="handleAnotateSubmit"
                 @delete="handleAnotateDelete"
                 :server="props.server"
                 @update:anotateVisible="handleAnotateClose"/>
</template>

<script setup>

import {defineProps, onBeforeUnmount, onMounted, ref, toRaw, watch} from 'vue';
import TimeRangeCanvas from './TimeRangeCanvas.vue';
import TagCanvas from './TagCanvas.vue';
import {ElDropdown, ElDropdownItem, ElDropdownMenu} from 'element-plus';
import AnotateDialog from '@/components/AnotateDialog.vue';
import TreeViewObj from '@/common/TreeViewObj.vue';
import WaveformToolbar from '@/components/WaveformToolbar.vue';
import {formatDateTime_full, formatTime} from '@/plugins/timetool.js';
import {checkAndClearOldCache, fetchAndCacheFile} from '@/plugins/filedb';
import {add_event_list, add_time_buffer, create_empty_event_list, create_empty_time_buffer} from '@/plugins/timebuffer';
import {eventBus, sendMessage} from '@/plugins/websocket';
import {loadLocalStorage, saveLocalStorage} from '@/plugins/store';

const channelColors = ['lime', 'yellow', 'red', 'pink', 'aqua', 'white', 'orange', 'white', 'brown'];
const devicePanelRef = ref(null);
const menuPosition = ref({ x: 0, y: 0 });
const visible_channels = ref({});
const anotating = ref(false)
const anotatetask = ref(null)
const anotateVisible = ref(false)
const anotateData = ref({})
const props = defineProps({
  device: String,
  date: String,
  globalstart: Number,
  globalend: Number,
  mode: String,
  server: {
    type: String,
    default: '数据服务器'
  },
  hf: {
    type: Number,
    default: 0
  },
  visible: {
    type: Array,
    default: null
  }
});

const timerange = ref({
  start: 0,
  end: 0,
  device: '',
  date: null,
  mode: null,
  hf: 0,
  recordedstart: 0,
  recordedend: 0,
  selstart: 0,
  selend: 0
})
const selectinfo = ref({
  startx: 0,
  starty: 0,
  button: 0,
  endx: 0,
  endy: 0,
  move: 0,
  holdstart: 0,
  holdend: 0,
  holdtime: 0,
  absy: 0,
  maxy: 0,
  over: 0
})
const hintinfo = ref({
  move: 0, info: {}
})

const _panels = ref({})

const dropdown = ref(null);

function onContainerRightClick (event) {
  // 动态设置下拉菜单位置
  event.preventDefault();
  const x = event.offsetX;
  const y = event.currentTarget.offsetTop + event.offsetY
  menuPosition.value = { x, y };

  // dropdownVisible.value = true;
  dropdown.value.handleClose();
  dropdown.value.handleOpen();
}

const annotats = ['anotate_onbed', 'anotate_move', 'anotate_partner', 'anotate_quality', 'anotate_pos'];
function onCommand (command) {
  console.log('选中的操作：', command);
  if (command === 'rollback') {
    reroletime()
  } else if (command === 'reset') {
    resettime()
  } else if (command === 'moveleft') {
    movetime(0)
  } else if (command === 'moveright') {
    movetime(1)
  } else if (command === 'anotate_onbed') {
    anotating.value = true
    anotatetask.value = 'onbed'
  } else if (command === 'anotate_pos') {
    anotating.value = true
    anotatetask.value = 'pos'
  } else if (command === 'anotate_move') {
    anotating.value = true
    anotatetask.value = 'move'
  } else if (command === 'anotate_partner') {
    anotating.value = true
    anotatetask.value = 'partner'
  } else if (command === 'anotate_quality') {
    anotating.value = true
    anotatetask.value = 'quality'
  } else {
    anotating.value = false
  }
}

function selectionStyle () {
  if (selectinfo.value.move === 1) {
    return {
      position: 'absolute',
      top: '0px',
      height: '100%',
      backgroundColor: 'rgba(255, 255, 255, 0.5)',
      left: `${selectinfo.value.startx}px`,
      width: `${selectinfo.value.endx - selectinfo.value.startx + 1}px`,
      font: '0.5em',
      // width: '10px',
      pointerEvents: 'none'
    }
  }
  if (selectinfo.value.over === 1) {
    return {
      position: 'absolute',
      top: '0px',
      height: '100%',
      backgroundColor: 'rgba(255, 255, 255, 0.5)',
      left: `${selectinfo.value.endx}px`,
      width: '2px',
      font: '0.5em',
      // width: '10px',
      pointerEvents: 'none'
    }
  }
}

function hintStyle () {
  const left = selectinfo.value.endx;
  let top = selectinfo.value.absy;
  if (top > selectinfo.value.maxy - 100) top = selectinfo.value.maxy - 100;
  if (top < 0) top = 0;
  return {

    // backgroundColor: 'rgba(255, 255, 255, 0.5)',
    position: 'absolute',
    top: `${top}px`,
    left: `${left}px`,
    width: '200px',
    fontSize: '0.5em',
    color: 'white',
    pointerEvents: 'none'
  }
}

function recordtime () {
  timerange.value.recordedstart = timerange.value.selstart
  timerange.value.recordedend = timerange.value.selend
}

function sel_timerange (start, end) {
  timerange.value.start = Math.floor(start / 3600) * 3600
  timerange.value.end = Math.ceil(end / 3600) * 3600;
  console.log('sel_timerange', timerange.value.start, timerange.value.end)
  timerange.value.selstart = start
  timerange.value.selend = end
  recordtime()
}

function sel_time (start, end, refresh = true) {
  if (start < timerange.value.start) {
    start = timerange.value.start
  }
  if (end > timerange.value.end) {
    end = timerange.value.end
  }
  timerange.value.selstart = start
  timerange.value.selend = end
  if (refresh) {
    refresh_all()
  }
}

function reroletime () {
  sel_time(timerange.value.recordedstart, timerange.value.recordedend)
}

function resettime () {
  sel_time(timerange.value.start, timerange.value.end)
}

function movetime (direct) {
  const l = (timerange.value.selend - timerange.value.selstart) / 2
  if (direct === 1) {
    sel_time(timerange.value.selstart + l, timerange.value.selend + l)
  } else {
    sel_time(timerange.value.selstart - l, timerange.value.selend - l)
  }
}

const storage_url = {
  数据服务器: '/a',
  计算服务器1: '/b',
  计算服务器2: '/c'
}
const focused = ref(-1);

const emitCheckboxChange = (value) => {
  console.log('isChecked', value)
  if (value) {
    focused.value = 0;
  } else {
    focused.value = -1;
  }
}

function create_panel (name) {
  let panel = _panels.value[name]
  if (!panel) {
    panel = {
      name, series: {}, selected: []
    }
    _panels.value[name] = panel
  }
  return panel
}
function create_series (name, key, panel) {
  if (panel.series[key]) return panel.series[key]
  panel.series[key] = {
    name, key, list: {}, selected: [], data: null
  }
  if (!all_selected.value[panel.name] || all_selected.value[panel.name].includes(key)) {
    panel.selected.push(key)
  }

  return panel.series[key]
}

function create_list (name, key, series) {
  if (series.list[key]) return series.list[key]
  // console.log('create list', name);
  series.list[key] = { name, key, data: null }
  series.selected.push(key)
  return series.list[key]
}
function series (obj) {
  if (obj.column == null) return;
  const panel = create_panel(obj.task)
  const data = create_series(obj.column, obj.task + '_' + obj.column, panel)
  if (data.data == null || data.data.starttime !== timerange.value.start || data.data.datalen !== timerange.value.end - timerange.value.start) {
    data.data = create_empty_time_buffer(timerange.value.start, timerange.value.end, obj)
  }
  add_time_buffer(data.data, obj)
}

function lists (obj) {
  if (obj.length === 0) return
  let source = obj.data[0].event_source // 目前一个list只有一个source
  if (!source) {
    try {
      source = obj.data[0].data[0].event_source
    } catch (e) {
      try {
        source = obj.data[0].data.event_source
      } catch (e) {
        console.log('error', e)
      }
    }
  }
  if (!source) return
  const source_splited = source.split('_')
  const fixed_prefix = ['mcu_electric', 'intervention_pillow_event', 'imu_head', 'air_pressure']
  let source_name = null;
  let source_column;

  for (const i in fixed_prefix) {
    if (source.startsWith(fixed_prefix[i])) {
      source_name = fixed_prefix[i];
      source_column = source.slice(fixed_prefix[i].length + 1);
      if (source_column === '') source_column = 'data';
      source = source_name + '_' + source_column;
      break;
    }
  }
  if (!source_name) {
    if (source_splited.length === 1) {
      source_name = source;
      source_column = 'data';
    } else {
      source_name = source_splited[0];
      source_column = source_splited.slice(1).join('_');
    }
    source = source_name + '_' + source_column
  }
  const series = create_series(source_column, source, create_panel(source_name))
  const data = create_list(obj.task, obj.task, series)
  if (data.data == null || data.data.starttime !== timerange.value.start || data.data.datalen !== timerange.value.end - timerange.value.start) {
    data.data = create_empty_event_list(timerange.value.start, timerange.value.end)
  }
  add_event_list(data.data, obj)
}
// 并发控制函数，限制同时运行的任务数量
async function runWithConcurrencyLimit (tasks, limit) {
  const results = [];
  const executing = new Set();

  for (const task of tasks) {
    const promise = task().then(result => {
      executing.delete(promise);
      return result;
    });
    results.push(promise);
    executing.add(promise);

    // 如果执行中的任务数量达到限制，等待一个完成
    if (executing.size >= limit) {
      await Promise.race(executing);
    }
  }

  // 等待所有任务完成
  return Promise.all(results);
}

// 填充并处理每个文件的数据，增加 lastModified 参数
async function fill_fetched_data (url, lastModified) {
  const data = await fetchAndCacheFile(url, lastModified);
  if (data.type === 1) {
    series(data);
  } else if (data.type === 2) {
    lists(data);
  }
  return data;
}

const all_selected = ref({})

const storageKey = `${props.mode}-unselected`;

function paint_selected () {
  for (const i in all_selected.value) {
    if (_panels.value[i]) {
      // 下面把_panels.value[i]的selected中的被unselected[i]包含的去掉
      _panels.value[i].selected = all_selected.value[i];
    }
  }
}
function loadselected () {
  const savedData = loadLocalStorage(storageKey) || {};
  // 初始化 subscriptions 和 lastSelectedSubscription
  all_selected.value = savedData.all_selected ? savedData.all_selected : {};
  paint_selected()
}

function saveselected() {
  for (const i in _panels.value) {
    all_selected.value[i] = _panels.value[i].selected
  }
  const dataToSave = {
    all_selected: all_selected.value
  };
  saveLocalStorage(storageKey, dataToSave);
}


function find_list_obj (key) {
  for (const p in _panels.value) {
    const panel = _panels.value[p]
    for (const s in panel.series) {
      const series = panel.series[s]
      for (const l in series.list) {
        const list = series.list[l]
        if (list.key === key) {
          return list
        }
      }
    }
  }
  return null
}

function refresh_all () {
  for (const p in _panels.value) {
    const panel = _panels.value[p]
    for (const s in panel.series) {
      const series = panel.series[s]
      series.last_resized = Date.now()
      for (const l in series.list) {
        const list = series.list[l]
        list.last_resized = Date.now()
      }
    }
  }
}

// 新的异步函数 process_task，处理单个任务组
async function process_task (taskName, fileList) {
  console.log(`开始处理任务组: ${taskName}`);
  const starttime = Date.now();

  // 获取对应的 series 对象和 list 对象

  // 并发加载文件，不限制数量，传递 lastModified 参数
  const filePromises = fileList.flatMap((fileObj) => {
    const [filename, lastmodified] = Object.entries(fileObj)[0];
    return fill_fetched_data(storage_url[props.server] + filename, lastmodified);
  });

  // 等待所有文件加载完成
  await Promise.all(filePromises);
  console.log(`任务组处理完成: ${taskName} (${Date.now() - starttime}ms)`);
  // 更新 series 或 list 对象的 last_modified 标志
  const seiresobj = _panels.value[taskName];
  const listobj = find_list_obj(taskName);
  if (seiresobj) {
    for (const s in seiresobj.series) {
      seiresobj.series[s].last_modified = Date.now();
    }
  } else if (listobj) {
    listobj.last_modified = Date.now();
  }
}

// 使用 process_task 封装的逻辑，并发控制任务组

// 主函数，处理分层并发请求
async function fetch_data (skipped = null, tasks = null) {
  // 初始化 URL 参数数组
  const cmd = {
    type: 'command',
    action: 'channelfiles',
    device: timerange.value.device
  }
  if (timerange.value.start) {
    cmd.starttime = timerange.value.start;
    cmd.endtime = timerange.value.end
  }
  if (timerange.value.mode) {
    cmd.mode = timerange.value.mode
  }
  if (timerange.value.hf) {
    cmd.highfreq = timerange.value.hf
  }
  if (skipped && skipped.length > 0) {
    cmd.skipped = skipped
  }
  if (tasks && tasks.length > 0) {
    cmd.tasks = tasks
  }
  if (timerange.value.date) {
    cmd.date = timerange.value.date
  }
  await sendMessage(cmd, props.server);
}

const handleChannelFile = (data) => {
  const response = data.message.data;
    if (response.timerange) {
    sel_timerange(response.timerange.starttime, response.timerange.endtime);
  }

  // 获取所有任务组的键值对数组
  const taskEntries = Object.entries(response.files);

  const taskGroupPromises = taskEntries.map(([taskName, fileList]) => () => process_task(taskName, fileList));

  // 使用并发控制函数，限制同时运行的任务组数量为 5
  runWithConcurrencyLimit(taskGroupPromises, 2)
    .then((results) => {
      console.log('All tasks completed:', results);
    })
    .catch((error) => {
      console.error('Error:', error);
    });

  // 更新最后修改时间
  refresh_all()
};

watch([() => props.device, () => props.globalstart, () => props.globalend, () => props.date], async () => {
  let reload = false;
  if (props.mode !== timerange.value.mode || timerange.value.device !== props.device || timerange.value.date !== props.date) {
    timerange.value.mode = props.mode
    timerange.value.device = props.device
    timerange.value.date = props.date
    focused.value = -1;
    _panels.value = {}
    reload = true
  }
  if (props.mode !== timerange.value.mode || timerange.value.device !== props.device || timerange.value.date !== props.date ||
      props.globalstart !== timerange.value.start || timerange.value.end !== props.globalend) {
    timerange.value.start = Math.floor(props.globalstart / 3600) * 3600
    timerange.value.end = Math.ceil(props.globalend / 3600) * 3600
    timerange.value.selstart = props.globalstart
    timerange.value.selend = props.globalend
    timerange.value.hf = props.hf
    focused.value = -1;

    reload = true
  }
  if (reload) {
    await checkAndClearOldCache();
    fetch_data()
  }
  // 判断props.globalend是否和当前时间比较接近，
  if (props.globalend) {
    openStream(props.device);
  }
}, { immediate: true })

// 处理接收到的实时数据
const handleRealtimeData = (data) => {
  const message = data.message;
  if (message.name === 'streamdata') {
    const task = message.task;
    if (message.type === 1) {
      const seiresobj = _panels.value[message.task];
      if (seiresobj) {
        for (const c in message.data) {
          const datalen = message.data[c].length;
          console.log('接收数据series:', task, c, datalen, 'from:', data.from);
          const targetobj = seiresobj.series[task + '_' + c]
          if (targetobj && targetobj.data) {
            const realtime_packed = {
              starttime: message.starttime,
              datalen: message.endtime - message.starttime,
              data: message.data[c],
              freq: message.freq ? message.freq : 1
            }
            targetobj.data.realtime.push(realtime_packed);
            targetobj.last_realtime = Date.now();
            if (focused.value >= 0) {
              if (message.endtime > focused.value) {
                focused.value = Math.floor(message.endtime) + 58;
                if (focused.value > timerange.value.end) {
                  const dur = timerange.value.end - timerange.value.start;
                  const end = Math.ceil(focused.value / 3600) * 3600;
                  sel_timerange(end - dur, end)
                  fetch_data()
                }
                sel_time(focused.value - 60, focused.value)
              } else if (timerange.value.selend - timerange.value.selstart > 60) {
                sel_time(focused.value - 60, focused.value)
              }
            }
          }
        }
      }
    } else if (message.type === 2) {
      console.log('接收list:', task, 'from:', data.from);
      const listobj = find_list_obj(task);
      if (listobj && listobj.data) {
        listobj.data.realtime.push(message.data);
        listobj.last_realtime = Date.now();
      }
    }
  }
};

// 发送 open_stream 命令
const openStream = (deviceName) => {
  const command = {
    type: 'command',
    action: 'open_stream',
    device: deviceName,
    cmd: { highfreq: 1 }
  };
  sendMessage(command, props.server).then((response) => {
    console.log('open_stream 命令发送成功:', response);
  }).catch((error) => {
    console.error('open_stream 命令发送失败:', error);
  });
};

// 发送 close_stream 命令
const closeStream = () => {
  if (props.device) {
    const command = {
      type: 'command',
      action: 'close_stream',
      device: props.device
    };
    sendMessage(command, props.server).then((response) => {
      console.log('close_stream 命令发送成功:', response);
    }).catch((error) => {
      console.error('close_stream 命令发送失败:', error);
    });
  }
};

// 生命周期钩子
onMounted(() => {
  // 监听全局事件总线上的实时数据消息
  eventBus.on('streamdata', handleRealtimeData);
  eventBus.on('channelfiles', handleChannelFile)
  loadselected()
});

onBeforeUnmount(() => {
  // 解绑事件监听
  eventBus.off('streamdata', handleRealtimeData);
  eventBus.off('channelfiles', handleChannelFile)
  // 关闭当前设备的数据流
  closeStream();
});

watch([() => props.visible], ([newVisible]) => {
  if (newVisible) {
    visible_channels.value = {}
    for (const v in newVisible) {
      visible_channels.value[newVisible[v]] = 1
    }
  }
}, { immediate: true });

const handleMouseDown = (event) => {
  const x = event.offsetX;
  selectinfo.value.button = event.button;
  selectinfo.value.startx = x

  if (event.button === 0) {
    selectinfo.value.move = 1;
  }
  if (event.button === 2) {
    selectinfo.value.holdtime = timerange.value.selstart + (timerange.value.selend - timerange.value.selstart) * x / timerange.value.clientwidth;
  }
};

const handleMouseUp = (event, series) => {
  console.log('mouse up', event.button, series)
  selectinfo.value.move = 0
  const x1 = selectinfo.value.startx;
  const x2 = event.offsetX;
  const time1 = timerange.value.selstart + (timerange.value.selend - timerange.value.selstart) * x1 / event.currentTarget.clientWidth;
  const time2 = timerange.value.selstart + (timerange.value.selend - timerange.value.selstart) * x2 / event.currentTarget.clientWidth

  if (anotating.value && (series.key === 'electric2k_electric_data' || series.key === '信号_压电原始' || series.key === 'psgsync_electric_data' || series.key === 'bcg_electric_data')) {
    if (selectinfo.value.button === 0) {
      if (time2 - time1 > 0.0005 && x2 - x1 > 5) {
        anotateData.value = {
          task: anotatetask.value,
          type: series.key,
          mode: timerange.value.mode,
          date: timerange.value.date,
          select: '正例',
          starttime: formatDateTime_full(timerange.value.selstart + (timerange.value.selend - timerange.value.selstart) * selectinfo.value.startx / event.currentTarget.clientWidth),
          endtime: formatDateTime_full(timerange.value.selstart + (timerange.value.selend - timerange.value.selstart) * event.offsetX / event.currentTarget.clientWidth),
          device: timerange.value.device
        }

        anotateVisible.value = true;
      } else {
        handleclickwhenaotating(x1, time1, series)
      }
    }
  } else {
    if (time2 - time1 > 0.0005 && x2 - x1 > 5) {
      if (selectinfo.value.button === 0) {
        recordtime();
        sel_time(time1, time2)
      }
    } else {
      if (selectinfo.value.button === 0) {
        if (event.currentTarget.classList.contains('heatmap') && series.data) {
          if (event.currentTarget.clientHeight < 100) {
            event.currentTarget.style.height = '300px'
          } else {
            event.currentTarget.style.height = '60px'
          }
        }
      }

      if (selectinfo.value.button === 2) {
        // reroletime()
      }
    }
  }
};

function handleAnotateClose (visible) {
  console.log(visible)
  anotateVisible.value = false;
}

const handleAnotateSubmit = (data) => {
  console.log('更新事件触发，数据：', data);
  fetch_data(annotats).then((result) => {
    console.log('Async function result:', result);
    // 继续处理 result
  }).catch((error) => {
    console.error('Error:', error);
  });
};

function remove_events (annotats) {
  for (const p in _panels.value) {
    const panel = _panels.value[p]
    for (const s in panel.series) {
      const series = panel.series[s]

      for (const l in series.list) {
        if (annotats.includes(l)) {
          const index = series.selected.indexOf(l);
          if (index > -1) {
            series.selected.splice(index, 1);
          }
          series.list[l] = null
          delete series.list[l]
        }
      }
    }
  }
}
const handleAnotateDelete = (data) => {
  console.log('删除事件触发，数据：', data);
  remove_events(annotats)
  fetch_data(annotats).then((result) => {
    console.log('Async function result:', result);
    // 继续处理 result
  }).catch((error) => {
    console.error('Error:', error);
  });
};

function handleclickwhenaotating (x1, time1, series) {
  console.log('click when anotating', x1, time1, series)
  for (const d in series.list) {
    const paint_info = series.list[d].data;
    if (paint_info) {
      try {
        for (const ename in paint_info) {
          for (const i in paint_info[ename]) {
            const eobj = paint_info[ename][i];
            if (eobj.view_type === 1 && eobj.x1 <= x1 && eobj.x2 >= x1 && eobj.event_type === 'anotate_' + anotatetask.value) {
              anotateData.value = {
                task: anotatetask.value,
                type: series.key,
                mode: timerange.value.mode,
                date: timerange.value.date,
                select: eobj.event_value,
                starttime: formatDateTime_full(eobj.starttime),
                endtime: formatDateTime_full(eobj.endtime),
                device: timerange.value.device,
                action: 'edit'
              }

              anotateVisible.value = true;
              return;
            }
          }
        }
      } catch (e) {
        console.log('error', e)
      }
    }
  }
}

const handleMouseMove = (event, series) => {
  const x = event.offsetX;
  const y = event.offsetY;
  selectinfo.value.endx = x;
  selectinfo.value.endy = y;

  selectinfo.value.over = 1

  const ctime = timerange.value.selstart + (timerange.value.selend - timerange.value.selstart) * x / event.currentTarget.clientWidth
  const current_time = formatTime(ctime)
  // console.log(gpu_drawer.selstart, gpu_drawer.selend, x, timerange.value.clientwidth, current_time)
  let info = { 通道: series.key, 时间: current_time, 偏移: (ctime - timerange.value.start).toFixed(2) }
  if (series.y2) {
    const y2 = series.y2[Math.floor(x)]
    const y1 = series.y1[Math.floor(x)]
    if (y1 !== y2) { info['值范围'] = '(' + y1.toFixed(2) + '-' + y2.toFixed(2) + ')'; } else { info['值'] = y1 ? y1.toFixed(2) : 0; }
  }
  if (event.currentTarget.classList.contains('heatmap')) {
    selectinfo.value.absy = event.currentTarget.offsetTop + event.offsetY
    selectinfo.value.maxy = event.currentTarget.offsetParent.clientHeight
  }
  const events = {}
  let count = 0
  for (const d in series.list) {
    const paint_info = toRaw(series.list[d].paint_data);
    if (paint_info) {
      try {
        for (const ename in paint_info) {
          for (const i in paint_info[ename]) {
            const eobj = paint_info[ename][i];
            if (eobj.view_type === 1 && eobj.x1 <= x && eobj.x2 >= x) {
              const view = {}
              view[eobj.event_type] = eobj.event_info
              events['事件' + count] = view
              count++
            }
            if (eobj.view_type === 2 && eobj.x1 <= x && eobj.x2 >= x) {
              events['事件' + count] = { 组合事件: eobj.hit + '个事件' };
              count++
            }
          }
        }
      } catch (e) {
        console.log('error', e)
      }
    }
  }

  if (count === 1) {
    for (const e in events) {
      info = Object.assign(info, events[e])
    }
  } else if (count > 1) {
    for (const e in events) {
      info[e] = events[e]
    }
  }

  hintinfo.value.info = info

  hintinfo.value.move = 1
};

const onCheckboxChange = (value) => {
  saveselected();
};
const handleMouseLeave = () => {
  console.log('鼠标离开');
  hintinfo.value.move = 0
  selectinfo.value.over = 0
};

const handleMouseClick = (event) => {
  console.log('鼠标点击', event);
};
</script>

<style scoped>
/* 样式定义 */
.toolbar {
  padding: 2px;
  display: flex;
  border: 1px solid black;
  font-size: 0.5em; /* 或者使用具体的像素值，如 '12px' */
  color: black
}

.checkbox-group {
        /*background-color: black;*/
    background-color: black;
    padding: 2px;
    font-size: 0.5em; /* 或者使用具体的像素值，如 '12px' */
    display: flex;
    flex-wrap: wrap; /* 允许换行 */
    gap: 1px; /* 设置复选框之间以及行之间的间距 */
    max-height: 50px;
    height: auto;
    color: white;
    transition: max-height 0.5s ease;
}
.checkbox-group:hover {
  max-height: 300px;
}

.checkbox-item label {
  cursor: pointer;
  margin-left: 2px; /* 标签和复选框之间的间距 */
  font-size: 0.5em; /* 或者使用具体的像素值，如 '12px' */
}

.heatmap {
  position: relative;
  height: 60px;
  background-color: black;
  width: 100%;
  font-size: 0.5em; /* 或者使用具体的像素值，如 '12px' */
}
</style>
