<template>
  <canvas ref="timerangeCanvas" :style="{width: '100%', height: '100%', cursor: 'pointer'}"></canvas>
</template>

<script setup>
import { onMounted, ref, defineProps, onUnmounted, watch } from 'vue';
import { add_time_buffer_fragment, get_canvas_pos } from '@/plugins/timebuffer';

// Props
const props = defineProps({
  seriesobj: Object,
  color: String,
  selstart: Number,
  selend: Number,
  serieskey: String,
  last_modified: Number,
  last_realtime: Number,
  last_resized: Number
});
const timerangeCanvas = ref(null);
let resizeObserver;
let current_data = null;
const drawChannel = () => {
  const canvas = timerangeCanvas.value;
  if (props.seriesobj == null || canvas == null || props.seriesobj.data == null) return;

  // Adjust canvas dimensions if needed
  if (canvas.width !== canvas.clientWidth || canvas.height !== canvas.clientHeight) {
    canvas.width = canvas.clientWidth;
    canvas.height = canvas.clientHeight;
  }
  const paint_info = get_canvas_pos(props.seriesobj.data, props.selstart, props.selend, canvas.width);
  const focused = canvas.height > 100
  const height = focused ? 300 : 60;
  const p = paint_info.y1;
  const q = paint_info.y2;
  current_data = props.seriesobj;
  current_data.y1 = p;
  current_data.y2 = q;
  const rev = !(props.serieskey.includes('stages') || props.serieskey.includes('sleep'));
  const pwidth = p.length;

  // Calculate mean and scale based on the min and max values in the arrays
  let minVal = Infinity;
  for (let i = 0; i < pwidth; i++) {
    if (p[i] < minVal) {
      minVal = p[i];
    }
  }
  let maxVal = -Infinity;
  for (let i = 0; i < pwidth; i++) {
    if (q[i] > maxVal) {
      maxVal = q[i];
    }
  }

  const mean = (maxVal + minVal) / 2;
  const scale = (height - 10) / (maxVal - minVal + 1);

  const ctx = canvas.getContext('2d');
  ctx.clearRect(0, 0, canvas.width, canvas.height); // Clear previous drawings
  ctx.strokeStyle = props.color;
  ctx.lineWidth = 1;
  ctx.fillStyle = focused ? '#151515' : 'black';
  ctx.fillRect(0, 0, pwidth, height);

  // Draw line with calculated mean and scale
  ctx.fillStyle = props.color;
  ctx.beginPath();
  let y1 = 0; let y2 = 0;
  let oi = -1; let oy1 = y1; let oy2 = y2;

  for (let i = 0; i < pwidth; i++) {
    if (isNaN(p[i])) continue;
    oy1 = y1;
    oy2 = y2;

    y1 = Math.round((p[i] - mean) * scale + height / 2);
    y2 = Math.round((q[i] - mean) * scale + height / 2);

    if (rev) {
      y1 = height - y1;
      y2 = height - y2;
    }
    ctx.moveTo(i, y1);
    ctx.lineTo(i, y2);

    if (oi >= 0 && oi >= i - 1) {
      if (oy1 >= y2) {
        ctx.moveTo(oi, oy1);
        ctx.lineTo(i, y2);
      } else if (oy2 <= y1) {
        ctx.moveTo(oi, oy2);
        ctx.lineTo(i, y1);
      }
    }
    oi = i;
  }
  ctx.stroke();

  // Draw scale markers if focused
  if (focused) {
    ctx.fillStyle = 'white';
    ctx.strokeStyle = 'white';
    ctx.lineWidth = 0.5;
    ctx.beginPath();

    const diff = maxVal - minVal;
    for (let i = -2; i < 3; i++) {
      let y = Math.round(height / 2 + i * (diff * (scale / 4) - 5));
      if (rev) {
        y = height - y;
      }
      ctx.moveTo(0, y);
      ctx.lineTo(pwidth, y);

      const value = (mean + i * diff / 4).toFixed(2);
      ctx.fillText(value, 0, y);
    }
    ctx.stroke();
  }
};

// Initialize component and watch for changes
watch([() => props.last_modified],
  () => {
    current_data = props.seriesobj;
    for (const i in current_data.data.realtime_history) {
      const obj = current_data.data.realtime_history[i];
      add_time_buffer_fragment(current_data.data, obj);
    }
    drawChannel();
  }
);

watch([() => props.last_resized],
  () => {
    drawChannel();
  }
);

watch(() => props.last_realtime,
  () => {
  try {
    current_data = props.seriesobj;
    while (current_data.data.realtime.length > 0) {
      const obj = current_data.data.realtime.shift();
      add_time_buffer_fragment(current_data.data, obj);
      current_data.data.realtime_history.push(obj);
    }
    drawChannel();
  } catch (e) {
    console.log(e);}
  }
);

onMounted(() => {
  resizeObserver = new ResizeObserver(() => {
    if (props.seriesobj.data == null) {
      console.log('resized ' + props.serieskey + ' seriesobj.data is null');
    }
    drawChannel(); // 尺寸变化时触发重绘
  });
  resizeObserver.observe(timerangeCanvas.value);
  const canvas = timerangeCanvas.value;
  canvas.addEventListener('mousemove', handleMouseMove);
  canvas.addEventListener('mouseleave', handleMouseLeave);
  canvas.addEventListener('click', handleClick);
});

function merge_realtime (realtime) {
  add_time_buffer_fragment(current_data.data, realtime);
}
onUnmounted(() => {
  if (resizeObserver) {
    resizeObserver.disconnect(); // 清理监听器
  }
});
function handleMouseMove () {
  // Logic for handling mouse movement if needed
}

function handleMouseLeave () {
  // Logic for handling mouse leave if needed
}

function handleClick () {
  // Logic for handling click event if needed
}
</script>

<style scoped>
canvas {
  position: absolute;
  top: 0;
  left: 0;
}
</style>
