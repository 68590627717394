<template>
  <el-dialog v-model="anotateVisible" :title="anotateData.task">
    <el-form :model="anotateData" label-position="right" label-width="100px">
      <el-form-item label="开始时间">
        <el-input v-model="anotateData.starttime" disabled></el-input>
      </el-form-item>

      <!-- 显示 endtime -->
      <el-form-item label="结束时间">
        <el-input v-model="anotateData.endtime" disabled></el-input>
      </el-form-item>
      <el-form-item label="标注">
        <el-select v-model="anotateData.select" placeholder="请选择" style="width: 100%;">
          <el-option label="正例" value="正例"></el-option>
          <el-option label="负例" value="负例"></el-option>
        </el-select>
      </el-form-item>

      <!-- 根据需要添加更多表单项 -->
    </el-form>
    <template #footer>
      <el-button @click="cancel">取消</el-button>
      <el-button type="primary" @click="submitForm">确认</el-button>
      <!-- 动态显示删除按钮 -->
      <el-button type="danger" v-if="anotateData.action === 'edit'" @click="deleteItem">删除</el-button>

    </template>
  </el-dialog>
</template>

<script setup>
import { ref, defineEmits, defineProps, watch } from 'vue';
import { ElMessage } from 'element-plus';
import { request_data } from '@/plugins/axios';

const props = defineProps({
  data: Object,
  selections: Object,
  anotateVisible: Boolean,
  server: {
    type: String,
    default: '数据服务器'
  }
});
const emit = defineEmits(['anotateVisible']);
const anotateVisible = ref(false);
const anotateData = ref(props.data);

const submitForm = async () => {
  try {
    const params = new URLSearchParams({
      mode: anotateData.value.mode || '',
      task: anotateData.value.task || '',
      select: anotateData.value.select || '',
      starttime: anotateData.value.starttime || '',
      endtime: anotateData.value.endtime || '',
      date: anotateData.value.date || '',
      type: anotateData.value.type || ''
    }).toString();

    const response = await request_data(`/anotate/${anotateData.value.device}/?${params}`, props.server);
    console.log(response)
    emit('update:anotateVisible', false);
    emit('submit', anotateData.value); // 触发 submit 事件
    ElMessage.success('标注更新成功');
  } catch (error) {
    console.log(error);
    ElMessage.error('更新失败');
  }
};

const deleteItem = async () => {
  try {
    const params = new URLSearchParams({
      task: anotateData.value.task || '',
      select: 'delete',
      mode: anotateData.value.mode || '',
      date: anotateData.value.date || '',
      starttime: anotateData.value.starttime || '',
      endtime: anotateData.value.endtime || '',
      type: anotateData.value.type || ''
    }).toString();
    const response = await request_data(`/anotate/${anotateData.value.device}/?${params}`, props.server);
    console.log(response);
    emit('update:anotateVisible', false);
    emit('delete', anotateData.value); // 触发 delete 事件
    ElMessage.success('标注删除成功');
  } catch (error) {
    console.log(error);
    ElMessage.error('删除失败');
  }
};

const cancel = () => {
  emit('update:anotateVisible', false);
};
watch(() => props.data, (newVal) => {
  anotateData.value = newVal;
});

</script>
