<template>
  <div class="search-area">
    <el-input v-model="search" placeholder="搜索设备" class="search-input"></el-input>
    <el-date-picker
        v-model="selectedDate"
        type="date"
        placeholder="选择日期"
        class="date-picker"
        @change="handleDateChange"
        :disabled-date="disableFutureDates"
    ></el-date-picker>
  </div>

  <div class="content-row">
    <div class="table-data">
      <el-table
          :data="devices"
          class="device-table"
          border
          stripe
      >
        <el-table-column prop="device" label="设备编号">
          <template #default="scope">
            <span @click="handleJump('device', scope.row.device)">{{ scope.row.device }}</span>
            <el-tooltip placement="right" effect="light" trigger="click">
              <template #content>
                <div class="tooltip-content">
                  <TreeViewObj :data="scope.row"></TreeViewObj>
                </div>
              </template>
              <el-tag type="info" size="small" effect="dark">动态</el-tag>
            </el-tooltip>
          </template>
        </el-table-column>
        <el-table-column prop="status" label="基础信息">
          <template #default="scope">
            <DeviceSummary :info="scope.row"/>
          </template>
        </el-table-column>
        <el-table-column prop="start_per" label="首次报告">
          <template #default="scope">
            <div class="custom-div">
              <span>数据完整性：{{ scope.row.start_per }}</span>
            </div>
            <div class="custom-div">
              <span>数据缺失（秒）：{{ scope.row.首次报告.missed }}</span>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="end_per" label="末次报告">
          <template #default="scope">
            <div class="custom-div">
              <span>数据完整性：{{ scope.row.end_per }}</span>
            </div>
            <div class="custom-div">
              <span>数据缺失（秒）：{{ scope.row.末次报告.missed }}</span>
            </div>
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页组件 -->
      <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="currentPage"
          :page-sizes="[10, 20, 50, 100]"
          :page-size="pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
          class="pagination"
      ></el-pagination>
    </div>
    <div class="counts">
      <el-row>
        <el-col :span="24" class="count-item">
          <el-card>
            <template v-slot:header>
              <div class="clearfix">
                <span>首次报告统计</span>
              </div>
            </template>
            <div v-if="counts && counts['首次报告']" class="custom-card">
              <!-- 你的信息内容 -->
              <p>总体数据完整性：{{ counts['首次报告']['start_per_str'] || 'N/A' }}</p>
              <p>100%: {{ counts['首次报告']['100'] || 'N/A' }}</p>
              <p>99%-100%: {{ counts['首次报告']['99'] || 'N/A' }}</p>
              <p>90%-99%: {{ counts['首次报告']['90'] || 'N/A' }}</p>
              <p>0-90%: {{ counts['首次报告']['89'] || 'N/A' }}</p>
              <p>总结收数据: {{ counts['首次报告']['start_count'] || 'N/A' }}秒</p>
              <p>数据缺失: {{ counts['首次报告']['start_miss_count'] || 'N/A' }}秒</p></div>
          </el-card>
        </el-col>

        <el-col :span="24" class="count-item">
          <el-card>
            <template v-slot:header>
              <div class="clearfix">
                <span>末次报告统计</span>

              </div>
            </template>
            <div v-if="counts && counts['末次报告']" class="custom-card">
              <!-- 你的信息内容 -->
              <p>数据完整性：{{ counts['末次报告']['end_per_str'] || 'N/A' }}</p>
              <p>100%: {{ counts['末次报告']['100'] || 'N/A' }}</p>
              <p>99%-100%: {{ counts['末次报告']['99'] || 'N/A' }}</p>
              <p>90%-99%:: {{ counts['末次报告']['90'] || 'N/A' }}</p>
              <p>0-90%: {{ counts['末次报告']['89'] || 'N/A' }}</p>
              <p>总结收数据: {{ counts['末次报告']['end_count'] || 'N/A' }}秒</p>
              <p>数据缺失: {{ counts['末次报告']['end_miss_count'] || 'N/A' }}秒</p></div>
          </el-card>
        </el-col>
      </el-row>
    </div>
  </div>
</template>
<script setup>
import { onMounted, ref, watch} from 'vue';
import TreeViewObj from '@/common/TreeViewObj.vue';
import {useStore} from "vuex";
import {useRouter} from "vue-router";
import dayjs from "dayjs";
import {useJumpData} from "@/plugins/checkJumpData";
import DeviceSummary from "@/components/DeviceSummary.vue";
import {createAxiosInstance} from "@/plugins/axios";

const {checkJumpData} = useJumpData();


const currentPage = ref(1);
const pageSize = ref(100);
const search = ref('');
const total = ref(0);
const devices = ref([]);
const selectedDate = ref(dayjs().toDate());  // 设置默认值为当前日期
const router = useRouter();
const store = useStore();
const sort = ref({prop: 'start_per', order: 'ascending'});
const counts = ref([])
const axiosInstance = createAxiosInstance("计算服务器1");

const handleSizeChange = (newSize) => {
  pageSize.value = newSize;
  fetchDevices();
};

const handleCurrentChange = (newPage) => {
  currentPage.value = newPage;
  fetchDevices();
};

// 处理日期变化
const handleDateChange = (newDate) => {
  selectedDate.value = newDate;
  fetchDevices();
  featchCount()
};

const fetchDevices = async () => {
  try {

    const response = await axiosInstance.get('/data_detail/list/', {
      params: {
        page: currentPage.value,
        page_size: pageSize.value,
        device: search.value,
        sort_prop: sort.value.prop,
        sort_order: sort.value.order,
        date: selectedDate.value ? dayjs(selectedDate.value).format('YYYY-MM-DD') : null  // 将日期转换为字符串
      },
    });
    console.log(sort.value.order);
    devices.value = response.data.data.sort((a, b) => a.sort_key - b.sort_key);
    total.value = response.data.total;
  } catch (error) {
    console.error('Failed to fetch device data:', error);
  }
};
const featchCount = async () => {
  try {
    const response = await axiosInstance.get('/data_detail/list/', {
      params: {
        data_type: 'count',
        date: selectedDate.value ? dayjs(selectedDate.value).format('YYYY-MM-DD') : null  // 将日期转换为字符串

      },
    });
    counts.value = response.data;
    console.log(counts.value)
  } catch (error) {
    console.error('Failed to fetch devices data:', error);
  }
};

function handleJump(type, value) {
  if (type === 'device' && value) {
    store.commit('setJumpData', {
      type: 'device',
      value: value,
      filter_type: 'input',
      path: '/device-management',
    });
    router.push('/device-management');
  } else if (type === 'org') {
    router.push(`/organization`);
  }
}

// 禁用未来的日期
const disableFutureDates = date => {
  return date > new Date();
};
onMounted(() => {
  checkJumpData()

  fetchDevices();
  featchCount()
});

watch(search, fetchDevices);
watch(selectedDate, fetchDevices);  // 监听日期变化
</script>


<style scoped>
.search-area {
  margin-bottom: 20px;
  display: flex;
  justify-content: center;
  align-items: center; /* 新添加的样式，使搜索框和日期选择框对齐 */
}

.search-input {
  width: 300px;
  margin-right: 10px; /* 添加右边距，分隔搜索框和日期选择框 */
}

.date-picker {
  width: 200px;
}

.content-row {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
}

.table-data {
  width: 70%;
  margin-right: 20px; /* 添加右边距，分隔表格和统计信息卡片 */
}

.device-table {
  margin-bottom: 20px;
}

.device-table .el-table__header-wrapper th {
  background-color: #f2f2f2;
  color: #333;
  font-weight: bold;
  text-align: center;
  padding: 10px;
}

.device-table .el-table__body-wrapper td {
  text-align: center;
  padding: 10px;
  cursor: default;
}

.custom-div,
.custom-div * {
  cursor: default !important;
}

.tooltip-content {
  max-width: 300px;
}

.pagination {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

/deep/ .el-table td.el-table__cell div {
  cursor: default !important;
  box-shadow: none !important;
}

.counts {
  display: flex;
  flex-direction: column;
  gap: 20px; /* 卡片间距 */
  width: 30%;
}

.count-item {
  margin-bottom: 20px;
}

.count-item .el-card {
  width: 100%;
}

.custom-card {
  font-size: 12px;
}

.custom-card p {
  margin: 0;
  padding: 4px 0;
}
</style>

