<template>
  <el-container>
        <!-- 侧边栏 -->
        <el-aside :style="{ width: asideWidth }">
            <the-sidebar></the-sidebar>
        </el-aside>

        <!-- 主内容区，包括头部和主体内容 -->
        <el-container>
            <!-- 头部 -->
            <el-header>
                <the-header></the-header>
            </el-header>

            <!-- 主体内容 -->
            <el-main>
                <router-view></router-view> <!-- 路由出口 -->
            </el-main>
        </el-container>
    </el-container>
    <!-- 登录组件 -->
    <LoginComponent v-if="loginRequired" :isVisible="loginRequired" />
</template>
<script setup>
import { useStore } from 'vuex';
import TheSidebar from './components/TheSidebar.vue';
import TheHeader from './components/TheHeader.vue';
import LoginComponent from './common/LoginComponent.vue';
import { onMounted, computed, ref } from 'vue';

const store = useStore();

const user = computed(() => store.state.user); // 使用Vuex state获取用户信息
const loginRequired = computed(() => {
  if (Object.keys(user.value).length === 0) return true
  return false;
});

onMounted(async () => {
  await store.dispatch('fetchUser'); // 使用Vuex action获取用户信息
});

// 定义响应式数据
const asideWidth = ref('140px');

/* 扩展侧边栏
    const expandAside = () => {
        asideWidth.value = '140px';
    };

    // 折叠侧边栏
    const collapseAside = () => {
        setTimeout(() => {
            asideWidth.value = '20px';
        }, 1000); // 延迟1秒后折叠
    }; */
</script>

<style>
    .el-aside {
        transition: width 0.5s ease; /* 添加平滑过渡效果 */
    }
</style>
