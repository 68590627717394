<script setup>
import { ref, onMounted, onUnmounted, watch } from 'vue';

import TreeViewObj from '@/common/TreeViewObj.vue'; // 确保路径正确
import { useStore } from 'vuex'

import { useRouter } from 'vue-router';

import CustomFilter from '@/common/CustomFilter.vue';
import UserSummary from './UserSummary.vue';
import UploadExcel from '@/components/UploadExcel.vue';
import { ElButton, ElMessage, ElMessageBox } from 'element-plus';
import UserModify from '@/components/UserModify.vue';
import { useJumpData } from '@/plugins/checkJumpData';
import ReportDetail from '@/components/ReportDetail.vue';
import { createAxiosInstance, request_data } from '@/plugins/axios';

const { checkJumpData } = useJumpData();
const current_detail = ref('');
// const {proxy} = getCurrentInstance();

const router = useRouter();
const store = useStore();
// 定义响应式数据
const currentPage = ref(1);
const pageSize = ref(10);
const total = ref(100);
const rows = ref([]);
const formType = ref('');
const formTitle = ref('');
let intervalId = null; // 用于存储定时器ID
let encodedFilterStr = '';
const uploadPath = '/user/upload/'
const row_data = ref({})
const serverType = ref('数据服务器')

// 跳转页面
function handleJump (type, value) {
  if (type === 'device' && value) {
    store.commit('setJumpData', {
      type: 'device',
      value,
      filter_type: 'input',
      path: '/device-management'
    })// 存储跳转信息，filter_type: tga 标签 inputs 搜索框

    router.push('/device-management');
  } else if (type === 'org') {
    router.push('/organization');
  }
}

// 获取用户信息，修改用户信息
function handleEditDialogClose (visible) {
  console.log(visible)
  editDialogVisible.value = false;
}

// 收藏夹模块

const editDialogVisible = ref(false);
const editForm = ref({});

const openEditDialog = async (rowData) => {
  editDialogVisible.value = true;
  const response = await request_data(`/userinfo/${rowData.user}/`);
  editForm.value = response
  formType.value = 'edit'
  formTitle.value = '编辑用户信息'
};
const openAddDialog = () => {
  formType.value = 'add'
  formTitle.value = '新增用户信息'
  editForm.value = {}
  editDialogVisible.value = true;

  // 重置表单或进行其他初始化操作
};

// 提交表单，修改用户信息
// 新增用户
const handleUploadSuccess = (response) => {
  console.log(response)
  ElMessage({
    message: '上传成功',
    type: 'success'
  });
  fetchData(); //
};
const handleChildSuccess = (response) => {
  console.log('子组件上传成功', response);
  // 处理子组件上传成功后的逻辑
  fetchData(); //
};

const handleUploadError = () => {
  ElMessage({
    message: '上传失败',
    type: 'error'
  });
};
// 删除用户
const deleteRow = async (raw) => {
  try {
    console.log(raw.user)
    const axiosInstance = createAxiosInstance(serverType.value);

    const response = await axiosInstance.get(`/user/delete/${raw.user}/`);
    console.log(response)
    ElMessage.success('用户删除成功');
    fetchData()
  } catch (error) {
    console.log(error);
    ElMessage.error('删除失败');
  }
};

const showDeleteConfirm = (raw) => {
  ElMessageBox.confirm('确定要删除该用户吗?', '提示', {
    confirmButtonText: '确定',
    cancelButtonText: '取消',
    type: 'warning'
  }).then(() => {
    deleteRow(raw);
  }).catch(() => {
    ElMessage.info('已取消删除');
  });
};

// 从云端获取数据的方法
const fetchData = async () => {
  if (current_detail.value !== '') return
  try {
    const response = await request_data('/users/?filter=' + encodedFilterStr + '&page=' + currentPage.value)
    rows.value = response.nodes;
    total.value = response.count;
    console.log(encodedFilterStr);
  } catch (error) {
    console.error('获取数据失败:', error);
  }
};

async function navigateToUserReport (user, row) {
  const deviceid = row.info.deviceID

  if (row.info && deviceid) {
    row_data.value = await request_data(`/devicedetail/${deviceid}/`, serverType.value)
    current_detail.value = deviceid
  } else { /* empty */ }
}

// 处理响应数据

// 处理过滤器变化
const handleFilterChange = async (filterStr) => {
  encodedFilterStr = encodeURIComponent(filterStr);
  fetchData();
  // store.commit('setFilter', filterStr);
};

// 处理分页大小变化
const handleSizeChange = (newSize) => {
  pageSize.value = newSize;
  fetchData();
};

// 处理当前页变化
const handleCurrentChange = (val) => {
  currentPage.value = val;
  fetchData();
};

// 排序方法
const sortNames = (a, b) => {
  return (a.user + '').localeCompare(b.user + '');
};
onMounted(async () => {
  checkJumpData()

  fetchData()
  intervalId = setInterval(fetchData, 1000);
});
onUnmounted(() => {
  console.log('unmoounted main')
  if (intervalId) {
    clearInterval(intervalId); // 清除定时器
  }
});

</script>

<template>
  <el-card shadow="always" v-show="current_detail == ''">
    <div>
      <CustomFilter item="user" @filter-changed="handleFilterChange"/>

      <div style="display: flex; align-items: center; justify-content: space-between; margin-bottom: 20px; margin-top: 20px;">

        <!-- 上传Excel -->
        <div style="flex-grow: 1; margin-left: 20px;">
          <UploadExcel
              :on-success="handleUploadSuccess"
              :on-error="handleUploadError"
              :uploadPath="uploadPath"
              :serverType="serverType"
              @success="handleChildSuccess"
          />
        </div>

        <!-- 新增用户按钮 -->
        <div>
          <el-button type="primary" @click="openAddDialog">新增用户</el-button>
        </div>
      </div>

    </div>

    <!-- 表格 -->
    <el-table :data="rows" table-layout="auto">
      <el-table-column type="selection" width="30"/>
      <el-table-column prop="user" label="编号" resizable sortable :sort-method="sortNames">
        <template #default="scope">
          <span v-if="scope.row['设备和网络']['在线']" :style="{ fontWeight: 'bold' }">
            {{ scope.row.user }} | 在线
          </span>
          <span v-else>
            {{ scope.row.user }}
          </span>
          <el-tooltip placement="right" effect="light" trigger="click">
            <template #content>
              <div class="tooltip-content">
                <TreeViewObj :data="scope.row"></TreeViewObj>
              </div>
            </template>
            <el-tag type="info" size="small" effect="dark">动态</el-tag>
          </el-tooltip>
          <el-tag @click="navigateToUserReport(scope.row.user, scope.row)">详情</el-tag>
        </template>
      </el-table-column>
      <el-table-column prop="status" label="基础信息">
        <template #default="scope">
          <span>
            <UserSummary :info="scope.row"/>
          </span>
        </template>
      </el-table-column>
      <el-table-column prop="status" label="设备和网络">
        <template #default="scope">
          <div class="info">
            <div v-if="scope.row.info.deviceID" class="device-jump-tag"
                 @click="handleJump('device', scope.row.info.deviceID)">
              <strong>监测垫：</strong>{{ scope.row.info.deviceID }}
            </div>
            <div v-if="scope.row.info.pillowID" class="device-jump-tag"
                 @click="handleJump('device', scope.row.info.pillowID)">
              <strong>干预枕：</strong>{{ scope.row.info.pillowID }}
            </div>
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="rules" label="偏好策略">
        <template #default="scope">
          <TreeViewObj :data="{ info: scope.row['偏好策略'] }"/>
        </template>
      </el-table-column>
      <el-table-column label="操作" width="180">
        <template #default="scope">
          <el-button size="small" @click="openEditDialog(scope.row)">编辑</el-button>
          <el-button size="small" @click="showDeleteConfirm(scope.row)">删除</el-button>
        </template>
      </el-table-column>
    </el-table>

    <!-- 编辑用户 -->
    <UserModify
        v-model="editDialogVisible"
        :editDialogVisible="editDialogVisible"
        :data="editForm"
        @update:editDialogVisible="handleEditDialogClose"
        :formTitle="formTitle"
        :formType="formType"
        :serverType="serverType"
    />

    <!-- 分页 -->
    <el-pagination
        v-model:current-page="currentPage"
        :page-size="10"
        layout="total, prev, pager, next"
        :total="total"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
    ></el-pagination>
  </el-card>
  <div v-if="current_detail != ''" class="report">
    <ReportDetail v-model:device="current_detail" :selections="row_data" :stage="3"/>
  </div>
</template>

<style scoped>
.add-user-form .el-form-item {
  margin-bottom: 24px; /* 调整表单项间的间距 */
}

.add-user-form .el-input,
.add-user-form .el-select {
  width: 100%; /* 调整输入框和选择框的宽度 */
}

.report {
  height: 100vh; /* 可以根据需要调整这个高度 */
  overflow-y: auto;
  overflow-x: hidden;
}

.device-jump-tag {
  color: #409eff;
  cursor: pointer;
}

.tooltip-content {
  max-width: 200px;
  word-break: break-word;
}
</style>
