<template>
  <div class="data-table-form">
    <div class="form-row">
      <div>
        <label for="table">Table:</label>
        <input type="text" id="table" v-model="formData.table">
      </div>
      <div>
        <label for="item">Item:</label>
        <input type="text" id="item" v-model="formData.item">
      </div>
      <div>
        <label for="tag">Tag:</label>
        <input type="text" id="tag" v-model="formData.tag">
      </div>
      <div>
        <label for="time_type">Value Type:</label>
        <select id="time_type" v-model="formData.time_type">
          <option value="timestamp">TimeStamp</option>
          <option value="float">Float</option>
          <option value="string">String</option>
        </select>
      </div>
      <div>
        <label for="value">Value:</label>
        <input :type="inputType" id="value" v-model="formData.value">
      </div>

      <div>
        <button @click="submitForm('add')">增加</button>
        <button @click="submitForm('remove')">删除</button>
      </div>
    </div>
  </div>
</template>


<script setup>
import { ref, computed, defineProps, watch} from 'vue';
import {ElMessage} from "element-plus";
import {createAxiosInstance} from "@/plugins/axios";

const message = {
  200: "操作成功",
  501: "操作成功"
};

const props = defineProps({
  selectedDbname: String,
  selectedItem: String,
  serverType: String
});

const formData = ref({
  table: '',
  item: '',
  tag: '',
  value: '',
  time_type: 'timestamp' // 默认选择 'float'
});
const inputType = computed(() => {
  switch (formData.value.time_type) {
    case 'string':
      return 'text';  // 当Value Type选择string时，输入框类型为text
    case 'timestamp':
      return 'datetime-local';  // 当Value Type选择timestamp时，输入框类型为datetime-local
    default:
      return 'text';  // 默认返回文本类型
  }
});
const submitForm = async (type) => {
  let value = formData.value.value
  if (formData.value.time_type === 'timestamp') {
    const date = new Date(formData.value.value);
    value = date.getTime();
  }
  let params; // 声明 params 变量
  params = new URLSearchParams({
    table: formData.value.table || '',
    tag: formData.value.tag || '',
    value: value || '',
    // value: timestamp || '',
    time_type: formData.value.time_type || '',
    item: formData.value.item || '',
  }).toString();
  const axiosInstance = createAxiosInstance(props.serverType);

  const response = await axiosInstance.get(`/config/${type}/?${params}`);
  console.log(response.data)
  console.log(message)
  ElMessage.success(message[response.data.code]);

  // 这里可以添加提交表单的逻辑，例如 API 调用等
}
// 监听 props 的变化并更新 formData
watch(() => props.selectedDbname, (newVal) => {
  formData.value.table = newVal;
});

watch(() => props.selectedItem, (newVal) => {
  formData.value.item = newVal;
});

</script>

<style scoped>
.data-table-form {
  background-color: #f8f9fa; /* 浅灰色背景 */
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  max-width: 100%;
}

.data-table-form .form-row {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  gap: 15px;
}

.data-table-form .form-row > div {
  display: flex;
  align-items: center;
  gap: 5px;
}

.data-table-form label {
  min-width: 75px;
  color: #333;
  font-weight: bold;
}

.data-table-form input, .data-table-form select {
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  width: 100%;
}

button {
  padding: 8px 16px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
}

button:hover {
  background-color: #0056b3;
}
</style>


