import { getColor, ledColor } from '../utils/utils';

import { locales } from '../utils/locales';

import {
  chart_dates, // 图表横轴的日期与报告数据之间的映射关系
  getInterval
} from './variables.js';

const L = locales['zh-Hans']
const monthNameArray = [L.jan, L.feb, L.mar, L.apr, L.may, L.jun, L.jul, L.aug, L.sep, L.oct, L.nov, L.dec];
function getSymbol (value, params, data, icon) { // 单点数据需要显示symbol，能连成线则不显示
  const left = params.dataIndex - 1;
  const right = params.dataIndex + 1;
  let symbol = 'none';
  if (left >= 0 && right < data.length) { // 中间单点
    if (data[left][1] == null && data[right][1] == null) {
      symbol = icon || 'circle';
    }
  } else if (right < data.length) { // 最左边缘
    if (data[right][1] == null) symbol = icon || 'circle';
  } else if (left >= 0) { // 最右边缘
    if (data[left][1] == null) symbol = icon || 'circle';
  }
  return symbol;
}

export function setHRChartOption (reports, hr_chart) {
  if (!hr_chart) {
    return;
  }

  const dataHRmin = [];
  const dataHRavg = [];
  const dataUpper = [];
  const dataLower = [];
  const dataXAxis = [];

  // 从日期序列转换到图表数据
  let weekmode = false;
  if (Object.keys(reports).length > 0) {
    const total = Object.keys(chart_dates).length;
    // 如果周期太长，以周为单位计算均值
    if (total <= 60) {
      let totalHole = 0;
      for (const key in chart_dates) {
        const [month, day] = key.split('/');
        const abbrKey = month + '-' + day;

        // 添加安全检查
        let min = null; let avg = null; let upper = null; let lower = null;
        if (reports[key] && typeof reports[key].bpm_min !== 'undefined') {
          min = Math.round(reports[key].bpm_min);
          avg = Math.round(reports[key].bpm_avg);
          upper = Math.round(reports[key].bpm_limit_max);
          lower = Math.round(reports[key].bpm_limit_min);
        }

        if (!min) min = null;
        if (!avg) avg = null;
        let minColor = ledColor[0];
        if (min > upper || min < lower) {
          minColor = ledColor[1];
        }
        dataHRmin.push({ name: abbrKey, value: min, itemStyle: { color: minColor } });
        dataHRavg.push({ name: abbrKey, value: avg });
        dataUpper.push([abbrKey, upper ? (upper - lower) : null]);
        dataLower.push([abbrKey, lower]);
        if (!upper || !lower) {
          totalHole++;
        }
        dataXAxis.push(abbrKey);
      }
      if (totalHole) {
        let holeStart = 0;
        // 如果空洞不超过4天，不要断开空洞的部分，平滑过渡
        for (let i = 0; i < dataUpper.length; i++) {
          if (dataUpper[i][1]) {
            if (holeStart && (i - holeStart) <= 4) {
              dataUpper.splice(holeStart, i - holeStart);
              dataLower.splice(holeStart, i - holeStart);
              i = holeStart;
            }
            holeStart = 0;
          } else if (!holeStart) {
            holeStart = i;
          }
        }
      }
    } else {
      weekmode = true;
      let [min_avg, avg_avg, upper_avg, lower_avg, count, countBase] = [0, 0, 0, 0, 0, 0];
      let valid = false; // 跳过开头的“半周”
      let weekend = '';
      for (let key in chart_dates) {
        const [year, month, day] = key.split('-');
        const d = new Date();
        d.setFullYear(year);
        d.setMonth(month - 1);
        d.setDate(day);
        d.setHours(0, 0, 0, 0);
        const weekday = d.getDay();

        let min = chart_dates[key] >= 0 ? Math.round(reports[chart_dates[key]].bpm_min) : null;
        let avg = chart_dates[key] >= 0 ? Math.round(reports[chart_dates[key]].bpm_avg) : null;
        const upper = chart_dates[key] >= 0 ? Math.round(reports[chart_dates[key]].bpm_limit_max) : null;
        const lower = chart_dates[key] >= 0 ? Math.round(reports[chart_dates[key]].bpm_limit_min) : null;
        if (!min) min = null;
        if (!avg) avg = null;
        if (min != null && avg != null && valid) {
          min_avg += min;
          avg_avg += avg;
          count++;
        }
        if (upper != null && lower != null && valid) {
          upper_avg += upper;
          lower_avg += lower;
          countBase++;
        }
        if (weekday === 6) { // 每周最后一天
          weekend = month + '-' + day;
        }
        if (weekday === 0) { // 每周第一天
          if (valid) {
            min_avg = count ? Math.round(min_avg / count) : null;
            avg_avg = count ? Math.round(avg_avg / count) : null;
            upper_avg = countBase ? Math.round(upper_avg / countBase) : null;
            lower_avg = countBase ? Math.round(lower_avg / countBase) : null;

            let minColor = ledColor[0];
            if (min_avg > upper_avg || min_avg < lower_avg) {
              minColor = ledColor[1];
            }

            key = month + '-' + day + L.to + weekend;
            dataHRmin.push({ name: key, value: min_avg, itemStyle: { color: minColor } });
            dataHRavg.push({ name: key, value: avg_avg });
            dataUpper.push([key, upper_avg ? (upper_avg - lower_avg) : null]);
            dataLower.push([key, lower_avg]);
            dataXAxis.push(key);
            [min_avg, avg_avg, upper_avg, lower_avg, count, countBase] = [0, 0, 0, 0, 0, 0];
            weekend = '';
          } else {
            valid = true;
          }
        }
      }
    }
  }
  dataHRmin.reverse();
  dataHRavg.reverse();
  dataUpper.reverse();
  dataLower.reverse();
  dataXAxis.reverse();

  const option = {
    backgroundColor: 'transparent',
    animationDuration: 500,
    /* title: {
            text: '睡眠心率 (次/分)',
            padding: [0, 0, 0, 32],
            textStyle: {
                fontWeight: 'normal',
                fontSize: 14
            }
        }, */
    tooltip: {
      trigger: 'axis',
      confine: true,
      order: 'seriesAsc',
      shadowBlur: 4,
      backgroundColor: getColor('background'),
      textStyle: {
        fontSize: 12,
        lineHeight: 9,
        textShadowBlur: 2,
        textShadowColor: 'transparent',
        color: getColor('text')
      },
      axisPointer: {
        type: 'line',
        label: {
          show: true,
          backgroundColor: '#6a7985'
        }
      },
      // valueFormatter: (value) => value ? value + '次/分' : '-',
      formatter: (params) => {
        if (params.length != 4) { return null; }

        return params[0].name + '\n' +
                    params[0].marker + params[0].seriesName + '    ' + (params[0].value ? Math.round(params[0].value) + L.bpm : L.nodata_abbr) + '\n' +
                    params[1].marker + params[1].seriesName + '    ' + (params[1].value[1] ? (params[1].value[1] + '-' + (params[1].value[1] + params[2].value[1]) + L.bpm) : L.nodata_abbr) + '\n' +
                    params[3].marker + params[3].seriesName + '    ' + (params[3].value ? Math.round(params[3].value) + L.bpm : L.nodata_abbr);
      }
    },
    legend: {
      data: [{ name: L.lowest_hr }, {
        name: L.lowest_hr_baseline,
        icon: 'roundRect',
        itemStyle: { opacity: 0.8 }
      }, { name: L.average_hr }],
      orient: 'horizontal',
      bottom: 1,
      selectedMode: false,
      icon: 'circle',
      itemHeight: 10,
      itemWidth: 10,
      itemGap: 6,
      textStyle: {
        fontSize: 12,
        padding: [0, 0, 0, -2]
      }
    },
    grid: {
      top: '5%',
      left: '3%',
      right: '3%',
      bottom: weekmode ? '18%' : '12%',
      containLabel: true
    },
    xAxis: [{
      type: 'category',
      boundaryGap: true,
      axisTick: { alignWithLabel: true },
      min: 'dataMin',
      max: 'dataMax',
      axisLabel: {
        showMinLabel: true,
        showMaxLabel: true,
        fontSize: 10,
        rotate: weekmode ? 0 : 45,
        interval: weekmode ? 0 : getInterval,
        formatter: (value) => {
          if (weekmode) {
            const [weekstart, weekend] = value.split(L.to);
            const [startMonth, startDay] = weekstart.split('-');
            const [endMonth] = weekend.split('-');
            if (startMonth !== endMonth || startDay === 1) { // 月初
              return monthNameArray[parseInt(endMonth) - 1];
            } else {
              return ''
            }
          } else {
            return value;
          }
        }
      },
      data: dataXAxis
    }],
    yAxis: [{
      type: 'value',
      interval: 10,
      min: function (value) {
        return Math.floor(value.min / 10) * 10 - 10
      },
      max: function (value) {
        return Math.ceil(value.max / 10) * 10
      },
      axisLabel: {
        show: true
      }
    }],
    series: [{
      name: L.lowest_hr,
      type: 'line',
      lineStyle: { color: getColor('linegray1') },
      itemStyle: { color: getColor('linegray1') },
      symbol: 'circle',
      symbolSize: 5,
      smooth: true,
      emphasis: { disabled: true },
      data: dataHRmin
    }, {
      name: L.lowest_hr_baseline,
      type: 'line',
      stack: 'band',
      silent: true,
      emphasis: { disabled: true },
      itemStyle: { color: getColor('tag'), opacity: 0.5 },
      lineStyle: { opacity: 0 },
      symbol: (value, params) => {
        return getSymbol(value, params, dataLower, 'roundRect');
      },
      smooth: true,
      data: dataLower
    }, {

      name: '基线上限',
      type: 'line',
      stack: 'band',
      silent: true,
      emphasis: { disabled: true },
      itemStyle: { color: getColor('tag'), opacity: 0.5 },
      lineStyle: { opacity: 0 },
      symbol: (value, params) => {
        return getSymbol(value, params, dataUpper, 'roundRect');
      },
      smooth: true,
      areaStyle: { color: getColor('tag'), opacity: 0.8 },
      data: dataUpper
    }, {
      name: L.average_hr,
      type: 'line',
      // lineStyle: { color: getColor('linegray1') },
      itemStyle: { color: '#73c0de' },
      symbol: 'circle',
      // showAllSymbol: true,
      smooth: true,
      emphasis: { disabled: true },
      data: dataHRavg
    }]
  };
  hr_chart.setOption(option);
}
