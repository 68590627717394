<template>
  <div class="device-report-view">
    <!-- 顶部栏 -->
    <el-card class="header-card">
      <div class="header-content">
        <span>文件名称：{{ psgid }}</span>
        <el-button @click="handleClose" type="primary">返回列表页</el-button>
      </div>
    </el-card>
    <!-- 设备详细数据 -->
    <div class="container">
      <div class="snapshot-wrapper">
        <report-view :device="props.psgid" date="" :server="'数据服务器'"/>
      </div>
      <div class="waveform-wrapper">
        <device-waveform :device="props.psgid" date="" mode="psg"></device-waveform>
      </div>
    </div>
  </div>
</template>

<script setup>
import {defineProps} from 'vue';
import {defineEmits} from 'vue'
import ReportView from './ReportView.vue';
import DeviceWaveform from './DeviceWaveform.vue';
// 定义 emit 函数，用于触发事件
const emit = defineEmits(['close'])
const handleClose = () => {
  emit('close')
}
// 接收prop
const props = defineProps({
  psgid: String
});


</script>

<style scoped>
.header-content {
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
}

.container {
  display: flex;
  flex-direction: row; /* 水平布局 */
  width: 100%; /* 确保容器占满整个父元素宽度 */
}

.snapshot-wrapper {
  flex: 0 0 20%; /* flex-grow: 0, flex-shrink: 0, flex-basis: 20% */
  margin: 4px;
  height: 600px;
  /* 这里表示snapshot-wrapper不会随着容器大小的变化而增长或缩小，其基础大小为容器宽度的20% */
}

.waveform-wrapper {
  flex-grow: 1; /* 使用flex-grow: 1，意味着这个元素会占据剩余的空间 */
  /* 这里不需要设置flex-basis，因为默认值是0%，flex-grow会让它自动填满剩余空间 */
  margin: 4px;
}
</style>
