<template>
  <el-dialog v-if="special==='1'" v-model="editDialogVisible" title="编辑设备" width="30%">
    <el-form :model="deviceData" label-position="right" label-width="100px">
      <el-form-item label="床位号">
        <el-input v-model="deviceData.bednumber"
                  style="width: 100%;"
        ></el-input>
      </el-form-item>
      <el-form-item label="备注">
        <el-input v-model="deviceData.info" type="textarea"
                  style="width: 100%;" :autosize="{ minRows: 4, maxRows: 8 }"
        ></el-input>
      </el-form-item>
      <!-- 根据需要添加更多表单项 -->
    </el-form>
    <template #footer>
      <el-button @click="cancel">取消</el-button>
      <el-button type="primary" @click="submitForm">确认</el-button>
    </template>
  </el-dialog>

  <el-dialog v-else v-model="editDialogVisible" title="修改设备信息">
    <el-form :model="deviceData" label-position="right" label-width="100px">
      <el-form-item label="工作状态">
        <el-select v-model="deviceData.workstate" placeholder="请选择" style="width: 100%;">

          <el-option v-for="workstate in  selects['deviceinfo.workstate=状态']" :key="workstate" :label="workstate"
                     :value="workstate"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="机构绑定">
        <el-select v-model="deviceData.orgbind" placeholder="请选择" style="width: 100%;">
          <el-option label="无" value=""></el-option>
          <el-option v-for="org in  selects['deviceinfo.orgbind=机构']" :key="org" :label="org"
                     :value="org"></el-option>
        </el-select>
      </el-form-item>


      <el-form-item label="设备类型">
        <el-select v-model="deviceData.type" placeholder="请选择" style="width: 100%;">
          <el-option label="无" value=""></el-option>
          <el-option v-for="type in  selects['deviceinfo.type=类型']" :key="type" :label="type"
                     :value="type"></el-option>

        </el-select>
      </el-form-item>

      <el-form-item label="设备等级">
        <el-select v-model="deviceData.level" placeholder="请选择" style="width: 100%;">
          <el-option label="无" value=""></el-option>
          <el-option v-for="level in  selects['deviceinfo.level=等级']" :key="level" :label="level"
                     :value="level"></el-option>

        </el-select>
      </el-form-item>

      <el-form-item label="平行设备">
        <el-input v-model="deviceData.psgid" style="width: 100%;"></el-input>
      </el-form-item>
      <el-form-item label="备注">
        <el-input v-model="deviceData.info" type="textarea"
                  style="width: 100%;" :autosize="{ minRows: 4, maxRows: 8 }"
        ></el-input>
      </el-form-item>
      <!-- 根据需要添加更多表单项 -->
    </el-form>
    <template #footer>
      <el-button @click="cancel">取消</el-button>
      <el-button type="primary" @click="submitForm">确认</el-button>
    </template>
  </el-dialog>
</template>

<script setup>
import {ref, defineEmits, defineProps, watch, onMounted} from 'vue';
import {ElMessage} from "element-plus";
import {createAxiosInstance, request_data} from "@/plugins/axios";


const props = defineProps({
  data: Object,
  editDialogVisible: Boolean,
  special: String

});
const emit = defineEmits(['editDialogVisible']);
const editDialogVisible = ref(false);
const deviceData = ref(props.data);
const selects = ref({})

const submitForm = async () => {
  try {

    const params = new URLSearchParams({
      info: deviceData.value.info || '',
      psgid: deviceData.value.psgid || '',
      workstate: deviceData.value.workstate || '',
      orgbind: deviceData.value.orgbind || '',
      type: deviceData.value.type || '',
      level: deviceData.value.level || '',
      bednumber: deviceData.value.bednumber || '',
    }).toString();
    const axiosInstance = createAxiosInstance("数据服务器");

    const response = await axiosInstance(`/updatedevice/${deviceData.value.device}/?${params}`);
    console.log(response)
    emit('update:editDialogVisible', false);
    ElMessage.success('设备信息更新成功');
  } catch (error) {
    console.log(error);
    ElMessage.error('更新失败');
  }
};

// 获取下拉框数据
async function fetchSelects() {
  try {
    const data = await request_data(`/sysfilter/device/`, '数据服务器');

    selects.value = data.selects;
  } catch (error) {
    console.error('Error fetching filters:', error);
  }
}

const cancel = () => {
  emit('update:editDialogVisible', false);
};
onMounted(() => {
  fetchSelects();

});
watch(() => props.data, (newVal) => {
  deviceData.value = newVal;
});

</script>
