import {getColor} from '../utils/utils';
import {locales} from '../utils/locales';

const L = locales['zh-Hans']
const monthNameArray = [L.jan, L.feb, L.mar, L.apr, L.may, L.jun, L.jul, L.aug, L.sep, L.oct, L.nov, L.dec];

import {
  chart_dates, // 图表横轴的日期与报告数据之间的映射关系
  getInterval
} from './variables.js';
export function setSnoreIndexChartOption(reports, snore_chart) {
    if (!snore_chart) {
        return;
    }

    var dataSnore = [];
    var dataXAxis = [];

    //从日期序列转换到图表数据
    var weekmode = false;
    if (Object.keys(reports).length > 0) {
        var total = Object.keys(chart_dates).length;
        // 如果周期太长，以周为单位计算均值
        if (total <= 60) {
            for (var key in chart_dates) {
                let [month, day] = key.split('/');
                let abbrKey = month + '-' + day;
                // var si;

                // if (reports[key] && Object.keys(reports[key]).length > 0) {
                //     console.log(reports[key])
                //     si = (reports[key].bcgsnore_index).toFixed(1)
                // } else {
                //     si = null
                // }
                let si = !isNaN(reports[key].bcgsnore_index) ? Math.round(reports[key].bcgsnore_index) : null;

                dataSnore.push({name: abbrKey, value: si});
                dataXAxis.push(abbrKey);
            }
        } else {
            weekmode = true;
            let [si_avg, count] = [0, 0];
            let valid = false; // 跳过开头的“半周”
            let weekend = '';
            for (let key in chart_dates) {
                let [year, month, day] = key.split('-');
                let d = new Date();
                d.setFullYear(year);
                d.setMonth(month - 1);
                d.setDate(day);
                d.setHours(0, 0, 0, 0);
                let weekday = d.getDay();

                let si = (chart_dates[key] >= 0 && !isNaN(reports[chart_dates[key]].bcgsnore_index)) ? (reports[chart_dates[key]].bcgsnore_index).toFixed(1) : null;
                if (si != null && valid) {
                    si_avg += parseFloat(si);
                    count++;
                }
                if (weekday === 6) { //每周最后一天
                    weekend = month + '-' + day;
                }
                if (weekday === 0) { //每周第一天
                    if (valid) {
                        si_avg = count ? Math.round(si_avg / count) : null;

                        key = month + '-' + day + L.to + weekend;
                        dataSnore.push({name: key, value: si_avg}); //HERE
                        dataXAxis.push(key);
                        [si_avg, count] = [0, 0];
                        weekend = '';
                    } else {
                        valid = true;
                    }
                }
            }
        }
    }
    dataSnore.reverse();
    dataXAxis.reverse();

    var option = {
        animationDuration: 500,
        backgroundColor: 'transparent',
        /*title: {
            text: 'AHI指数',
            padding: [15, 0, 0, 32],
            textStyle: {
                fontWeight: 'normal',
                fontSize: 14
            }
        },*/
        tooltip: {
            trigger: 'axis',
            confine: true,
            order: 'seriesDesc',
            shadowBlur: 4,
            backgroundColor: getColor('background'),
            textStyle: {
                fontSize: 12,
                lineHeight: 9,
                textShadowBlur: 2,
                textShadowColor: 'transparent',
                color: getColor('text'),
            },
            axisPointer: {
                type: 'line',
                label: {
                    show: true,
                    backgroundColor: '#6a7985'
                }
            },
            valueFormatter: (value) => value ? Math.round(value) : '-',
        },
        grid: {
            top: '5%',
            left: '3%',
            right: '3%',
            bottom: '0%',
            containLabel: true
        },
        xAxis: [{
            type: 'category',
            boundaryGap: true,
            axisTick: {alignWithLabel: true},
            axisLabel: {
                showMinLabel: true,
                showMaxLabel: true,
                fontSize: 10,
                rotate: weekmode ? 0 : 45,
                interval: weekmode ? 0 : getInterval,
                formatter: (value) => {
                    if (weekmode) {
                        let [weekstart, weekend] = value.split(L.to);
                        let [startMonth, startDay] = weekstart.split('-');
                        let [endMonth] = weekend.split('-');
                        if (startMonth != endMonth || startDay == 1) { // 月初
                            return monthNameArray[parseInt(endMonth) - 1];
                        } else {
                            return ''
                        }
                    } else {
                        return value;
                    }
                }
            },
            data: dataXAxis
        }],
        yAxis: {
            type: 'value',
            min: 0,
            minInterval: 5,
            axisLabel: {
                show: true,
            }
        },
        series: [{
            name: L.snore_index,
            type: 'line',
            itemStyle: {color: '#73c0de'},
            lineStyle: {color: '#c8c9cc'},
            //symbol: weekmode ? 'emptyCircle' : (value, params)=>{ return getSymbol(value, params, dataAHI); },
            symbol: 'circle',
            symbolSize: 5,
            //showAllSymbol: 'true',
            smooth: true,
            data: dataSnore,
        }]
    };
    snore_chart.setOption(option);
    //console.log("AHI chart optionized with: " + reports.length + " reports");
}

