import { useStore } from 'vuex';
import { useRouter } from 'vue-router';

// useJumpData.js
export function useJumpData () {
  const store = useStore();
  const router = useRouter();

  const checkJumpData = () => {
    const jump_data = store.state.jumpData;
    const path = jump_data.path;
    if (router.currentRoute.value.path === path) {
      const filter_type = jump_data.filter_type;
      const value = jump_data.value;
      console.log(jump_data, filter_type);
      let encodedFilterStr = '';
      if (value) {
        if (filter_type === 'input') {
          encodedFilterStr = 'search:*' + value + '|';
        } else {
          encodedFilterStr = value;
        }
        // 使用 encodedFilterStr
        console.log('Encoded Filter String:', encodedFilterStr);
        // 假设我们需要将其存储或进一步处理
      }
    } else {
      store.commit('setJumpData', '');
    }
  };

  return { checkJumpData };
}
