import { getColor, formatDateString } from '../utils/utils';

export function getSymbol (value, params, data) { // 单点数据需要显示symbol，能连成线则不显示
  const left = params.dataIndex - 1;
  const right = params.dataIndex + 1;
  let symbol = 'none';
  if (left >= 0 && right < data.length) { // 中间单点
    if (data[left][3] == null && data[right][3] == null) {
      symbol = 'circle';
    }
  } else if (right < data.length) { // 最左边缘
    if (data[right][3] == null) symbol = 'circle';
  } else if (left >= 0) { // 最右边缘
    if (data[left][3] == null) symbol = 'circle';
  }
  return symbol;
}

export function setDayEnvChartOption (env_chart, env_data) {
  if (!env_chart || !env_data) {
    return;
  }

  const data = [];
  const dataMask = [];
  if (env_data && env_data.segments) {
    const offset = env_data.start;
    const end = env_data.end;
    let lastend = 0;
    if (env_data.segments[0].time > 0) {
      data.push([offset, null, null, null]);
    }
    for (let i = 0; i < env_data.segments.length; i++) {
      // data.push([offset + env_data.segments[i].time, 0, 0, 0]);
      data.push([offset + env_data.segments[i].time,
        env_data.segments[i].t < 0 ? null : env_data.segments[i].t,
        env_data.segments[i].h < 0 ? null : env_data.segments[i].h,
        env_data.segments[i].i < 0 ? null : env_data.segments[i].i
      ]);
      lastend = offset + env_data.segments[i].time;
    }
    if (lastend < end) {
      data.push([end, null, null, null]);
    }

    for (let i = 0; i < env_data.nointention?.length; i++) {
      const seg = env_data.nointention[i];
      if (seg.e - seg.s > 60) { dataMask.push([{ name: '活动', xAxis: env_data.start + seg.s }, { xAxis: env_data.start + seg.e }]); }
    }
    for (let i = 0; i < env_data.leavebed?.length; i++) {
      const seg = env_data.leavebed[i];
      if (seg.e - seg.s > 60) { dataMask.push([{ name: '离床', xAxis: env_data.start + seg.s }, { xAxis: env_data.start + seg.e }]); }
    }
  }

  const colors = ['#F4912E', '#3C8DD0', '#3CB043'];
  const option = {
    backgroundColor: 'transparent',
    grid: {
      left: '2%',
      right: '3%',
      top: '16%',
      bottom: '3%',
      containLabel: true
    },
    xAxis: {
      min: 'dataMin',
      max: 'dataMax',
      minInterval: 120 * 60,
      maxInterval: 120 * 60,
      axisLabel: {
        showMinLabel: true,
        showMaxLabel: true,
        formatter: function (val) {
          const d = new Date(val * 1000);
          return d.getHours() + ':' + d.getMinutes().toString().padStart(2, '0');
        }
      },
      splitLine: {
        lineStyle: { type: 'dashed' }
      }
    },
    yAxis: [{
      name: '温度',
      type: 'value',
      position: 'right',
      alignTicks: true,
      nameGap: 4,
      axisLine: {
        show: true,
        lineStyle: { color: colors[0] }
      },
      axisLabel: { formatter: '{value}°C' }
      // min: function (value) { return Math.floor(value.min/10)*10 },
      // max: function (value) { return Math.ceil(value.max/10)*10 },
    }, {
      name: '湿度',
      type: 'value',
      position: 'right',
      alignTicks: true,
      offset: 40,
      nameGap: 4,
      axisLine: {
        show: true,
        lineStyle: { color: colors[1] }
      },
      axisLabel: { formatter: '{value}%' }
    }, {
      name: '光照',
      type: 'value',
      position: 'left',
      alignTicks: true,
      nameGap: 4,
      max: function (value) { return value.max > 5 ? (Math.ceil(value.max / 5)) * 5 : 5; },
      min: 0,
      minInterval: 1,
      axisLine: {
        show: true,
        lineStyle: { color: colors[2] }
      },
      // axisLabel: { formatter: '{value} lx' }
      axisLabel: {
        formatter: function (val) {
          return Math.round(val) + ' lx';
        }
      }
    }],
    tooltip: {
      trigger: 'axis',
      confine: true,
      shadowBlur: 4,
      backgroundColor: getColor('background'),
      textStyle: {
        fontSize: 12,
        lineHeight: 9,
        textShadowBlur: 2,
        textShadowColor: 'transparent',
        color: getColor('text')
      },
      formatter: function (params) {
        return formatDateString(params[0].value[0] * 1000, 'HHmm') + '\n' +
                    params[0].marker + params[0].seriesName + '  ' + (params[0].value[1] !== null ? Math.round(params[0].value[1]) + '°C' : '无') + '\n' +
                    params[1].marker + params[1].seriesName + '  ' + (params[1].value[2] !== null ? Math.round(params[1].value[2]) + '%' : '无') + '\n' +
                    params[2].marker + params[2].seriesName + '  ' + (params[2].value[3] !== null ? Math.round(params[2].value[3]) + 'lx' : '无');
      }
    },
    series: [{
      name: '温度',
      type: 'line',
      data,
      itemStyle: { color: colors[0] },
      yAxisIndex: 0,
      encode: {
        x: 0,
        y: 1
      },
      symbol: function (value, params) { return getSymbol(value, params, data) },
      markArea: {
        silent: false,
        label: {
          show: false
        },
        itemStyle: {
          color: '#CCC',
          opacity: 0.4
        },
        emphasis: {
          label: {
            show: true,
            position: 'right',
            color: getColor('text')
          }
        },
        data: dataMask
      }
    }, {
      name: '湿度',
      type: 'line',
      data,
      itemStyle: { color: colors[1] },
      yAxisIndex: 1,
      encode: {
        x: 0,
        y: 2
      },
      symbol: function (value, params) { return getSymbol(value, params, data) }
    }, {
      name: '光照',
      type: 'line',
      data,
      itemStyle: { color: colors[2] },
      yAxisIndex: 2,
      encode: {
        x: 0,
        y: 3
      },
      symbol: function (value, params) { return getSymbol(value, params, data) }
    }]
  };
  env_chart.setOption(option);
}
