<template>
 Report history
  <div class="image-grid">
    <div  v-for="(value, key) in reports" :key="key" class="image-item">
      <!-- 条件渲染，检查 value.report 是否等于 'ahireport' -->
      <el-text>{{key}}</el-text>
      <div class="card-content">
        <AlgorithmReport v-if="value.report === 'ahireport'" :data="value"/>
        <TreeViewObj v-else :data="value"/>

      </div>
    </div>
    <!-- 用伪元素填充剩余空间 -->
    <div class="image-item empty" v-for="n in emptySlots" :key="'empty' + n"></div>
  </div>
</template>

<script setup>
import {ref, defineProps, onMounted, watch} from 'vue';
import TreeViewObj from "@/common/TreeViewObj.vue";
import {request_data} from '@/plugins/axios.js';
import AlgorithmReport from "@/components/AlgorithmReport.vue";
// 接收prop
const props = defineProps({
  device: String,
  date: String,
  server: {
    type: String,
    default: '计算服务器1'
  }
});

const reports = ref({});

async function getReports() {
  if (!props.device) return
  const reportdate = props.date ? props.date : "_";
  reports.value = await request_data(`/reportall/${props.device}/${reportdate}/`,props.server);
}

console.log("ReportHistory.vue")
onMounted(() => {

  getReports()
});
watch([() => props.device, () => props.date], async ([newDevice, newDate], [oldDevice, oldDate]) => {
  console.log(oldDate, oldDevice, newDevice, newDate)
  getReports()
});
</script>
<style scoped>
.image-grid {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  justify-content: flex-start; /* 确保对齐方式 */
}

.image-item {
  flex: 1 0 18%; /* 将图片宽度调整为18% */
  margin-bottom: 10px;
  text-align: center;
  box-sizing: border-box; /* 确保图片边框和内边距不影响总宽度 */
}

.image-item img {
  width: 100%;
  height: auto;
  display: block;
}

.image-item.empty {
  visibility: hidden; /* 隐藏空白槽 */
}
</style>
