<template>
  <div v-if="isAdmin">
    <!--    <el-tooltip placement="bottom">-->
    <!--       <template #content>-->
    <!--                <div class="tooltip-content">-->
    <!--                  <TreeViewObj :data="systemDetails"></TreeViewObj>-->
    <!--                </div>-->
    <!--       </template>-->
    <!--      <span :style="{ color: systemStatusColor }">{{ systemStatusText }}</span>-->
    <!--    </el-tooltip>-->
    <span :style="{ color: systemStatusColor }">{{ systemStatusText }}</span>

  </div>
</template>

<script setup>
import { computed, onMounted, onUnmounted, ref } from 'vue';
// import {ElTooltip} from 'element-plus';
import { store } from '@/plugins/store';
// import TreeViewObj from "@/common/TreeViewObj.vue";
// 示例系统状态和详情
const user = computed(() => store.state.user)
const isAdmin = computed(() => user.value && user.value.role === '管理')

// const systemDetails = computed(() => {
//   const info = {}
//   const now = new Date().getTime()
//   Object.assign(info, store.state.systemState);
//   if (store.state.net_err_time + 10000 > now)
//     info["网络异常"] = store.state.net_error
//   return info
// });

function check_status () {
  const state = store.state;
  const now = new Date().getTime()
  if (state.net_err_time + 10000 > now) normal.value = 0;
  else if (now / 1000 - state.systemState.refresh_device > 10) normal.value = 0
  else normal.value = 1;
}

const normal = ref(1)

const systemStatusText = computed(() => {
  return normal.value === 1 ? '系统：正常' : '系统：异常';
});

const systemStatusColor = computed(() => {
  return normal.value === 1 ? 'white' : 'red';
});

let intervalId = null; // 用于存储定时器ID
onMounted(() => {
  intervalId = setInterval(check_status, 1000); // 每5秒刷新一次用户信息
})

onUnmounted(() => {
  if (intervalId) clearInterval(intervalId); // 组件销毁时清除定时器
})
</script>

<style scoped>
/* 这里可以添加一些额外的 CSS 样式 */
</style>
