import * as echarts from 'echarts';

import { getColor, formatDateString } from '../utils/utils';

const catColor = ['#36349d', '#3478f6', '#59aae0', '#fe8973', '#CCC', '#8E377A'];

export function renderItem (params, api) {
  let curveShape = {
    type: 'line'
  };
  let rectShape = {
    type: 'rect'
  };
  let markerShape = {
    type: 'polygon'
  };

  const categoryIndex = api.value(0);
  const nextCategoryIndex = api.value(3);
  if (categoryIndex >= 0) {
    const start = api.coord([api.value(1), (categoryIndex > 3) ? 3 : categoryIndex]);
    const end = api.coord([api.value(2), (categoryIndex > 3) ? 3 : categoryIndex]);
    const start2 = nextCategoryIndex !== -1 ? api.coord([api.value(1), nextCategoryIndex]) : start;
    const end2 = nextCategoryIndex !== -1 ? api.coord([api.value(2), nextCategoryIndex]) : end;
    let height = api.size([0, 1])[1] * 0.9;

    const cond1 = (start[1] > start2[1]);
    const cond2 = (start[1] >= start2[1]);
    const x1 = end[0];
    const y1 = start[1] + (cond1 ? -height * 1 / 3 : height * 2 / 3);
    const x2 = end2[0];
    const y2 = start2[1] + (cond2 ? height * 2 / 3 : -height / 3);

    var thisColor = catColor[categoryIndex];
    const nextColor = catColor[nextCategoryIndex];

    // var yOffset = params.coordSys.height * 0.15;
    // var yRatio = 0.5;
    const yOffset = 0;
    const yRatio = 1;

    rectShape = {
      x: start[0] - 0.5, // offset半个像素才能与直线对齐，dont know why..
      y: (start[1] - height / 3) * yRatio - yOffset,
      width: end[0] - start[0] + 1,
      height: height * yRatio
    };
    if (categoryIndex === 4) { // 卧床无意图
      rectShape = {
        x: start[0],
        y: (start[1] - height / 3) * yRatio - yOffset,
        width: end[0] - start[0],
        height: height * yRatio
      };
    }
    if (categoryIndex === 5) { // 离床
      height = api.size([0, 1])[1];
      rectShape = {
        x: start[0],
        y: (start[1] - height / 2) * yRatio - yOffset,
        width: end[0] - start[0],
        height: height * yRatio
      };

      if (rectShape.width < 5) {
        const mWidth = 7;
        const mHeight = 6;
        markerShape = {
          type: 'polygon',
          shape: {
            points: [[start[0] + rectShape.width / 2 - mWidth / 2, start[1] - height / 2 - mHeight / 2], [start[0] + rectShape.width / 2 + mWidth / 2, start[1] - height / 2 - mHeight / 2], [start[0] + rectShape.width / 2, start[1] - height / 2 + mHeight / 2]]
          },
          style: {
            fill: thisColor,
            opacity: 0.8
          },
          emphasis: {
            style: {
              stroke: thisColor,
              lineWidth: 3
            }
          },
          z: 20
        }
      }
    }

    // 下一分段有效，则画连接线
    if (!(categoryIndex >= 3 && nextCategoryIndex >= 3) && nextCategoryIndex >= 0) {
      curveShape = {
        type: 'line',
        silent: true,
        shape: {
          x1,
          y1: (y1 + (cond1 ? 3 : -3)) * yRatio - yOffset,
          x2,
          y2: (y2 + (cond1 ? -3 : 3)) * yRatio - yOffset
        },
        style: {
          stroke: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
            offset: 0, color: cond1 ? nextColor : thisColor
          }, {
            offset: 1, color: cond1 ? thisColor : nextColor
          }]),
          lineWidth: 1,
          opacity: 0.7
        }
      };
    }
  }

  const radius = (rectShape.width > 2) ? 3 : 0;
  return ({
    type: 'group',
    children: [
      curveShape,
      markerShape,
      rectShape && {
        type: 'rect',
        silent: false, // (categoryIndex>3),
        transition: ['shape'],
        shape: {
          ...rectShape,
          r: radius
        },
        style: {
          ...api.style(),
          fill: thisColor,
          opacity: 1
        },
        // z: 2,
        emphasis: {
          style: {
            stroke: thisColor,
            lineWidth: 2
            // shadowBlur: 2,
          }
        }
      }
    ]
  });
}

// 睡眠分期合并：5分期合并为4分期
export function mergeSleepStage (stage) {
  switch (stage) {
    case -1: return -1; // 无效的数据段
    case 1: return 3;
    case 2: return 2;
    case 3: return 1;
    case 4: return 1;
    case 5: return 0;
    default:
      return 3;
  }
}

export function setStageAH2ChartOption (stage_chart, stage_data) {
  if (!stage_data || !stage_data.stages) {
    console.log('Empty stage data, set chart fail.')
    return;
  }
  if (!stage_chart) { // 图表组件尚未初始化
    return;
  }

  const data = [];
  const dataAH = [];
  const categories = ['深睡眠', '浅睡眠', '快速眼动睡眠', '清醒'];

  const baseTime = stage_data.start;
  // 从睡眠分期数组读出绘图数据
  for (let i = 0; i < stage_data.stages.length; i++) {
    // 5分期合并为4分期
    const stg = mergeSleepStage(stage_data.stages[i].value);

    data.push({
      name: stg >= 0 ? categories[stg] : '',
      value: [stg, baseTime * 1000 + stage_data.stages[i].start * 1000, baseTime * 1000 + stage_data.stages[i].end * 1000, -1],
      itemStyle: { color: stg >= 0 ? catColor[stg] : '#FFF' }
    });
  }

  // 分期中的相邻碎片合并
  for (let i = 1; i < data.length; i++) {
    if (data[i - 1].value[0] === data[i].value[0]) {
      data[i - 1].value[2] = data[i].value[2];
      data.splice(i, 1);
      i--;
    }
  }
  // 对离床和非意图段也做一遍碎片合并检查
  for (let i = 0; i < stage_data.nointention?.length; i++) {
    stage_data.nointention[i].s = Math.round(stage_data.nointention[i].s);
    stage_data.nointention[i].e = Math.round(stage_data.nointention[i].e);
  }
  for (let i = 1; i < stage_data.nointention?.length; i++) {
    if ((stage_data.nointention[i - 1].e === stage_data.nointention[i].s) || (stage_data.nointention[i - 1].e + 1 === stage_data.nointention[i].s)) {
      stage_data.nointention[i - 1].e = stage_data.nointention[i].e;
      stage_data.nointention.splice(i, 1);
      i--;
    }
  }
  for (let i = 1; i < stage_data.leavebed?.length; i++) {
    if ((stage_data.leavebed[i - 1].e === stage_data.leavebed[i].s) || ((stage_data.leavebed[i - 1].e + 1 === stage_data.leavebed[i].s))) {
      stage_data.leavebed[i - 1].e = stage_data.leavebed[i].e;
      stage_data.leavebed.splice(i, 1);
      i--;
    }
  }

  // 用离床和非意图时间把wake分期拆开
  for (let i = 0; i < data.length; i++) {
    if (data[i].value[0] === 3) {
      for (let j = 0; j < stage_data.nointention?.length; j++) {
        const nointent = stage_data.nointention[j];
        const oldname = data[i].name;
        const oldstart = data[i].value[1] / 1000;
        const oldend = data[i].value[2] / 1000;
        const oldstyle = data[i].itemStyle;
        if (baseTime + nointent.s >= oldstart && baseTime + nointent.e <= oldend) { // 如果当前wake分期与意图有重叠
          let idx = i;
          if (baseTime + nointent.s > oldstart) { // 如果有，拆分第一个wake段，并替换掉原来的wake段
            data.splice(i, 1, {
              name: oldname,
              value: [3, oldstart * 1000, baseTime * 1000 + nointent.s * 1000, -1],
              itemStyle: oldstyle
            })
            idx = i + 1;
          } else { // 否则要删掉原来的wake段
            data.splice(i, 1);
          }
          // 插入一个非意图段
          data.splice(idx, 0, {
            name: '活动',
            value: [4, baseTime * 1000 + nointent.s * 1000, baseTime * 1000 + nointent.e * 1000, -1],
            itemStyle: { color: catColor[4] }
          })
          if (baseTime + nointent.e < oldend) { // 如果有，拆分后一个wake段
            data.splice(idx + 1, 0, {
              name: oldname,
              value: [3, baseTime * 1000 + nointent.e * 1000, oldend * 1000, -1],
              itemStyle: oldstyle
            })
          }
        }
      }
    }
  }
  for (let i = 0; i < data.length; i++) {
    if (data[i].value[0] === 3) {
      for (let j = 0; j < stage_data.leavebed?.length; j++) {
        const leave = stage_data.leavebed[j];
        const oldname = data[i].name;
        const oldstart = data[i].value[1] / 1000;
        const oldend = data[i].value[2] / 1000;
        const oldstyle = data[i].itemStyle;
        if (baseTime + leave.s >= oldstart && baseTime + leave.e <= oldend) { // 如果当前wake分期与意图有重叠
          let idx = i;
          if (baseTime + leave.s > oldstart) { // 如果有，拆分第一个wake段，并替换掉原来的wake段
            data.splice(i, 1, {
              name: oldname,
              value: [3, oldstart * 1000, baseTime * 1000 + leave.s * 1000, -1],
              itemStyle: oldstyle
            })
            idx = i + 1;
          } else { // 否则要删掉原来的wake段
            data.splice(i, 1);
          }
          // 插入一个离床段
          data.splice(idx, 0, {
            name: '离床',
            value: [5, baseTime * 1000 + leave.s * 1000, baseTime * 1000 + leave.e * 1000, -1],
            itemStyle: { color: catColor[5] }
          })
          if (baseTime + leave.e < oldend) { // 如果有，拆分后一个wake段
            data.splice(idx + 1, 0, {
              name: oldname,
              value: [3, baseTime * 1000 + leave.e * 1000, oldend * 1000, -1],
              itemStyle: oldstyle
            })
          }
        }
      }
    }
  }

  // 在每一段的数据中记住下一个段的分期值
  let firstLegend = null; // 记住第一个出现的需要legend的类型，画legend按出现的顺序来排
  for (let i = 0; i < data.length - 1; i++) {
    if (data[i].value[0] === 4 && !firstLegend) {
      firstLegend = 'intent';
    }
    if (data[i].value[0] === 5 && !firstLegend) {
      firstLegend = 'leave';
    }
    if (data[i + 1].value[1] === data[i].value[2]) {
      data[i].value[3] = data[i + 1].value[0];
    } else {
      data[i].value[3] = -1;
    }
  }
  // console.log(data);

  const sleepStart = stage_data.start * 1000;
  const sleepEnd = stage_data.end * 1000;

  if (stage_data.ah && stage_data.ah.length > 0) {
    const offset = stage_data.start;
    const end = stage_data.end;
    let lastend = 0;
    for (let i = 0; i < stage_data.ah.length; i++) {
      if (i === 0 && stage_data.ah[i].s !== 0) { // 用于x轴开头的对齐
        dataAH.push([1000 * offset, 1000 * (offset + 60), 0]);
      }
      const v = stage_data.ah[i].v;
      const s = 1000 * (offset + stage_data.ah[i].s);
      const e = 1000 * (offset + stage_data.ah[i].e);
      dataAH.push([s, e, v]);
      lastend = offset + stage_data.ah[i].e;
    }
    if (offset + stage_data.ah[stage_data.ah.length - 1].e < end) { // 用于x轴结尾的对齐
      dataAH.push([1000 * lastend, 1000 * end, 0]);
    }
  } else {
    dataAH.push([sleepStart, sleepStart + 60 * 1000, 0]);
    dataAH.push([sleepEnd - 60 * 1000, sleepEnd, 0]);
  }
  // console.log(data, dataAH);

  const option = {
    backgroundColor: 'transparent',
    tooltip: {
      trigger: 'item',
      backgroundColor: getColor('background'),
      borderColor: 'transparent',
      order: 'seriesDesc',
      textStyle: {
        fontSize: 12,
        lineHeight: 9,
        textShadowBlur: 2,
        textShadowColor: 'transparent',
        color: getColor('text')
      },
      confine: true,
      shadowBlur: 4,
      axisPointer: {
        type: 'line',
        axis: 'x',
        // snap: false,
        label: {
          show: true,
          backgroundColor: '#6a7985',
          formatter: (params) => formatDateString(params.value, 'HHmm')
        }
      },
      formatter: function (params) {
        /* if (params[0].seriesIndex==0) {
                    return params[0].marker + params[0].name + ': ' + formatDateString(params[0].value[1], "HHmm") + ' - ' + formatDateString(params[0].value[2], "HHmm");
                } else {
                    return formatDateString(params[0].value[0], "HHmm") + ' ' + params[0].value[2] + '次';
                } */
        if (params.seriesIndex === 0) {
          return params.marker + params.name + ': ' + formatDateString(params.value[1], 'HHmm') + ' - ' + formatDateString(params.value[2], 'HHmm');
        } else {
          return formatDateString(params.value[0], 'HHmm') + ' ' + params.value[2] + '次';
        }
      }
    },
    title: {
      text: '整晚呼吸暂停低通气事件',
      padding: [2, 0, 0, 24],
      textStyle: {
        fontWeight: 'normal',
        fontSize: 12
      }
    },
    grid: [{
      left: 24,
      right: '7%',
      top: 18,
      height: '35%'
      // containLabel: true
    }, {
      left: 24,
      right: '7%',
      bottom: 24,
      height: '35%'
      // containLabel: true
    }],
    axisPointer: { link: [{ xAxisIndex: 'all' }] },
    xAxis: [{
      min: 'dataMin',
      max: 'dataMax',
      minInterval: 120 * 60000,
      maxInterval: 120 * 60000,
      splitLine: {
        show: true,
        lineStyle: { type: 'dashed' }
      },
      axisLabel: {
        show: false
      },
      axisPointer: {
        triggerEmphasis: false,
        snap: false,
        label: {
          show: false
        }
      }
    }, {
      gridIndex: 1,
      boundaryGap: false,
      min: 'dataMin',
      max: 'dataMax',
      minInterval: 120 * 60000,
      maxInterval: 120 * 60000,
      splitLine: {
        lineStyle: { type: 'dashed' }
      },
      axisPointer: {
        snap: false,
        triggerEmphasis: false
      },
      axisLabel: {
        showMinLabel: true,
        showMaxLabel: true,
        itemStyle: {
          opacity: 0.2
        },
        formatter: function (val) {
          const d = new Date(val);
          const tag = d.getHours() + ':' + d.getMinutes().toString().padStart(2, '0');
          // if (val % 3600000 != 0)
          if (val === sleepStart || val === sleepEnd) {
            return '{endtag|' + tag + '}';
          } else { return '{all|' + tag + '}'; }
        },
        rich: {
          all: {
            padding: 2
          },
          endtag: {
            color: getColor('text'),
            backgroundColor: getColor('tag'),
            padding: 2,
            borderRadius: 2
          }
        }
      }
    }],
    yAxis: [{
      gridIndex: 1,
      type: 'value',
      posisiton: 'left',
      min: -2,
      max: 2,
      show: false,
      axisLabel: { show: false }
      /* max: (value)=>{
                return Math.ceil(value.max / 5) * 5;
            } */
    }, {
      gridIndex: 0,
      data: categories,
      // axisLine: { show: false },
      axisTick: {
        show: true,
        inside: false
      },
      axisLabel: {
        show: false,
        inside: true,
        fontSize: 4,
        // padding: [-15, 0, 10, 0],
        verticalAlign: 'top'
      },
      z: 10,
      posisiton: 'right'
    }],
    dataZoom: {
      type: 'inside',
      start: 0,
      end: 100,
      filterMode: 'weakFilter', // empty会exception
      minSpan: 40,
      maxSpan: 100,
      zoomLock: false,
      orient: 'horizontal',
      xAxisIndex: [0, 1]
    },
    series: [{
      xAxisIndex: 0,
      type: 'custom',
      clip: false,
      // silent: true,
      renderItem,
      itemStyle: {
        borderWidth: 0,
        opacity: 0.9,
        borderColor: '#dddddd'
      },
      dimensions: ['分期', '开始', '结束'],
      encode: {
        x: [1, 2],
        y: 0
        // tooltip: [1, 2]
      },
      yAxisIndex: 1,
      data
    }, {
      xAxisIndex: 1,
      yAxisIndex: 0,
      type: 'custom',
      name: '呼吸事件',
      renderItem: function (params, api) {
        const start = api.coord([api.value(0), 0]);
        // var end = api.coord([api.value(0) + (api.value(1)-api.value(0)) * api.value(2)/ (1+api.value(2)), api.value(2)]);
        const end = api.coord([api.value(1), api.value(2)]);
        const height = api.size([0, 1])[1] * api.value(2);
        return {
          type: 'rect',
          shape: {
            x: start[0],
            y: start[1] - height / 2,
            width: end[0] - start[0],
            height,
            r: 2
          },
          style: {
            ...api.style(),
            fill: '#ee7624'
          },
          emphasis: {
            style: {
              stroke: '#ee7624',
              lineWidth: 2
            }
          }
        }
      },
      encode: {
        x: [0, 1],
        y: 2
      },
      data: dataAH
    }]
  };
  stage_chart.setOption(option);
}
