import { getColor, formatDateString } from '../utils/utils.js';

export function setDaySnoreChartOption (snore_chart, snore_data) {
  if (!snore_chart || !snore_data) { // 尚未初始化
    return;
  } const data = [];

  if (snore_data.snore) {
    const offset = snore_data.start;
    const end = snore_data.end;
    let lastend = 0;
    if (snore_data.snore[0].s > 0) {
      data.push([offset, offset + snore_data.snore[0].s - 1, 0]);
    }
    for (let i = 0; i < snore_data.snore.length; i++) {
      data.push([offset + snore_data.snore[i].s, offset + snore_data.snore[i].e, snore_data.snore[i].v]);
      lastend = offset + snore_data.snore[i].e;
    }
    if (snore_data.snore.length > 0) {
      if (offset + snore_data.snore[snore_data.snore.length - 1].e < end) {
        data.push([lastend, end, 0]);
      }
    } else {
      data.push([offset, end, 0]);
    }
  }

  const option = {
    backgroundColor: 'transparent',
    title: {
      text: '整晚鼾声事件',
      padding: [4, 0, 0, 24],
      textStyle: {
        fontWeight: 'normal',
        fontSize: 12
      }
    },
    grid: {
      left: 24,
      right: '7%',
      top: 14,
      bottom: 4,
      containLabel: true
    },
    xAxis: {
      min: 'dataMin',
      max: 'dataMax',
      minInterval: 120 * 60,
      maxInterval: 120 * 60,
      offset: -5,
      axisLabel: {
        showMinLabel: true,
        showMaxLabel: true,
        formatter: function (val) {
          const d = new Date(val * 1000);
          return d.getHours() + ':' + d.getMinutes().toString().padStart(2, '0');
        }
      },
      splitLine: {
        lineStyle: { type: 'dashed' }
      }
    },
    yAxis: {
      type: 'value',
      min: (value) => { if (value.max) { return -value.max * 2 / 3; } else { return -2; } },
      max: (value) => { if (value.max) { return value.max * 2 / 3; } else { return 2; } },
      show: false,
      axisLabel: { show: false }
    },
    tooltip: {
      confine: true,
      shadowBlur: 4,
      backgroundColor: getColor('background'),
      borderColor: 'transparent',
      textStyle: {
        fontSize: 12,
        lineHeight: 9,
        textShadowBlur: 2,
        textShadowColor: 'transparent',
        color: getColor('text')
      },
      formatter: function (params) {
        if (params.seriesIndex === 0) {
          let t = params.value[2] + '次';
          if (params.value[2] === 1) {
            t = '1次';
          } else if (params.value[2] === 2) {
            t = '2次';
          }
          return formatDateString(params.value[0] * 1000, 'HHmm') + ' ' + params.seriesName + ' ' + t;
        } else if (params.seriesIndex === 1) {
          return params.seriesName + ' ' + formatDateString(params.value[0] * 1000, 'HHmm') + ' - ' + formatDateString(params.value[1] * 1000, 'HHmm');
        }
      }
    },
    series: [
      {
        type: 'custom',
        name: '鼾声',
        renderItem: function (params, api) {
          const start = api.coord([api.value(0), 0]);
          // var end = api.coord([api.value(0) + (api.value(1)-api.value(0)) * api.value(2)/ (1+api.value(2)), api.value(2)]);
          const end = api.coord([api.value(1), api.value(2)]);
          const height = api.size([0, 1])[1] * api.value(2);
          return {
            type: 'rect',
            shape: {
              x: start[0],
              y: end[1] + height / 2,
              width: end[0] - start[0],
              height,
              r: 2
            },
            style: {
              ...api.style(),
              fill: '#ee7624',
              opacity: 1
            },
            emphasis: {
              style: {
                stroke: '#ee7624',
                opacity: 1,
                lineWidth: 2
              }
            }
          }
        },
        encode: {
          x: [0, 1],
          y: 2
        },
        data
      }
    ]
  };

  snore_chart.setOption(option);
}
