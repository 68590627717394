import { getColor, ledColor } from '../utils/utils.js';
// var {
//     chart_dates, //图表横轴的日期与报告数据之间的映射关系
// } = require('./variables.js');

function getSymbol (value, params, data) { // 单点数据需要显示symbol，能连成线则不显示
  const left = params.dataIndex - 1;
  const right = params.dataIndex + 1;
  let symbol = 'none';
  if (left >= 0 && right < data.length) { // 中间单点
    if (data[left].value == null && data[right].value == null) {
      symbol = 'circle';
    }
  } else if (right < data.length) { // 最左边缘
    if (data[right].value == null) symbol = 'circle';
  } else if (left >= 0) { // 最右边缘
    if (data[left].value == null) symbol = 'circle';
  }
  return symbol;
}

export function setRRBaselineChartOption (reports, rr_chart, chart_dates) {
  if (!rr_chart) {
    return;
  }
  const dataRRavg = [];
  const dataUpper = [];
  const dataLower = [];
  const dataXAxis = [];

  // 从日期序列转换到图表数据
  if (Object.keys(reports).length > 0) {
    for (const key in chart_dates) {
      var avg, upper, lower;

      if (reports[key] && Object.keys(reports[key]).length > 0) {
        avg = Math.round(reports[key].avg_resp * 10) / 10;
        upper = Math.round(reports[key].resp_limit_max * 10) / 10;
        lower = Math.round(reports[key].resp_limit_min * 10) / 10;
      } else {
        avg = null;
        upper = null;
        lower = null;
      }

      let minColor = ledColor[0]; // '#9dce61';
      if (avg > upper || avg < lower) {
        minColor = ledColor[1];
      }
      dataRRavg.push({ name: key, value: avg, itemStyle: { color: minColor } });
      dataUpper.push({ name: key, value: upper ? (upper - lower) : null });
      dataLower.push({ name: key, value: lower });
      dataXAxis.push(key);
    }
  }
  dataRRavg.reverse();
  dataUpper.reverse();
  dataLower.reverse();
  dataXAxis.reverse();

  const endPos = dataXAxis.length - 1;
  const startPos = (endPos - 29) >= 0 ? (endPos - 29) : 0;

  const screenWidth = 0;

  const option = {
    backgroundColor: 'transparent',
    animationDuration: 500,
    title: {
      text: '近期趋势',
      padding: [0, 0, 0, 32],
      textStyle: {
        fontWeight: 'normal',
        fontSize: 12
      }
    },
    tooltip: {
      trigger: 'axis',
      // triggerOn: 'click',
      confine: true,
      order: 'seriesAsc',
      shadowBlur: 4,
      backgroundColor: getColor('background'),
      textStyle: {
        fontSize: 12,
        lineHeight: 9,
        textShadowBlur: 2,
        textShadowColor: 'transparent',
        color: getColor('text')
      },
      axisPointer: {
        type: 'line',
        label: {
          show: true,
          backgroundColor: '#6a7985'
        }
      },
      // valueFormatter: (value) => value ? value + '次/分' : '-',
      formatter: (params) => {
        return params[0].name + '\n' +
                    params[0].marker + params[0].seriesName + '    ' + (params[0].value ? params[0].value + '次/分' : '无') + '\n' +
                    params[1].marker + params[1].seriesName + '    ' + (params[1].value ? (params[1].value + '-' + (params[1].value + params[2].value) + '次/分') : '无');
      }
    },
    legend: {
      data: ['个人基线区间', '超出基线区间'],
      orient: 'horizontal',
      bottom: 1,
      selectedMode: false,
      icon: 'circle',
      itemHeight: screenWidth <= 320 ? 10 : 12,
      itemWidth: 14,
      textStyle: {
        fontSize: screenWidth <= 320 ? 10 : 12
      }
    },
    grid: {
      top: '16%',
      left: '3%',
      right: '3%',
      bottom: '12%',
      containLabel: true
    },
    xAxis: [{
      type: 'category',
      boundaryGap: false,
      min: 'dataMin',
      max: 'dataMax',
      axisLabel: {
        showMinLabel: true,
        showMaxLabel: true,
        fontSize: 10,
        rotate: 45,
        interval: 0
      },
      data: dataXAxis
    }],
    yAxis: [{
      type: 'value',
      interval: 2,
      min: function (value) {
        return Math.floor(value.min / 2) * 2
      },
      max: function (value) {
        return Math.ceil(value.max / 2) * 2
      },
      axisLabel: {
        show: true
      }
    }],
    dataZoom: {
      type: 'inside',
      zoomLock: true,
      rangeMode: 'value',
      startValue: startPos,
      endValue: endPos,
      minValueSpan: 30
    },
    series: [{
      name: '平均呼吸频率',
      type: 'line',
      lineStyle: { color: getColor('linegray1') },
      itemStyle: { color: ledColor[0] },
      symbol: 'circle',
      symbolSize: 5,
      smooth: true,
      data: dataRRavg
    }, {
      name: '个人基线区间',
      type: 'line',
      stack: 'band',
      silent: true,
      itemStyle: { color: getColor('tag') },
      lineStyle: { opacity: 0 },
      symbol: (value, params) => {
        return getSymbol(value, params, dataLower);
      },
      smooth: true,
      data: dataLower
    }, {
      name: '基线上限',
      type: 'line',
      stack: 'band',
      silent: true,
      itemStyle: { color: getColor('tag') },
      lineStyle: { opacity: 0 },
      symbol: (value, params) => {
        return getSymbol(value, params, dataUpper);
      },
      smooth: true,
      areaStyle: { color: getColor('tag'), opacity: 0.8 },
      data: dataUpper
    }, {
      name: '超出基线区间',
      type: 'line',
      itemStyle: { color: ledColor[1] }
    }]
  };
  rr_chart.setOption(option);
}
